import React, { useState, useContext, createContext, useEffect} from "react";
import { AccountContext } from "../Account/Account";
import { useNavigate, useSearchParams } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import Personal from "./Personal";
import AddressInfo from "./AddressInfo";
import AgentNotes from "./AgentNotes";
import EnrollmentHistory from "./EnrollmentHistory";
import Prescriptions from "./Prescriptions";
import PharmacyInfo from "./PharmacyInfo";
import Providers from "./Providers"
import EmailHistory from "./EmailHistory";
import SMSHistory from "./SMSHistory";
import { useViewContactDebounce } from "../../resources/hooks/hooks";

const ContactContext = createContext();


const ContactParent = () =>{
    const navigate = useNavigate();
    const { getSession } = useContext(AccountContext);
    const [token, setToken] = useState("");
    const [agentId, setAgentId] = useState("");
    const [searchParams, setSearchParams] = useSearchParams({id:""});
    const [contactId, setContactId] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [loadedTimeout, setLoadedTimeOut] = useState(true);
    const [updateText, setUpdateText] = useState("Loading contact data");

    const [viewContactData, setViewContactData] = useState({
        id:null,
        firstname: null,
        lastname: null,
        ani: null,
        anis:[],
        email: null,
        emails: [],
        emailhistory: [],
        medenrollments: [],
        providers: [],
        pharmacies: [],
        prescriptions: [],
        smshistory: [],
        vbcenrollments: [],
        rawcallhistory: [],
        filteredcallhistory: [],
        interestedproducts: [],        
        consent: false,
        consenttoken:null,
        lis: null,
        medicaid: null,
        csnp: null,
        dsnp: null,
        enrollmenthistory: [],
        mbid: null,
        dob: null,
        street: null,
        unit: null,
        city: null,
        state: null,
        zip: null,
        addresslat: null,
        addresslong: null
    });

    const debouncedViewContactData = useViewContactDebounce(viewContactData)

    useEffect(() => {
        getSession().then((session) => {
          setToken(session.idToken.jwtToken);
          setAgentId(session["custom:agentId"]);
          setContactId(searchParams.get("id"));
        }).catch((err) =>{
          navigate('/auth');
        })
        ;
      }, []);

      useEffect(()=>{
        if(contactId && agentId && token){
            viewContact(contactId);
        }
      }, [contactId]);

      useEffect(()=>{
        console.log(viewContactData)
      }, [viewContactData]); 
      
      useEffect(()=>{
        if(!loadedTimeout){
          updateContact(contactId)
        }
      },[debouncedViewContactData])

      
      const viewContact = async (contactid) =>{
        try {
            const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/contact/fetch?agentid=${agentId}&contactid=${contactid}`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "auth-key": token
              },
            });
            const responseJson = await response.json();
            if(responseJson.data && responseJson.data.result !== "Unauthorized"){
                setLoaded(true)
                const contact = responseJson.data;
                const filteredCallHistory = contact.call_history?.filter(item => item.comments !== "") || [];


                setViewContactData(prevState => ({
                    ...prevState,
                    id:contact.contact_data[0].id,
                    firstname: contact.contact_data[0].firstname || "",
                    lastname: contact.contact_data[0].lastname || "",
                    ani: contact.contact_data[0].ani || null,
                    anis:contact.contact_data[0].anis || [],
                    email: contact.contact_data[0].email || "",
                    emails: contact.contact_data[0].emails || [],
                    emailhistory: contact.email_history || [],
                    rawcallhistory: contact.call_history || [],
                    filteredcallhistory: filteredCallHistory,
                    smshistory: contact.sms_history || [],
                    providers: contact.contact_data[0].providers || [],
                    pharmacies: contact.contact_data[0].pharmacies || [],
                    medenrollments: contact.contact_data[0].medenrollments || [],
                    prescriptions: contact.contact_data[0].prescriptions || [],
                    consent: contact.contact_data[0].consent || false,
                    consenttoken: contact.contact_data[0].consenttoken || null,
                    interestedproducts: contact.contact_data[0].interestedproducts || [],
                    enrollmenthistory: contact.enrollment_history || [],
                    lis: contact.contact_data[0].lis,
                    medicaid: contact.contact_data[0].medicaid,
                    csnp: contact.contact_data[0].csnp,
                    dsnp: contact.contact_data[0].dsnp,
                    mbid: contact.contact_data[0].mbid,
                    dob: contact.contact_data[0].dob || "",
                    street: contact.contact_data[0].street,
                    unit: contact.contact_data[0].unit,
                    city: contact.contact_data[0].city,
                    state: contact.contact_data[0].state,
                    zip: contact.contact_data[0].zip,
                    addresslat: contact.contact_data[0].addresslat,
                    addresslong: contact.contact_data[0].addresslong
                  }));

                  setTimeout(() =>{
                    setLoadedTimeOut(false)
                  }, 2000)

            }else if(responseJson.data.result === "Unauthorized"){
                setUpdateText("You are not authorized to view this contact")
            }

          } catch (error) {
            console.error("Error fetching data:", error);
          }
      }


      const updateContact = async (contactid) => {
        try {
          const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/contact/update?agentid=${agentId}&contactid=${contactid}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "auth-key": token
            },
            body: JSON.stringify(debouncedViewContactData)
          });
          const responseJson = await response.json();
          console.log(responseJson)
        } catch (error) {
          console.log(error)
        }
      }


      return (
       <>
       <NavBar />
       <div className="row h-100">
        <Sidebar />
        <div className="col main-content">
        <ContactContext.Provider value={{ viewContactData, setViewContactData, token }}>
        <div className="row p-2 mb-4">
                <div className="col text-start">
                {loaded ? (
                    <div className="row">
                    <div className="col col-12 col-underline mb-2">
                         <div className="row">
                                <div className="col">
                                    <h4 className="mb-0 pb-0">Personal Information</h4>
                                </div>
                                <div className="col text-end">
                                    <button className="btn m-0 save-button" type="button" onClick={()=>(console.log("something"))}>Begin Enrollment</button>
                                </div>
                              </div>       
                    </div>
                    <Personal />
                      <div className="col col-12 col-underline mb-2">
                        <h4 className="mb-0 pb-0">Address Information</h4>
                    </div>
                    <AddressInfo />
                    <div className="col col-12 col-underline mb-2">
                        <h4 className="mb-0 pb-0">Additional Information</h4>
                    </div>
                      <div className="col col-12 mb-5">
                        {token && (<>
                         <Providers />  
                         <PharmacyInfo />                       
                         <Prescriptions />
                         <EnrollmentHistory />
                          <AgentNotes />
                          <EmailHistory/>
                          <SMSHistory/>
                         </>)       
                        }    
                        
                      </div>
                    </div>

                ) : (<h5 className="p-5 text-center">{updateText}</h5>)}
                </div>
            </div>
            
              </ContactContext.Provider>
                    </div>
             </div>
          </>
    );
      
}

export {ContactParent, ContactContext};
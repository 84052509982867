import React, { useState, useMemo, useEffect, useContext } from "react";
import { AccountContext } from "../Account/Account";
import { useTable, useSortBy } from "react-table";
import { Modal } from "react-bootstrap";

// Custom hook for creating table
const useCustomTable = (columns, data) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data }, useSortBy);

  return {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  };
};

const ProcessReminders = () => {
  const [reminderData, setReminderData] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => {setShow(false); setReminder({ agentId: "", text: "", duedate: "" })}
  const handleShow = () => setShow(true);
  const {  activeSession, userAttributes, sessionInformation } = useContext(AccountContext);
  const [isLoading, setIsLoading] = useState(true);

  const [reminder, setReminder] = useState({ agentId: "", text: "", duedate: "" });

  const prepareReminder = (e) => {
    setReminder((prev) => ({
      ...prev,
      agentId: userAttributes["custom:agentId"],
      [e.target.name]: e.target.value
    }));
  };


  const fetchReminders = async () => {
    try {
      const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/reminders/fetch?agentid=${userAttributes["custom:agentId"]}&orgid=${userAttributes["custom:orgid"]}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-key": sessionInformation.idToken.jwtToken,
        },
      });
      const responseJson = await response.json();
      if (response.ok) {
        setIsLoading(false)
        if(responseJson.body?.length > 0){
          setReminderData(responseJson.body)
        }else{
          setReminderData([])
        }
      } else {
        console.error("Failed to load reminders");
        setIsLoading(false)
      }
    } catch (error) {
      console.error("Error loading reminders:", error);
      setIsLoading(false)
    }
  };

  useEffect(()=>{
    if(activeSession){
     fetchReminders();
    }
  },[activeSession])




  const createReminder = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/reminders/create?agentid=${userAttributes["custom:agentId"]}&orgid=${userAttributes["custom:orgid"]}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-key": sessionInformation.idToken.jwtToken
        },
        body: JSON.stringify(reminder)
      });
      if (response.status === 200) {
       console.log("BONER REMINDER STATUS ", response.status);
       setReminder({ agentId: "", text: "", duedate: "" });
        handleClose();
        fetchReminders();
      } else {
        console.log("BONER REMINDER STATUS ", response.status)
      }
    } catch (error) {
      console.error("Error creating reminders:", error);
    }
  };

  const completeReminder = async (e) => {
    try {
      const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/reminders/complete?agentid=${userAttributes["custom:agentId"]}&orgid=${userAttributes["custom:orgid"]}&reminderid=${e}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-key": sessionInformation.idToken.jwtToken
        }
      });
      if (response.status === 200) {
        handleClose();
        fetchReminders();
      } else {
        console.log("BONER REMINDER STATUS ", response.status)
      }
    } catch (error) {
      console.error("Error completing reminders:", error);
    }
  };

  const reminderColumns = useMemo(
    () => [
      { Header: "Due Date", accessor: "duedate" },
      { Header: "Details", accessor: "text" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <>
            <button
              className="btn m-0 p-1 reminder-complete"
              type="button"
              onClick={() => completeReminder(row.original.id)}
            >
              Mark As Complete
            </button>
          </>
        ),
      },
    ],
    [reminderData]
  );

  const {
    getTableProps: getReminderTableProps,
    getTableBodyProps: getReminderTableBodyProps,
    headerGroups: reminderHeaderGroups,
    rows: reminderRows,
    prepareRow: prepareReminderRow,
  } = useCustomTable(reminderColumns, reminderData);

  return (
    <>
      <div className="container p-4">
        <div className="row">
          <div className="col col-10 border-bottom text-start">
            <div>
              <h5>Your Reminders</h5>
            </div>
          </div>
          <div className="col col-1 border-bottom">
            <div>
              <button className="btn p-0 m-0" type="button" onClick={handleShow}>
                &nbsp;Create Reminder
              </button>
            </div>
          </div>
          <div className="col col-1 text-center border-bottom hover-cursor" onClick={()=>{fetchReminders();setIsLoading(true);}}>Refresh <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
</svg></div>
          <div className="col col-12 p-3">
          {!isLoading ? (
            <div className="table-responsive">
            {reminderData.length > 0 ? (
              <table className="table" {...getReminderTableProps()}>
                <thead>
                  {reminderHeaderGroups.map((reminderHeaderGroup) => (
                    <tr {...reminderHeaderGroup.getHeaderGroupProps()}>
                      {reminderHeaderGroup.headers.map((column) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                          {column.render("Header")}
                          <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getReminderTableBodyProps()}>
                  {reminderRows.map((reminderRow) => {
                    prepareReminderRow(reminderRow);
                    return (
                      <tr {...reminderRow.getRowProps()}>
                        {reminderRow.cells.map((reminderCell, index) => (
                          <td
                            {...reminderCell.getCellProps()}
                            className={`${index === 0 || index === 2 ? "col-1 text-start" : index === 1 ? "col-8 text-start" : ""}`}
                          >
                            {reminderCell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
               ) : (
                <div className="w-100 m-auto text-center pb-0">
                  <h6>No reminders found</h6>
                </div>
                 )}
            </div>):(
              <div className="float-all-middle">
                <div className="text-center">
                  <img src="/img/spinner.gif" className="loadingIcon"></img>
                  <h5>Loading Reminders...</h5>
                  </div>               
              </div>
            )}
          </div>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Reminder</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <span>Reminder Time</span>
              <form>
                <input
                  className="form-control mb-3"
                  type="datetime-local"
                  name="duedate"
                  onChange={prepareReminder}
                ></input>
                <label className="form-label mb-0">Reminder Details</label>
                <textarea className="form-control" name="text" onChange={prepareReminder}></textarea>
              </form>
              <div className="w-100 text-end p-3">
                <button className="btn btn-light" type="button" onClick={handleClose}>
                  Cancel
                </button>
                <button className="btn btn-primary ms-4" type="button" onClick={createReminder}>
                  Add Reminder
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default ProcessReminders;

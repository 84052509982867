import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTable, useSortBy } from "react-table";
import { ContactContext } from "./ContactParent";
import { Accordion, AccordionBody, AccordionHeader, Modal } from "react-bootstrap";

const useCustomTable = (columns, data) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow
    } = useTable({ columns, data }, useSortBy);
  
    return {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow
    };
  };

const Providers = () => {
    const { viewContactData, setViewContactData, token } = useContext(ContactContext);
    const [editProviderId, setEditProviderId] = useState(null);
    const [showEditProvider, setShowEditProvider] = useState(false);
    const handleProviderClose = () => {setShowEditProvider(false);setEditProviderId(null);}
    const handleProviderShow = () => setShowEditProvider(true);

    const [showAddProvider, setShowAddProvider] = useState(false);
    const handleAddProviderClose = () => {setShowAddProvider(false);setProviderSearching(false); setLocatedProviderArray([])}
    const handleAddProviderShow = () => setShowAddProvider(true);

    const providerLookupStateRef = useRef(null);
    const providerLookupLastNameRef = useRef(null);
    const manualAddProviderNPNError = useRef(null);
    

    const [providerSearching, setProviderSearching] = useState(false);
    const [manualAddProvider, setManualAddProvider] = useState(false);

    const providerSpecialties = [
        'None','Adult Intensivist', 'Allergy', 'Anesthesia', 'Bariatric Medicine/Surgery', 'Burn/Trauma',
        'Cardiac Catheterization', 'Cardiology', 'Cardiovascular Surgery', 'Colorectal Surgery', 'Dermatology',
        'Electrophysiology', 'Emergency Medicine', 'Endocrinology', 'Family Practice', 'Gastroenterology',
        'General Surgery', 'Geriatrics', 'Gynecologic Oncology', 'Hematology/Oncology', 'Hepatobiliary',
        'Hospitalist', 'Infectious Disease', 'Internal Medicine', 'Interventional Radiology', 'Medical Genetics',
        'Neonatology', 'Nephrology', 'Neuroradiology', 'Neurology', 'Neurosurgery', 'Nuclear Medicine',
        'Obstetrics & Gynecology', 'Occupational Medicine', 'Ophthalmology', 'Oral Surgery', 'Orthopedics',
        'Otolaryngology / Head & Neck Surgery', 'Pain Management', 'Palliative Care', 'Pain Management',
        'Palliative Care', 'Pathology: Surgical & Anatomic', 'Pediatric Intensivist', 'Pediatrics',
        'Pediatric Surgery', 'Physical Medicine', 'Plastic & Reconstructive Surgery', 'Podiatric Surgery',
        'Psychiatry', 'Pulmonary Medicine', 'Radiation Oncology', 'Radiology', 'Rheumatology',
        'Surgical Oncology', 'Thoracic Surgery', 'Transplant Surgery', 'Urology', 'Vascular Surgery', 'Wound Care',
        'Bariatric Surgery', 'ENT', 'General Surgery', 'Gastroenterology', 'Neurosurgery', 'Obstetrics & Gynecology',
        'Ophthalmology', 'Oral/Dental Surgery', 'Orthopedic Surgery', 'Pain Management', 'Pediatric Surgery',
        'Plastic Surgery', 'Podiatric Surgery', 'Urology', 'Vascular Surgery', 'Ambulatory Clinics',
        'Cardiac Catheterization Labs', 'Diagnostic Radiology', 'Emergency Department', 'GI Endoscopy Labs',
        'Inpatient Acute Care', 'Interventional Cardiology', 'Interventional Radiology', 'Same Day Surgery',
        'Small Specialty Hospitals', 'Surgical Pathology', 'HCC Audits', 'Ambulance Coding',
      ];
  
       const [showSpecialtyDropdown, setshowSpecialtyDropdown] = useState(false)
       const [filteredProviderSpecialties, setFilteredProviderSpecialties] = useState([]);

       const [tempProviderInfo, setTempProviderInfo] = useState({
        name: null,
        id: null,
        address: null,
        primary: null,
        type: null,
        field: null
    });

    const [tempAddProviderInfo, setTempAddProviderInfo] = useState({
      firstname: null,
      lastname: null,
      id: null,
      address: null,
      primary: null,
      type: null,
      field: null
  });

    const [searchProviderForm, setSearchProviderForm] = useState({
      firstname:"",
      lastname:"",
      city:"",
      statecode:"0",
      npi:""
    });

    const [locatedProviderArray, setLocatedProviderArray] = useState([]);
    const [addProviderId,setAddProviderId] = useState("");
    const [removeProviderId, setRemoveProviderId] = useState(null);
    const [providerSearchErrorText, setProviderSearchErrorText] = useState("");
    const [editProviderErrorText, setEditProviderErrorText] = useState("")
    const [providerMismatchWarning, setProviderMismatchWarning] = useState(false);

    const addProviderToList = (npi) => {
      setViewContactData((prevState) => {
        const isProviderExists = prevState.providers.some((provider) => provider.id === npi);
    
        if (!isProviderExists) {
          const addProviderObject = locatedProviderArray.find((provider) => provider.npi === npi);
    
    
          if (addProviderObject) {
            const modifiedProviderObject = {
              name: addProviderObject.fullname,
              id: addProviderObject.npi,
              address: addProviderObject.fulladdress,
              primary: "Choose",
              type: "Choose",
              field: addProviderObject.providertype,
            };
    
            console.log("Adding provider:", modifiedProviderObject);
            setProviderSearchErrorText("");
            setAddProviderId(null);
            setSearchProviderForm({firstname:"",lastname:"",city:"",statecode:"0",npi:""});
            setTempAddProviderInfo({firstname: null,lastname: null,id: null,address: null,primary: null,type: null,field: null});
            handleAddProviderClose();
            return {
              ...prevState,
              providers: [...prevState.providers, modifiedProviderObject],
            };
          } else {
            console.log("Located provider not found for NPI:", npi);
          }
        } else {
          console.log("Provider already exists");
          setProviderSearchErrorText("Provider with identical NPI already exists");
        }
    
        // If no changes are made, return the previous state
        return prevState;
      });
    
    };

    const removeProvider = (provid) => {
      setViewContactData((prevState) => {
        console.log(prevState.providers);
        const isProviderExists = prevState.providers.some((provider) => provider.id === provid);
        if (isProviderExists) {
            const updatedProviders = prevState.providers.filter((provider) => provider.id !== provid);
            return {
              ...prevState,
              providers: updatedProviders,
            }
        }
        return prevState;
      });
    }

    useEffect(()=>{
        if(addProviderId){
          addProviderToList(addProviderId);
        }
      }, [addProviderId]);

      useEffect(()=>{
        if(editProviderId){
          const selectedProvider = viewContactData.providers.find(provider => provider.id === editProviderId);
          setTempProviderInfo(selectedProvider)
          handleProviderShow(true);
          console.log(selectedProvider);
        }else{
          setTempProviderInfo({name: null, id: null, address: null,primary: null,type: null,field: null});
        }
      }, [editProviderId]);

      useEffect(()=>{
        if(removeProviderId){
          removeProvider(removeProviderId)
        }
      }, [removeProviderId]);

      useEffect(() => {
        fetchAdditionalProviderInfo()
      },[])

      const providerColumns = useMemo(
        () => [
          { Header: "Provider Name", accessor: "name" },
          {
            Header: "Address",
            accessor: "address"
          },
          {
            Header: "Provider ID",
            accessor: "id",
          },
          {
            Header: "Primary",
            accessor: "primary"
          },
          {
            Header: "Type",
            accessor: "type"
          },
          {
            Header: "Field",
            accessor: "field"
          },
          {
            Header: "Actions",
            accessor: "actions",
            Cell: ({ row }) => (
              <>
               <button
                  className="btn m-0 p-1"
                  type="button"
                  onClick={() => (setEditProviderId(row.original.id))}
                >
                  Edit
                </button>
                <button
                  className="btn m-0 p-1"
                  type="button"
                  onClick={() => (setRemoveProviderId(row.original.id))}
                >
                  Remove
                </button>
              </>
            ),
          },
        ],
        []
      );

      const locatedProviderColumns = useMemo(
        () => [
          { Header: "NPI", accessor: "npi" },
          { Header: "Name", accessor: "fullname" },
          { Header: "Type", accessor: "providertype" },
          { Header: "Address", accessor: "fulladdress" },
          { Header: "Medicare Participant", accessor: "medicareparticipation" },
          {
            Header: "Actions",
            accessor: "actions",
            Cell: ({ row }) => (
              <>
               <button
                  className="btn m-0 p-1"
                  type="button"
                  onClick={() => (setAddProviderId(row.original.npi))}
                >
                  + Add
                </button>
              </>
            ),
          },
        ],
        []
      );

      const {
        getTableProps: getProviderTableProps,
        getTableBodyProps: getProviderTableBodyProps,
        headerGroups: providerHeaderGroups,
        rows: providerRows,
        prepareRow: prepareProviderRow,
      } = useCustomTable(providerColumns, viewContactData.providers);

      const {
        getTableProps: getAddProviderTableProps,
        getTableBodyProps: getAddProviderTableBodyProps,
        headerGroups: addProviderHeaderGroups,
        rows: addProviderRows,
        prepareRow: prepareAddProviderRow,
      } = useCustomTable(locatedProviderColumns, locatedProviderArray);

      const editTempProviderData = (e) => {
        setTempProviderInfo((prevTempProviderInfo) => {
          const updatedInfo = { ...prevTempProviderInfo, [e.target.name]: e.target.value };
      
          if (e.target.name === "type" && e.target.value === "Non-Specialist") {
            updatedInfo.field = "None";
          }
      
          return updatedInfo;
        });
      };
      

      const editAddTempProviderData = (e) =>{
        setTempAddProviderInfo((prevTempProviderInfo) => ({
          ...prevTempProviderInfo,
          [e.target.name]: e.target.value,
        }));
      }

      const editAddProviderData = (e) =>{
        setSearchProviderForm((prevAddProviderInfo) => ({
          ...prevAddProviderInfo,
          [e.target.name]: e.target.value,
        }));
      }

      const updateAddProviderObject = (object) => {
        const updatedObject = {...object, fullname: `${object.firstname}, ${object.lastname} ${object.credentials || ''}`, fulladdress: `${object.streetaddress1}, ${object.city}, ${object.statecode} ${object.zipcode}`}; 
        return updatedObject
      }




      const validateProviderLookupForm = () => {
        if (searchProviderForm.npi && searchProviderForm.npi.trim().length === 10) {
          providerLookupStateRef.current.classList.remove("validation-error")
          providerLookupLastNameRef.current.classList.remove("validation-error") 
          searchProviders();
        }else if(searchProviderForm.state !== "0" && searchProviderForm.lastname){
          providerLookupStateRef.current.classList.remove("validation-error")
          providerLookupLastNameRef.current.classList.remove("validation-error") 
          searchProviders();
        }else{
           providerLookupStateRef.current.classList.add("validation-error")
           providerLookupLastNameRef.current.classList.add("validation-error") 
        }
      }

     

      const searchProviders = async () => {
        setProviderSearching(true);
        let baseURL = `https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/providerlookup`;

        const filteredParams = Object.fromEntries(
          Object.entries(searchProviderForm).filter(([key, value]) => value != null && value !== '' && value !== "0")
        );
       
          const queryParams = Object.entries(filteredParams).map(([key, value]) => {
            return `${key}=${encodeURIComponent(value)}`;
          });
          
          const finalURL = `${baseURL}?${queryParams.join('&')}`;
          console.log(finalURL)
          try {
            const response = await fetch(finalURL, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "auth-key": token
              }
            });
            const responseJson = await response.json();
            console.log(responseJson);
            if(responseJson.statusCode === 200){
              setProviderSearchErrorText("");
              const updatedAddProviderObject = responseJson.body.map(item => updateAddProviderObject(item));
              setLocatedProviderArray(updatedAddProviderObject);
            }else{
              setProviderSearching(false);
              setProviderSearchErrorText("No Providers Found.")
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
    
      }

      const fetchAdditionalProviderInfo = async() => {
        const apiKey = 'AIzaSyDLahf2zhKgErUyr_j4w1M0h_3OHd3u454';
        const apiUrl = 'https://places.googleapis.com/v1/places:autocomplete';
        const requestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'X-Goog-Api-Key': apiKey
                },
                body: JSON.stringify({
                  input: "1255777215",
                  regionCode: "US",
                  includedPrimaryTypes:"doctor"
                }),
              };

             /* try {
                const response = await fetch(apiUrl, requestOptions);
                const data = await response.json();
                console.log(data);
              } catch (error) {
                console.error('Error fetching data:', error);
              }*/
      }

      const updateProviderInfo = (e) => {
        e.preventDefault();
        if(tempProviderInfo.id && tempProviderInfo.name && tempProviderInfo.address && tempProviderInfo.primary !== "Choose" && tempProviderInfo.type !== "Choose" && tempProviderInfo.field){
                setEditProviderErrorText("");
                const updatedProviderInfo = viewContactData.providers.map(provider => {
                  if (provider.id === tempProviderInfo.id) {
                      return { ...provider, ...tempProviderInfo };
                  }
                  return provider;
              });

              setViewContactData(prevState => ({
                  ...prevState,
                  providers: updatedProviderInfo
              }));
              handleProviderClose();
        }else{
          setEditProviderErrorText("Please complete all required fields");
        }
    };

    const addManualProvider = async (e) => {
      e.preventDefault();
      if((tempAddProviderInfo.firstname && tempAddProviderInfo.lastname && tempAddProviderInfo.address && tempAddProviderInfo.primary && tempAddProviderInfo.type && tempAddProviderInfo.field && tempAddProviderInfo.id) && tempAddProviderInfo.id.trim().length === 10 ){
        setProviderSearchErrorText("");
        if(!providerMismatchWarning){
          setProviderSearching(true);
          manualAddProviderNPNError.current.classList.remove("validation-error");
        const URL = `https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/providerlookup?npi=${tempAddProviderInfo.id.trim()}`;
        try {
          const response = await fetch(URL, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "auth-key": token
            }
          });
          const responseJson = await response.json();
          console.log(responseJson);
          if(responseJson.statusCode === 200){
            setProviderSearching(false);

            setViewContactData((prevState) => {
              const isProviderExists = prevState.providers.some((provider) => provider.id === tempAddProviderInfo.id.trim());
          
              if (!isProviderExists) {
                  const modifiedProviderObject = {
                    name: `${tempAddProviderInfo.firstname}, ${tempAddProviderInfo.lastname}`,
                    id: tempAddProviderInfo.id,
                    address: tempAddProviderInfo.address,
                    primary: tempAddProviderInfo.primary,
                    type: tempAddProviderInfo.type,
                    field: tempAddProviderInfo.field,
                  };
          
                  setProviderSearchErrorText("");
                  setTempAddProviderInfo({firstname: null,lastname: null,id: null,address: null,primary: null,type: null,field: null});
                  setSearchProviderForm({firstname:"",lastname:"",city:"",statecode:"0",npi:""});
                  handleAddProviderClose();
                  return {
                    ...prevState,
                    providers: [...prevState.providers, modifiedProviderObject],
                  };
                
              } else {
                setProviderSearchErrorText("Provider with identical NPI already exists");
              }
          
              // If no changes are made, return the previous state
              return prevState;
            });
          }else if(responseJson.statusCode === 201){
            //provider npi not found, warn
            setProviderSearching(false);
            setProviderMismatchWarning(true);
            setProviderSearchErrorText("WARNING: The NPI You've Entered Was Not Found In The System. If you are sure this is correct, tap 'Add Provider' again. Otherwise modify the NPI and resubmit.")
          }else{
            setProviderSearchErrorText(JSON.stringify(responseJson.body))
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
        }else{
          setViewContactData((prevState) => {
            const isProviderExists = prevState.providers.some((provider) => provider.id === tempAddProviderInfo.id.trim());
        
            if (!isProviderExists) {
                const modifiedProviderObject = {
                  name: `${tempAddProviderInfo.firstname}, ${tempAddProviderInfo.lastname}`,
                  id: tempAddProviderInfo.id,
                  address: tempAddProviderInfo.address,
                  primary: tempAddProviderInfo.primary,
                  type: tempAddProviderInfo.type,
                  field: tempAddProviderInfo.field,
                };
        
                setProviderSearchErrorText("");
                setTempAddProviderInfo({firstname: null,lastname: null,id: null,address: null,primary: null,type: null,field: null});
                setSearchProviderForm({firstname:"",lastname:"",city:"",statecode:"0",npi:""});
                handleAddProviderClose();
                return {
                  ...prevState,
                  providers: [...prevState.providers, modifiedProviderObject],
                };
              
            } else {
              setProviderSearchErrorText("Provider with identical NPI already exists");
            }
        
            // If no changes are made, return the previous state
            return prevState;
          });

        }
        
      }else{
        setProviderSearchErrorText("Please ensure all fields are complete before adding provider.");
      }
    }
    


      const filterSpecialtyResults = (e) => {
        const inputValue = e.target.value;
        setTempProviderInfo((prevTempProviderInfo)=>({
          ...prevTempProviderInfo,
          field: inputValue
        }))
        if(inputValue.length > 0){
          setshowSpecialtyDropdown(true)
        }else{
          setshowSpecialtyDropdown(false)
        }
        const filteredData = providerSpecialties.filter(item =>
          item.toLowerCase().includes(inputValue.toLowerCase())
        );

        const limitedResults = filteredData.slice(0, 3);
  
        setFilteredProviderSpecialties(limitedResults);
      };

      const handleSpecialtySelection = (selectedSpecialty) => {
        setshowSpecialtyDropdown(false)
        setTempProviderInfo((prevTempProviderInfo)=>({
          ...prevTempProviderInfo,
          field: selectedSpecialty
        }))
      };

      const handleAddSpecialtySelection = (selectedSpecialty) => {
        setshowSpecialtyDropdown(false)
        setTempAddProviderInfo((prevTempProviderInfo)=>({
          ...prevTempProviderInfo,
          field: selectedSpecialty
        }))
      };

      return (
        <>
        <Accordion>
          <Accordion.Item  eventKey="0">
            <AccordionHeader>Provider Information<span>({viewContactData.providers.length})</span></AccordionHeader>
            <AccordionBody>
            <div className="table-responsive">
                                            {viewContactData.providers.length > 0 ? (
                                              <>
                                                     <table className="table text-center" {...getProviderTableProps()}>
                                                     <thead>
                                                     {providerHeaderGroups.map((providerHeaderGroup) => (
                                                         <tr {...providerHeaderGroup.getHeaderGroupProps()}>
                                                         {providerHeaderGroup.headers.map((column) => (
                                                             <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                             {column.render("Header")}
                                                             <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                                                             </th>
                                                         ))}
                                                         </tr>
                                                     ))}
                                                     </thead>
                                                     <tbody {...getProviderTableBodyProps()}>
                                                     {providerRows.map((providerRow) => {
                                                         prepareProviderRow(providerRow);
                                                         return (
                                                         <tr {...providerRow.getRowProps()}>
                                                             {providerRow.cells.map((providerCell, index) => (
                                                             <td
                                                             {...providerCell.getCellProps()}
                                                             className={`text-center ${index === 1 || index === 5 ? 'col-2' : 'col-1'}`}
                                                             >
                                                                  {providerCell.render("Cell")}
                                                             </td>
                                                             ))}
                                                         </tr>
                                                         );
                                                     })}
                                                     </tbody>
                                                 </table>
                                                 <div className="w-100 text-center"><span onClick={handleAddProviderShow}>+ Add New Provider</span></div>
                                                 </>
                                                    ) : (
                                                        <div className="w-100 text-center">
                                                        <h6>No providers recorded</h6>
                                                        <div className="w-100 text-center"><span onClick={handleAddProviderShow}>+ Add New Provider</span></div>
                                                        </div>
                                                        )}
                                                    </div>
            </AccordionBody>
          </Accordion.Item>
        </Accordion>
      
                                            
        <Modal show={showEditProvider} onHide={handleProviderClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Edit Provider</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <form>
    <div className="row">
        <div className="col mb-5">
          <label className="form-label mb-1">Provider Name</label><input className="form-control mb-3" type="text" name="name" value={tempProviderInfo.name} onChange={(e)=> editTempProviderData(e)} required />
          <label className="form-label mb-1">Provider Address</label><input className="form-control mb-3" type="text" name="address" value={tempProviderInfo.address} onChange={(e)=> editTempProviderData(e)} required/>
          <label className="form-label w-100 mb-1">Provider Type</label><select className="form-select" name="type" value={tempProviderInfo.type} onChange={(e)=> editTempProviderData(e)} required>
                          <option value="Choose" selected disabled>Choose</option>
                          <option value="Non-Specialist">Non-Specialist</option>
                          <option value="Specialist">Specialist</option>
            </select>
        </div>
        <div className="col mb-5">
        <label className="form-label mb-1">NPI Number</label><input className="form-control mb-3" type="text" value={tempProviderInfo.id} required readOnly/>
        <label className="form-label w-100 mb-1">Primary Provider</label><select className="form-select mb-3" name="primary" value={tempProviderInfo.primary} onChange={(e)=> editTempProviderData(e)} required >
                          <option value="Choose" selected disabled>Choose</option>
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
            </select>
            <label className="form-label mb-1">Field</label><input className="form-control" type="text" onChange={filterSpecialtyResults} value={tempProviderInfo.field}/>
            <ul className="filteredList" style={{ display: showSpecialtyDropdown ? 'block' : 'none' }}>
              {filteredProviderSpecialties.map((result, index) => (   
                  <li key={index} className="specialty-li" onClick={(e)=>{handleSpecialtySelection(result)}}>{result}</li>
              ))}
            </ul>
        </div>
        </div>
        <div className="w-100 text-end p-3">
                <button className="btn btn-light" type="button" onClick={handleProviderClose}>Cancel</button>
                <button className="btn main-button ms-4" type="submit" onClick={(e)=>{updateProviderInfo(e)}}>Update Provider Info</button>
              </div>
              <div className="w-100 text-center">{editProviderErrorText}</div>
</form>
            </Modal.Body>
          </Modal>
          <Modal show={showAddProvider} onHide={handleAddProviderClose} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>Add Provider</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {!providerSearching ? (

                !manualAddProvider ? ( <form>
                  <div className="row">
                      <div className="col mb-3"><label className="form-label mb-1">First Name</label><input className="form-control mb-3" type="text" name="firstname" value={searchProviderForm.firstname} onChange={(e)=> editAddProviderData(e)}/>
                          <div className="row">
                              <div className="col"><label className="form-label mb-1">City</label><input className="form-control mb-3" type="text" name="city" value={searchProviderForm.city} onChange={(e)=> editAddProviderData(e)}/></div>
                              <div className="col"><label className="form-label mb-1">State</label>
                              <select className="form-select" name="statecode" value={searchProviderForm.statecode} onChange={(e)=> editAddProviderData(e)} ref={providerLookupStateRef}>
                              <option value="0">Choose</option>
                              <option value="AL">Alabama</option>
                              <option value="AK">Alaska</option>
                              <option value="AZ">Arizona</option>
                              <option value="AR">Arkansas</option>
                              <option value="CA">California</option>
                              <option value="CO">Colorado</option>
                              <option value="CT">Connecticut</option>
                              <option value="DE">Delaware</option>
                              <option value="FL">Florida</option>
                              <option value="GA">Georgia</option>
                              <option value="HI">Hawaii</option>
                              <option value="ID">Idaho</option>
                              <option value="IL">Illinois</option>
                              <option value="IN">Indiana</option>
                              <option value="IA">Iowa</option>
                              <option value="KS">Kansas</option>
                              <option value="KY">Kentucky</option>
                              <option value="LA">Louisiana</option>
                              <option value="ME">Maine</option>
                              <option value="MD">Maryland</option>
                              <option value="MA">Massachusetts</option>
                              <option value="MI">Michigan</option>
                              <option value="MN">Minnesota</option>
                              <option value="MS">Mississippi</option>
                              <option value="MO">Missouri</option>
                              <option value="MT">Montana</option>
                              <option value="NE">Nebraska</option>
                              <option value="NV">Nevada</option>
                              <option value="NH">New Hampshire</option>
                              <option value="NJ">New Jersey</option>
                              <option value="NM">New Mexico</option>
                              <option value="NY">New York</option>
                              <option value="NC">North Carolina</option>
                              <option value="ND">North Dakota</option>
                              <option value="OH">Ohio</option>
                              <option value="OK">Oklahoma</option>
                              <option value="OR">Oregon</option>
                              <option value="PA">Pennsylvania</option>
                              <option value="RI">Rhode Island</option>
                              <option value="SC">South Carolina</option>
                              <option value="SD">South Dakota</option>
                              <option value="TN">Tennessee</option>
                              <option value="TX">Texas</option>
                              <option value="UT">Utah</option>
                              <option value="VT">Vermont</option>
                              <option value="VA">Virginia</option>
                              <option value="WA">Washington</option>
                              <option value="WV">West Virginia</option>
                              <option value="WI">Wisconsin</option>
                              <option value="WY">Wyoming</option>
                  </select>
                              </div>
                          </div>
                      </div>
                      <div className="col mb-3"><label className="form-label mb-1">Last Name</label><input className="form-control mb-3" type="text"  name="lastname" value={searchProviderForm.lastname} onChange={(e)=> editAddProviderData(e)} ref={providerLookupLastNameRef} /><label className="form-label mb-1">NPI Number</label><input className="form-control mb-3" type="text" name="npi" value={searchProviderForm.npi} onChange={(e)=> editAddProviderData(e)} placeholder="Enter 10-Digit NPI Number" inputMode="numeric" /></div>
                  </div>
                  <div className="w-100 text-center"><button className="btn main-button" type="button" onClick={validateProviderLookupForm}>Search Providers</button>
                      <p className="w-100 m-0 pt-2 text-center" onClick={()=>{setManualAddProvider(true)}}>+ Add Manually</p>
                      <p className="mt-2 w-100 text-center">{providerSearchErrorText}</p>
                  </div>
                  </form>):(
                    <>
                   <form>
                          <div className="row">
                              <div className="col mb-5">
                              <div className="row">
                              <div className="col col-6">
                              <label className="form-label mb-1">Provider First Name</label><input className="form-control mb-3" type="text" name="firstname" value={tempAddProviderInfo.firstname} onChange={(e)=> editAddTempProviderData(e)} required />
                              </div>
                              <div className="col col-6">
                              <label className="form-label mb-1">Provider Last Name</label><input className="form-control mb-3" type="text" name="lastname" value={tempAddProviderInfo.lastname} onChange={(e)=> editAddTempProviderData(e)} required />
                              </div>
                                </div>
                                <label className="form-label mb-1">Provider Address</label><input className="form-control mb-3" type="text" name="address" value={tempAddProviderInfo.address} onChange={(e)=> editAddTempProviderData(e)} required/>
                                <label className="form-label w-100 mb-1">Provider Type</label><select className="form-select" name="type" value={tempAddProviderInfo.type} onChange={(e)=> editAddTempProviderData(e)} required>
                                                <option value="null" selected disabled>Choose</option>
                                                <option value="Non-Specialist">Non-Specialist</option>
                                                <option value="Specialist">Specialist</option>
                                  </select>
                              </div>
                              <div className="col mb-5">
                              <label className="form-label mb-1">NPI Number*</label><input className="form-control mb-3" type="text" name="id" value={tempAddProviderInfo.id} required ref={manualAddProviderNPNError} onChange={(e)=> {editAddTempProviderData(e); setProviderMismatchWarning(false);}}/>
                              
                              <label className="form-label w-100 mb-1">Primary Provider</label><select className="form-select mb-3" name="primary" value={tempAddProviderInfo.primary} onChange={(e)=> editAddTempProviderData(e)} required >
                                                <option value="null" selected disabled>Choose</option>
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>
                                  </select>
                                  <label className="form-label mb-1">Field</label><input className="form-control" type="text" onChange={filterSpecialtyResults} value={tempAddProviderInfo.field}/>
                                  <ul className="filteredList" style={{ display: showSpecialtyDropdown ? 'block' : 'none' }}>
                                    {filteredProviderSpecialties.map((result, index) => (   
                                        <li key={index} className="specialty-li" onClick={(e)=>{handleAddSpecialtySelection(result)}}>{result}</li>
                                    ))}
                                  </ul>
                              </div>
                              </div>
                              <div className="w-100 text-center p-3">
                                      <button className="btn main-button ms-4" type="submit" onClick={(e)=>{addManualProvider(e)}}>Add Provider</button>
                                    </div>
                      </form> 
                      <p className="w-100 m-0 pt-2 text-center" onClick={()=>{setManualAddProvider(false)}}>Return To Provider Search</p>
                      <p className="mt-2 w-75 m-auto text-center">{providerSearchErrorText}</p>            
                    </>
                  )
             ): locatedProviderArray.length > 0 ? (
                                                          <>
                                                          <table className="table text-center" {...getAddProviderTableProps()}>
                                                                        <thead>
                                                                        {addProviderHeaderGroups.map((addProviderHeaderGroup) => (
                                                                            <tr {...addProviderHeaderGroup.getHeaderGroupProps()}>
                                                                            {addProviderHeaderGroup.headers.map((column) => (
                                                                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                                                {column.render("Header")}
                                                                                <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                                                                                </th>
                                                                            ))}
                                                                            </tr>
                                                                        ))}
                                                                        </thead>
                                                                        <tbody {...getAddProviderTableBodyProps()}>
                                                                        {addProviderRows.map((addProviderRow) => {
                                                                            prepareAddProviderRow(addProviderRow);
                                                                            return (
                                                                            <tr {...addProviderRow.getRowProps()}>
                                                                                {addProviderRow.cells.map((addProviderCell, index) => (
                                                                                <td
                                                                                {...addProviderCell.getCellProps()}
                                                                                className={`text-center`}
                                                                                >
                                                                                      {addProviderCell.render("Cell")}
                                                                                </td>
                                                                                ))}
                                                                            </tr>
                                                                            );
                                                                        })}
                                                                        </tbody>
                                                                    </table>
                                                                    <div className="w-100 text-center" onClick={()=>{setProviderSearching(false); setLocatedProviderArray([])}}>Modify Search</div>
                                                                    <div className="w-100 text-center">{providerSearchErrorText}</div>
                                                    </>
                                        ) : (<div>Seaching for providers...</div>)}
                                                    </Modal.Body>
                                                  </Modal>
                                                  </>
      )
}

export default Providers;
import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "../Account/Account";
import { useNavigate } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import LoadLeaderboards from "./LoadLeaderboards";


const Leaderboards = () =>{
    const navigate = useNavigate();
    const { getSession } = useContext(AccountContext);
    const [token, setToken] = useState("");
    const [agentId, setAgentId] = useState("");
    const [orgId, setOrgId] = useState("");
    
    useEffect(() => {
        getSession().then((session) => {
          setToken(session.idToken.jwtToken);
          setAgentId(session["custom:agentId"]);
          setOrgId(session["custom:orgid"]);
        }).catch((err) =>{
          navigate('/auth');
        })
        ;
      }, []);


      return (
        <>
        <div><NavBar />
         <div className="row h-100">
          <Sidebar />
          <div className="col main-content">
          {token ? (
            <LoadLeaderboards access={token} agentId={agentId} orgId={orgId}/>
          ) : (
            <div>
              <h1 className="p-5">Loading....</h1>
            </div>
          )}
          </div>
          </div>
          </div>
        </>
      );
      
}

export default Leaderboards
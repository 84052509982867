import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "../Account/Account";
import { useNavigate } from 'react-router-dom';
import { Container } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';


const NavBar = () =>{
    const { logout } = useContext(AccountContext);
    const navigate = useNavigate();
    return (

        <Navbar expand="lg" className="bg-body py-3 navbar-boxshadow">
      <Container>
        <Navbar.Brand className="d-flex align-items-center left-margin"><img className="db-logo" src="/img/tappolicyheadergraphic-cropped.png" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto me-3">
            <NavDropdown align="end" title="Account Settings">
              <NavDropdown.Item onClick={()=>{navigate('/account');}}>Account Settings</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>{logout(); navigate('/auth');}}>Log Out</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    )
}

export default NavBar


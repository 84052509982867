import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTable, useSortBy } from "react-table";
import { ContactParentContext } from "../../resources/helperdata/FetchNDCData";
import { Five9Context } from "./Five9Master";
import { Accordion, Modal } from "react-bootstrap";


const Prescriptions = () => {
  const { callContactManager, setCallContactManager } = useContext(Five9Context);
    const { ndcData, fetchNDCData } = useContext(ContactParentContext);

    const [showAddPrescription, setShowAddPrescription] = useState(false);
    const handleAddPrescriptionClose = () => {setShowAddPrescription(false);resetAddForm()}
    const handleAddPrescriptionShow = () => setShowAddPrescription(true);

    const [medicationSearch, setMedicationSearch] = useState("");
    const [selectedMedicationStatus, setSelectedMedicationStatus] = useState(false);
    const [selectedMedication, setSelectedMedication] = useState([]);
    const [showMedicationDropdown, setShowMedicationDropdown] = useState(false)
    const [filteredMedications, setFilteredMedications] = useState([]);
    const [isDosageSelected, setIsDosageSelected] = useState(false);
    const [selectedDosageType, setSelectedDosageType] = useState("")
    const [isPackagingSelected, setIsPackagingSelected] = useState(false)
    const [tempPrescription, setTempPrescription] = useState({name:null,generic:null,genericmedications:null,dose:null,packingoption:null,refillquantity:"1",refillfrequency:"30"});
    const [addPrescriptionError, setAddPrescriptionError] = useState(null);
    const [genericAlternativeExists, setGenericAlternativeExists] = useState(false);
    const [viewGenericOptions, setViewGenericOptions] = useState(false);
    const [selectedDosageId, setSelectedDosageId] = useState(null)
    const [selectedPackagingId, setSelectedPackagingId] = useState(null);


    useEffect(() => {
      if(!ndcData){
        fetchNDCData()
      }
    }, []);



    useEffect(() => {
      if(tempPrescription.generic?.length > 0){
          const filteredGenerics = tempPrescription.generic.filter(genericOption => !genericOption.includes(tempPrescription.name));
          const doesAnyGenericExist = filteredGenerics.some(genericOption =>
            ndcData.some(dataItem => dataItem.brandname === genericOption)
          );
        setGenericAlternativeExists(doesAnyGenericExist);
      }
    }, [tempPrescription.generic]);





    const useCustomTable = (columns, data) => {
        const {
          getTableProps,
          getTableBodyProps,
          headerGroups,
          rows,
          prepareRow
        } = useTable({ columns, data }, useSortBy);
      
        return {
          getTableProps,
          getTableBodyProps,
          headerGroups,
          rows,
          prepareRow
        };
      };

      const prescriptionColumns = useMemo(
        () => [
          { Header: "Brand Name", accessor: "name" },
          { Header: "Generic Name", accessor: "generic" },
          { Header: "Dose", accessor: "dose" },
          { Header: "Packaging Option", accessor: "packingoption" },
          { Header: "Refill Quantity", accessor: "refillquantity" },
          { Header: "Refill Frequency", accessor: "refillfrequency" },
          {
            Header: "Actions",
            accessor: "actions",
            Cell: ({ row }) => (
              <>
                <button
                  className="btn m-0 p-1"
                  type="button"
                  onClick={() => (removePrescription(row.original.name, row.original.dose))}
                >
                  Remove
                </button>
              </>
            ),
          },
        ],
        []
      );

      const {
        getTableProps: getPrescriptionTableProps,
        getTableBodyProps: getPrescriptionTableBodyProps,
        headerGroups: prescriptionHeaderGroups,
        rows: prescriptionRows,
        prepareRow: preparePrescriptionRow,
      } = useCustomTable(prescriptionColumns, callContactManager.prescriptions);




      const filterMedicationResults = (e) => {
        const inputValue = e.target.value.toUpperCase();
        if(inputValue.length > 0){
          setShowMedicationDropdown(true)
        }else{
          setShowMedicationDropdown(false)
        }
        const filteredData = ndcData.filter(item =>
          item.brandname.startsWith(inputValue)
        );

        const limitedResults = filteredData.slice(0, 3);
        setFilteredMedications(limitedResults);
      };

      const handleMedicationClick = (obj) => {
        console.log("Selected ", obj)
        setMedicationSearch(obj.brandname);
        setShowMedicationDropdown(false);
        setSelectedMedicationStatus(true);
        setSelectedMedication(obj);

        setTempPrescription({
          name: obj.brandname,
          generic : obj.genericnames || "None",
          dose:null,
          packingoption:null,
          refillquantity:"1",
          refillfrequency:"30"
        })
      }

      const handleDosageSelect = (e, type, option) =>{
        if(e.target.checked){
          setSelectedDosageId(e.target.value)
          setIsDosageSelected(true);
          setSelectedDosageType(type);
          if(!viewGenericOptions){
            setTempPrescription(prev => ({
              ...prev,
              dose : e.target.value
            }))
          }else{
            setTempPrescription(prev => ({
              ...prev,
              name: option,
              dose : e.target.value
            }))
          }

        }else{
          setSelectedDosageId(null);
          setIsDosageSelected(false);
          setIsPackagingSelected(false);
          setSelectedDosageType(null);
          setTempPrescription(prev => ({
            ...prev,
            dose : null
          }))
        }
        if(e.target.checked && (type === "TABLET" || type === "CAPSULE")){
          setTempPrescription(prev => ({
            ...prev,
            packingoption : "BOTTLE",
            refillquantity: "30"
          }))
        }else{
          setTempPrescription(prev => ({
            ...prev,
            packingoption : null,
            refillquantity: "1"
          }))
        }
        //process for hiding other options here
      }

      const handlePackagingSelect = (e) => {
        if(e.target.checked){
          setSelectedPackagingId(e.target.value)
          setIsPackagingSelected(true)
          setTempPrescription(prev => ({
            ...prev,
            packingoption : e.target.value
          }))
        }else{
          setSelectedPackagingId(null);
          setIsPackagingSelected(false);
          setTempPrescription(prev => ({
            ...prev,
            packingoption : null,
            refillquantity:"1",
            refillfrequency:"30"
          }))
        }
      }

     const handleRefillInfo = (e) =>{
      setTempPrescription(prev => ({
        ...prev,
        [e.target.name] : e.target.value
      }))
     }


     const showGenericOptions = () => {
      console.log("show generic options");
      setViewGenericOptions(true);
      let tempObject = [];
      selectedMedication.genericnames.forEach(item => {
        const filteredData = ndcData.filter(medication =>
          medication.brandname === item
        );
        if(filteredData.length > 0){
          tempObject = [...tempObject, ...filteredData];
          setSelectedMedication(prev => ({
            ...prev,
            genericmedications : tempObject
          }))
        }else{
          setSelectedMedication(prev => ({
            ...prev,
            genericmedications : null
          }))
        }
      });
     }

     const resetAddForm = () => {
      setTempPrescription({name:null,generic:null,genericmedications:null,dose:null,packingoption:null,refillquantity:"1",refillfrequency:"30"});
      setMedicationSearch("");
      setSelectedMedicationStatus(false);
      setSelectedDosageId(null);
      setIsDosageSelected(false);
      setSelectedDosageType(null);
      setSelectedPackagingId(null);
      setIsPackagingSelected(false);
      setAddPrescriptionError(null);
      setViewGenericOptions(false);
      setGenericAlternativeExists(false);
     }


     const addPrescription = () =>{
      //check if all object elements are there, then check if an object with the same brand name is already in the array, and then if true and false respectively add, otherwise error
      const isMissing = Object.values(tempPrescription).some(value => value === null || value === "");
      if(callContactManager.prescriptions.length > 0){
        const existingPrescription = Object.values(callContactManager.prescriptions).some(prescription => (prescription.name === tempPrescription.name && prescription.dose === tempPrescription.dose))
        if(!isMissing && !existingPrescription){
          setCallContactManager(prev => ({
            ...prev,
            prescriptions : [
              ...prev.prescriptions,
              tempPrescription
            ]
        }));
        handleAddPrescriptionClose();
        }else if(isMissing){
          console.log(tempPrescription)
          setAddPrescriptionError("Please complete all fields before attempting to add a prescription")
        }else{
          setAddPrescriptionError("Identical prescription Brand and Dose already exists.")
        }
      }else{
        if(!isMissing){
          setCallContactManager(prev => ({
            ...prev,
            prescriptions : [
              ...prev.prescriptions,
              tempPrescription
            ]
        }));
        handleAddPrescriptionClose();
        }else{
          console.log(tempPrescription)
          setAddPrescriptionError("Please complete all fields before attempting to add a prescription")
        }
      }
     }

     const removePrescription = (name,dose) => {
      console.log(name,dose)
      setCallContactManager((prevState) => {
        const isPrescriptionExist = prevState.prescriptions.some((prescription) => (prescription.name === name && prescription.dose === dose));
        if (isPrescriptionExist) {
            const updatedPrescriptions = prevState.prescriptions.filter((prescription) => !(prescription.name === name && prescription.dose === dose));
            return {
              ...prevState,
              prescriptions: updatedPrescriptions,
            }
        }
        return prevState;
      });
    }
   
    return (
      <>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Recorded Prescriptions&nbsp;<span>({callContactManager.prescriptions.length})</span></Accordion.Header>
          <Accordion.Body>
          <div className="table-responsive">
                                         {callContactManager.prescriptions.length > 0 ? (
                                          <>
                                                     <table className="table text-center" {...getPrescriptionTableProps()}>
                                                     <thead>
                                                     {prescriptionHeaderGroups.map((prescriptionHeaderGroup) => (
                                                         <tr {...prescriptionHeaderGroup.getHeaderGroupProps()}>
                                                         {prescriptionHeaderGroup.headers.map((column) => (
                                                             <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                             {column.render("Header")}
                                                             <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                                                             </th>
                                                         ))}
                                                         </tr>
                                                     ))}
                                                     </thead>
                                                     <tbody {...getPrescriptionTableBodyProps()}>
                                                     {prescriptionRows.map((prescriptionRow) => {
                                                         preparePrescriptionRow(prescriptionRow);
                                                         return (
                                                         <tr {...prescriptionRow.getRowProps()}>
                                                             {prescriptionRow.cells.map((prescriptionCell, index) => (
                                                             <td
                                                             {...prescriptionCell.getCellProps()}
                                                             className={`text-center ${index === 2 || index === 4 ? 'col-1' : 'col-2'}`}
                                                             >
                                                                 {prescriptionCell.render("Cell")}
                                                             </td>
                                                             ))}
                                                         </tr>
                                                         );
                                                     })}
                                                     </tbody>
                                                 </table>
                                                 <div className="w-100 text-center"><span onClick={handleAddPrescriptionShow}>+ Add New Prescription</span></div>
                                                 </>
                                                    ) : (
                                                      <div className="w-100 text-center">
                                                      <h6>No prescriptions recorded</h6>
                                                      <div className="w-100 text-center"><span onClick={handleAddPrescriptionShow}>+ Add New Prescription</span></div>
                                                      </div>
                                                        )}
                                                    </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Modal show={showAddPrescription} onHide={handleAddPrescriptionClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Add Prescription</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                ndcData ? (
                        <div className="row w-100 m-auto pt-1 pb-3">
                        <div className="col-12">
                        <label className="form-label mb-1">Medication Name</label><input className="form-control mb-3" type="text" name="name" value={medicationSearch} onChange={(e)=> {setMedicationSearch(e.target.value);filterMedicationResults(e); setSelectedMedicationStatus(false); setIsDosageSelected(false); setIsPackagingSelected(false); setSelectedDosageType(null);setTempPrescription({name:null,generic:null,dose:null,packingoption:null,refillquantity:"1",refillfrequency:"30"}); setAddPrescriptionError(null);}}/>
                        <ul className="filteredList" style={{ display: showMedicationDropdown ? 'block' : 'none' }}>
                          {filteredMedications.map((result, index) => (   
                              <li key={index} className="specialty-li" onClick={(e)=>{handleMedicationClick(result);}}>{result.brandname}</li>
                          ))}
                        </ul>
                        {
                          selectedMedicationStatus && (
                            <div><p className="w-100 col-underline">Dosage Options {selectedMedication.genericnames?.length > 0 && genericAlternativeExists && !viewGenericOptions ? (<span className="ms-3" onClick={() => showGenericOptions()}>(Show Generic Options)</span>) : viewGenericOptions && (<span className="ms-3" onClick={() => setViewGenericOptions(false)}>(Show Brand Name Options)</span>)}</p>
                            <ul className="dosageoptionslist">
                              { !viewGenericOptions ? (
                              selectedMedication.dosageoptions.map((dosageoption, index) => (
                                dosageoption.options.map((option, index)=>(
                                  <li className="mb-2" style={{display: (!selectedDosageId || selectedDosageId === option) ? 'inherit' : 'none'}}><input type="checkbox" name="dosageCheckbox" value={option} onChange={(e) => {handleDosageSelect(e, dosageoption.type, selectedMedication.brandname)}}/>{selectedMedication.genericmedications ? `${selectedMedication.brandname} ${option}` : option}</li>
                                ))
                              ))): (
                                selectedMedication.genericmedications.map((medication, medicationIndex) => (
                                  medication.dosageoptions.map((dosageoption, index) => (
                                    dosageoption.options.map((option, optionIndex) => (
                                      <li key={optionIndex} className="mb-2" style={{display: (!selectedDosageId ||  selectedDosageId === option) ? 'inherit' : 'none'}}>
                                        <input type="checkbox" name="dosageCheckbox" value={option} onChange={(e) => { handleDosageSelect(e, dosageoption.type, medication.brandname) }} />
                                        {`${medication.brandname} ${option}`}
                                      </li>
                                    ))
                                  ))
                                ))
                              )
                            }
                            </ul>
                            </div>
                          )
                        }
                         {isDosageSelected && selectedDosageType && selectedDosageType !== "TABLET" && selectedDosageType !== "CAPSULE" && (
                            //display all the relevant packaging options for the selected thing, remember to check to see if the type is a tablet or capsule and adjust accordingly.
                              <div>
                                 <p className="w-100 col-underline">Packaging Options</p>
                                    <ul className="dosageoptionslist">
                                        {
                                        selectedMedication.packagingoptions
                                        .filter(packagingOption => packagingOption.type === selectedDosageType)
                                        .map((filteredPackagingOption, index) => (
                                          filteredPackagingOption.options.map((option, optionIndex) => (
                                            <li className="mb-2" key={optionIndex} style={{display: (!selectedPackagingId ||  selectedPackagingId === option) ? 'inherit' : 'none'}}>
                                                <input
                                                  type="checkbox"
                                                  name="dosageCheckbox"
                                                  value={option}
                                                  onChange={(e) => {handlePackagingSelect(e)}}
                                                />
                                                {option}
                                            </li>
                                          ))
                                        ))}
                                      </ul>
                              </div>
                            )}

                            { (isPackagingSelected || (selectedDosageType === "CAPSULE" || selectedDosageType === "TABLET")) && (
                               <div className="row">
                                <div className="col-6">
                                <label className="form-label mb-1" htmlFor="refillQuantity">Quantity Per Refill</label><input className="form-control mb-3" type="text" inputMode="numeric" name="refillquantity" id="refillQuantity" defaultValue={ selectedDosageType === "TABLET" || selectedDosageType === "CAPSULE" ? "30" : "1"} value={tempPrescription.refillquantity} onChange={(e)=>handleRefillInfo(e)}/>
                                </div>
                                <div className="col-6">
                                <label className="form-label mb-1" htmlFor="refillSelect" name="refillfrequency">Refill Frequency</label>
                                <select className="form-select mb-3" id="refillSelect" name="refillquantity" value={selectedMedication.refillfrequency} onChange={(e)=> handleRefillInfo(e)}>
                                      <option value="30">Every Month</option>
                                      <option value="60">Every 2 Months</option>
                                      <option value="90">Every 3 Months</option>
                                      <option value="180">Every 6 Months</option>
                                      <option value="360">Every 12 Months</option>
                                </select>
                                </div>
                                <div className="col-12 text-center p-3">
                                        <button className="btn main-button ms-4" type="button" onClick={() => addPrescription()}>Add Prescription</button>
                                        {
                                          addPrescriptionError && (
                                            <div className="w-100 mt-4">{addPrescriptionError}</div>
                                          )
                                        }
                                        
                                </div>
                               </div>
                            )
                            }
                        </div>
                      </div>

                ) : <div className="p5">Loading Data</div>
              }
            </Modal.Body>
          </Modal>
      
                                                      </>
    )
}

export default Prescriptions;
import React, { useState, useContext, useEffect} from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import Personal from "./Personal";
import AddressInfo from "./AddressInfo";
import AgentNotes from "./AgentNotes";
import EnrollmentHistory from "./EnrollmentHistory";
import Prescriptions from "./Prescriptions";
import PharmacyInfo from "./PharmacyInfo";
import Providers from "./Providers"
import EmailHistory from "./EmailHistory";
import SMSHistory from "./SMSHistory";
import { useViewContactDebounce } from "../../resources/hooks/hooks";
import { Five9Context } from "./Five9Master";
import {CallContext} from "./Call";


const ContactPortable = () =>{
    const navigate = useNavigate();
    const { token, agentId, orgId } = useContext(CallContext);
    const { callContactManager, setCallContactManager, currentCallDetails } = useContext(Five9Context);
    const [searchParams, setSearchParams] = useSearchParams({id:""});
    const [contactId, setContactId] = useState("");
    const [loaded, setLoaded] = useState(null);
    const [loadedTimeout, setLoadedTimeOut] = useState(true);
    const [isLocatingContact, setIsLocatingContact] = useState(true);
    const [locatedContacts, setLocatedContacts] = useState(null)
    const [updateText, setUpdateText] = useState("Loading contact data");

    const debouncedViewContactData = useViewContactDebounce(callContactManager)


      useEffect(()=>{
        console.log("BONER LOADED IS ", loaded)
      }, [loaded]);
      

      useEffect(()=>{
        if(currentCallDetails){
          locateContact()
        }
      },[currentCallDetails])

      useEffect(()=>{
        console.log(callContactManager)
      }, [callContactManager]); 
      
      useEffect(()=>{
        if(!loadedTimeout){
          updateContact(contactId)
        }
      },[debouncedViewContactData])



      const locateContact = async () => {
        try {
          console.log("BONER Locating Contacts...")
          const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/contact/locate?agentid=${agentId}&orgid=${orgId}&contactani=${currentCallDetails.caller_ani}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "auth-key": token
            },
          });
          const responseJson = await response.json();
          if(response.status === 200){
            setLocatedContacts(responseJson);
            setIsLocatingContact(false);
          }

        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      
      const selectContact = async (contactid) =>{
        try {
            const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/contact/select?agentid=${agentId}&orgid=${orgId}&contactid=${contactid}`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "auth-key": token
              },
            });
            const responseJson = await response.json();
            if(responseJson.data && responseJson.data.result !== "Unauthorized"){
              setIsLocatingContact(null);
                setLoaded(true)
                const contact = responseJson.data;
                const filteredCallHistory = contact.call_history?.filter(item => item.comments !== "") || [];


                setCallContactManager(prevState => ({
                    ...prevState,
                    id:contact.contact_data[0].id,
                    firstname: contact.contact_data[0].firstname || "",
                    lastname: contact.contact_data[0].lastname || "",
                    ani: contact.contact_data[0].ani || null,
                    anis:contact.contact_data[0].anis || [],
                    email: contact.contact_data[0].email || "",
                    emails: contact.contact_data[0].emails || [],
                    emailhistory: contact.email_history || [],
                    rawcallhistory: contact.call_history || [],
                    filteredcallhistory: filteredCallHistory,
                    smshistory: contact.sms_history || [],
                    providers: contact.contact_data[0].providers || [],
                    pharmacies: contact.contact_data[0].pharmacies || [],
                    medenrollments: contact.contact_data[0].medenrollments || [],
                    prescriptions: contact.contact_data[0].prescriptions || [],
                    consent: contact.contact_data[0].consent || false,
                    consenttoken: contact.contact_data[0].consenttoken || null,
                    interestedproducts: contact.contact_data[0].interestedproducts || [],
                    enrollmenthistory: contact.enrollment_history || [],
                    lis: contact.contact_data[0].lis,
                    medicaid: contact.contact_data[0].medicaid,
                    csnp: contact.contact_data[0].csnp,
                    dsnp: contact.contact_data[0].dsnp,
                    mbid: contact.contact_data[0].mbid,
                    dob: contact.contact_data[0].dob || "",
                    street: contact.contact_data[0].street,
                    unit: contact.contact_data[0].unit,
                    city: contact.contact_data[0].city,
                    state: contact.contact_data[0].state,
                    zip: contact.contact_data[0].zip,
                    addresslat: contact.contact_data[0].addresslat,
                    addresslong: contact.contact_data[0].addresslong
                  }));

                  setTimeout(() =>{
                    setLoadedTimeOut(false)
                  }, 2000)

            }else if(responseJson.data.result === "Unauthorized"){
                setUpdateText("You are not authorized to view this contact")
            }

          } catch (error) {
            console.error("Error fetching data:", error);
          }
      }


      const updateContact = async () => {
        try {
          const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/contact/update?agentid=${agentId}&contactid=${debouncedViewContactData.id}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "auth-key": token
            },
            body: JSON.stringify(debouncedViewContactData)
          });
          const responseJson = await response.json();
          console.log(responseJson)
        } catch (error) {
          console.log(error)
        }
      }

      const createNewContact = async() => {
          try {
            const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/contact/create?agentid=${agentId}&orgid=${orgId}`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "auth-key": token
              },
            });
            const responseJson = await response.json();
            if(responseJson.body && responseJson.statusCode == 200 && responseJson.body !== "Unauthorized"){
                setIsLocatingContact(null);
                setLoaded(true);

                setCallContactManager({
                    id:responseJson.body,
                    firstname: null,
                    lastname: null,
                    ani: null,
                    anis:[currentCallDetails.caller_ani],
                    email: null,
                    emails: [],
                    emailhistory: [],
                    medenrollments: [],
                    providers: [],
                    pharmacies: [],
                    prescriptions: [],
                    smshistory: [],
                    vbcenrollments: [],
                    rawcallhistory: [],
                    filteredcallhistory: [],
                    interestedproducts: [],        
                    consent: false,
                    consenttoken:null,
                    lis: null,
                    medicaid: null,
                    csnp: null,
                    dsnp: null,
                    enrollmenthistory: [],
                    mbid: null,
                    dob: null,
                    street: null,
                    unit: null,
                    city: null,
                    state: null,
                    zip: null,
                    addresslat: null,
                    addresslong: null
                });

                setTimeout(() =>{
                  setLoadedTimeOut(false)
                }, 2000)

                
            }else if(responseJson.body === "Unauthorized"){
              alert("You are not authorized to view this contact");
            }else{
              console.log(responseJson.body.statusCode)
            }
  
          } catch (error) {
            console.error("Error fetching data:", error);
          }
      }


      return (
       <>        
        <div className="row p-2 mb-4">
                <div className="col text-start">
                  {isLocatingContact ? (
                      <div className="float-all-middle">
                      <div className="text-center">
                        <img src="/img/spinner.gif" className="loadingIcon"></img>
                        <h5>Locating Existing Contacts...</h5>
                        </div>               
                    </div>
                  ) : isLocatingContact === false ? (
                    <div className="col p-2 text-center">
                        <h4 className="mb-3">Contacts Matched</h4>
                        {locatedContacts.length > 0 && (
                            locatedContacts.map((contact, index) => (
                              <div key={index} className="matchContactHolder mb-3" onClick={() => selectContact(contact.id)}>
                                   <p className="m-0 p-0">
                                      {contact.firstname} {contact.lastname ? contact.lastname : ''}
                                      <div className="float-end me-5">
                                      <i class="fas fa-chevron-right"></i>
                                      </div>
                                    </p>
                                </div>
                            ))
                        )}
                        <div className="matchContactHolder mb-3" onClick={()=>createNewContact()}>
                            <p className="m-0 p-0"><i className="fas fa-plus"></i> Create New Contact</p>
                        </div>
                    </div>
                  ):(
                    <></>
                  )}
                  
                    {loaded ? (
                        <div className="row">
                          <div className="col col-12 col-underline mb-2">
                            <div className="row">
                              <div className="col">
                                <h4 className="mb-0 pb-0">Personal Information</h4>
                              </div>
                              <div className="col text-end">
                                <button className="btn m-0 save-button" type="button" onClick={() => console.log("something")}>
                                  Begin Enrollment
                                </button>
                              </div>
                            </div>
                          </div>
                          <Personal />
                          <div className="col col-12 col-underline mb-2">
                            <h4 className="mb-0 pb-0">Address Information</h4>
                          </div>
                          <AddressInfo />
                          <div className="col col-12 col-underline mb-2">
                            <h4 className="mb-0 pb-0">Additional Information</h4>
                          </div>
                          <div className="col col-12 mb-5">
                            <Providers />
                            <PharmacyInfo />
                            <Prescriptions />
                            <EnrollmentHistory />
                            <AgentNotes />
                            <EmailHistory />
                            <SMSHistory />
                          </div>
                        </div>
                      ) : loaded === false ? (
                        <div className="float-all-middle">
                          <div className="text-center">
                            <img src="/img/spinner.gif" className="loadingIcon" alt="Loading"></img>
                            <h5>{updateText}</h5>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                </div>
            </div>
          </>
    );
      
}

export default ContactPortable;
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTable, useSortBy } from "react-table";
import { Five9Context } from "./Five9Master";
import { Accordion, Modal } from "react-bootstrap";
import areacodekey from "../../resources/helperdata/areacodekey.json";
import mailorderpharmacies from "../../resources/helperdata/mailorderpharmacies.json";
import { useDebounce, findLatLongByFirstThree } from "../../resources/hooks/hooks";




const PharmacyInfo = () => {
  const { callContactManager, setCallContactManager } = useContext(Five9Context);
    const [showAddPharmacy, setShowAddPharmacy] = useState(false);
    const handleAddPharmacyClose = () => {setShowAddPharmacy(false)}
    const handleAddPharmacyShow = () => setShowAddPharmacy(true);
    const [pharmacySearch, setPharmacySearch] = useState("");
    const debouncedPharmacySearch = useDebounce(pharmacySearch);
    const [pharmacyResults, setPharmacyResults] = useState([]);
    const [addPharmacyErrorText, setAddPharmacyErrorText] = useState();
    const [selectingMailOrder, setSelectingMailOrder] = useState(false);
    const [mailOrderInfo, setMailOrderInfo] = useState({id: null,name: "Choose",address: null,number: null,website: null})

    //In the select, just map this object and return an option for each.

    useEffect(() => {
        const shouldFetch = async () => {
            if(debouncedPharmacySearch !== "" && debouncedPharmacySearch.length > 2){
                fetchPharmacies()
            }else if(debouncedPharmacySearch.length < 2){
                setPharmacyResults([]);
            }
        }
        shouldFetch();
    }, [debouncedPharmacySearch])

   
    const useCustomTable = (columns, data) => {
        const {
          getTableProps,
          getTableBodyProps,
          headerGroups,
          rows,
          prepareRow
        } = useTable({ columns, data }, useSortBy);
      
        return {
          getTableProps,
          getTableBodyProps,
          headerGroups,
          rows,
          prepareRow
        };
      };

      const pharmacyColumns = useMemo(
        () => [
          { Header: "Pharmacy Name", accessor: "name" },
          { Header: "Address", accessor: "address" },
          { Header: "Phone Number", accessor: "number" },
          { Header: "Website", 
          accessor: "website",
          Cell: ({ row }) => (
            row.original.website ? (
                <a href={row.original.website} target="_blank">Visit Website</a>
            ):(
                <p>None recorded</p>
            )
          ),
          },
          {
            Header: "Actions",
            accessor: "actions",
            Cell: ({ row }) => (
              <>
                <button
                  className="btn m-0 p-1"
                  type="button"
                  onClick={() => (removePharmacy(row.original.id))}
                >
                  Remove
                </button>
              </>
            ),
          }
        ],
        []
      );


      const {
        getTableProps: getPharmacyTableProps,
        getTableBodyProps: getPharmacyTableBodyProps,
        headerGroups: pharmacyHeaderGroups,
        rows: pharmacyRows,
        prepareRow: preparePharmacyRow,
      } = useCustomTable(pharmacyColumns, callContactManager.pharmacies);


      const fetchPharmacies = async (token) => {
        const apiKey = 'AIzaSyDLahf2zhKgErUyr_j4w1M0h_3OHd3u454';
        const apiUrl = 'https://places.googleapis.com/v1/places:searchText?fields=places.id,places.displayName,places.nationalPhoneNumber,places.formattedAddress,places.websiteUri';
        const hasLocationData = callContactManager.addresslat !== null && callContactManager.addresslong !== null;

        if(hasLocationData){
            const requestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'X-Goog-Api-Key': apiKey
                },
                body: JSON.stringify({
                  textQuery: pharmacySearch,
                  regionCode: "US",
                  includedType: "pharmacy",
                      locationBias: {
                          circle: {
                            center: {
                              latitude: callContactManager.addresslat,
                              longitude: callContactManager.addresslong,
                            },
                            radius: 24000.0,
                          },
                        }
                }),
              };

              try {
                const response = await fetch(apiUrl, requestOptions);
                const data = await response.json();
                if(data.places?.length > 0){
                  setPharmacyResults(data.places);
                }else{
                  setPharmacyResults([]);
                }
              } catch (error) {
                console.error('Error fetching data:', error);
              }
        }else{
            const firstThree = parseInt(callContactManager.ani.toString().substring(0,3));
            const areaCodeMatch = await findLatLongByFirstThree(firstThree);
            const requestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'X-Goog-Api-Key': apiKey
                },
                body: JSON.stringify({
                  textQuery: pharmacySearch,
                  regionCode: "US",
                  includedType: "pharmacy",
                      locationBias: {
                          circle: {
                            center: {
                              latitude: areaCodeMatch.latitude,
                              longitude: areaCodeMatch.longitude,
                            },
                            radius: 24000.0,
                          },
                        }
                }),
              };

            try {
                const response = await fetch(apiUrl, requestOptions);
                const data = await response.json();
                if(data.places?.length > 0){
                  setPharmacyResults(data.places);
                }else{
                  setPharmacyResults([]);
                }
              } catch (error) {
                console.error('Error fetching data:', error);
              }
        }
      };

      const handlePharmacySelection = async (place) =>{
        setPharmacySearch("");
        const uniquePharmacy = callContactManager.pharmacies.every(item => item.id !== place.id)
        if(uniquePharmacy){
            const builtPharmacyInfo = {
                id: place.id,
                name: place.displayName.text,
                address: place.formattedAddress,
                number: place.nationalPhoneNumber,
                website: place.websiteUri
            }
            setCallContactManager(prevState => ({
                ...prevState,
                pharmacies: [...prevState.pharmacies, builtPharmacyInfo],
              }));
              handleAddPharmacyClose();
              setAddPharmacyErrorText("");
              setSelectingMailOrder(false);
              setMailOrderInfo({id: null,name: "Choose",address: null,number: null,website: null});
        }else{
            setAddPharmacyErrorText("Pharmacy address not unique, please double check and try again.")
        }
      }

      const removePharmacy = (pharmid) => {
        setCallContactManager((prevState) => {
          const isPharmacyExists = prevState.pharmacies.some((pharmacy) => pharmacy.id === pharmid);
          if (isPharmacyExists) {
              const updatedPharmacies = prevState.pharmacies.filter((pharmacy) => pharmacy.id !== pharmid);
              return {
                ...prevState,
                pharmacies: updatedPharmacies,
              }
          }
          return prevState;
        });
      }

      const handleMailOrderSelection = (e) =>{
        setAddPharmacyErrorText("")
            const selectedPharmacy = mailorderpharmacies.find(obj => obj.id === e.target.value);
            if(selectedPharmacy){
                setMailOrderInfo({id: selectedPharmacy.id, name:selectedPharmacy.name, address:"Mail Order", number: selectedPharmacy.number,website: selectedPharmacy.website})
            }
      }

      const addMailOrderPharmacy = () =>{
        setPharmacySearch("");
        const validValues = Object.values(mailOrderInfo).every(value => value !== null);
        if(validValues){
            const uniquePharmacy = callContactManager.pharmacies.every(item => item.id !== mailOrderInfo.id);
            if(uniquePharmacy){
                setCallContactManager(prevState => ({
                    ...prevState,
                    pharmacies: [...prevState.pharmacies, mailOrderInfo],
                  }));
                  handleAddPharmacyClose();
                  setAddPharmacyErrorText("");
                  setSelectingMailOrder(false);
                  setMailOrderInfo({id: null,name: "Choose",address: null,number: null,website: null});
            }else{
                setAddPharmacyErrorText(`You have already added ${mailOrderInfo.name} to this contact.`)
            }
        }else{
            setAddPharmacyErrorText("Missing Mail Order Pharmacy Values")
        }
      }


    return (
      <>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Pharmacy Information&nbsp;<span>({callContactManager.pharmacies.length})</span></Accordion.Header>
          <Accordion.Body>
          <div className="table-responsive">
                                            {callContactManager.pharmacies.length > 0 ? (
                                              <>
                                                     <table className="table text-center" {...getPharmacyTableProps()}>
                                                     <thead>
                                                     {pharmacyHeaderGroups.map((pharmacyHeaderGroup) => (
                                                         <tr {...pharmacyHeaderGroup.getHeaderGroupProps()}>
                                                         {pharmacyHeaderGroup.headers.map((column) => (
                                                             <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                             {column.render("Header")}
                                                             <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                                                             </th>
                                                         ))}
                                                         </tr>
                                                     ))}
                                                     </thead>
                                                     <tbody {...getPharmacyTableBodyProps()}>
                                                     {pharmacyRows.map((pharmacyRow) => {
                                                         preparePharmacyRow(pharmacyRow);
                                                         return (
                                                         <tr {...pharmacyRow.getRowProps()}>
                                                             {pharmacyRow.cells.map((pharmacyCell, index) => (
                                                             <td
                                                             {...pharmacyCell.getCellProps()}
                                                             className={`text-center ${index === 1 ? 'col-2' : 'col-1'}`}
                                                             >
                                                                  {pharmacyCell.render("Cell")}
                                                             </td>
                                                             ))}
                                                         </tr>
                                                         );
                                                     })}
                                                     </tbody>
                                                 </table>
                                                 <div className="w-100 text-center"><span onClick={handleAddPharmacyShow}>+ Add New Pharmacy</span></div>
                                                 </>
                                                    ) : (
                                                        <div className="w-100 text-center">
                                                        <h6>No pharmacies recorded</h6>
                                                        <div className="w-100 text-center"><span onClick={handleAddPharmacyShow}>+ Add New Pharmacy</span></div>
                                                        </div>
                                                        )}
                                                    </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Modal show={showAddPharmacy} onHide={handleAddPharmacyClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Search And Add Pharmacy Info</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                {!selectingMailOrder ? (
                    <div className="pt-2 pb-2">
                    <input className="form-control mb-0" type="text"  value={pharmacySearch} onChange={(e) => setPharmacySearch(e.target.value)}/>
                {pharmacyResults.length > 0 && (
                     <ul className="pharmacyList">
                     {pharmacyResults.map((place, index) => (   
                          <li key={place} className="pt-1 pb-1" onClick={(e)=>{handlePharmacySelection(place)}}>{place.displayName.text} - {place.formattedAddress}</li>
                      ))}
                   </ul>
                )}
                    <div className="w-100 m-auto pt-2 pb-2 text-center"><span>{addPharmacyErrorText}</span></div>
                    <div className="w-100 text-center mt-3 mb-0 pb-0"><span onClick={() => {setSelectingMailOrder(true)}}>Select Mail Order Pharmacy Instead</span></div>
                    </div>
                ):(
                    <div className="pt-2 pb-2">
                        <label>Choose Mail Order Pharmacy Name</label>
                        <select className="form-select mb-3" value={mailOrderInfo.name} name="mailOrderChoice" onChange={(e) => {handleMailOrderSelection(e)}} >
                          <option value="Choose" selected disabled>Choose</option>
                          { mailorderpharmacies.map((pharmacy) => (
                            <option value={pharmacy.id}>{pharmacy.name}</option>
                          ))
                          }
                        </select>
                        { mailOrderInfo.id && (
                            <div className="w-100 m-auto text-center"><button className="btn main-button" type="button" onClick={()=>{addMailOrderPharmacy()}}>Add Mail Order Pharmacy</button></div>
                        )
                        }
                        <div className="w-100 m-auto text-center"><span>{addPharmacyErrorText}</span></div>
                        <div className="w-100 text-center mt-3 mb-0 pb-0"><span onClick={() => {setSelectingMailOrder(false)}}>Search For Pharmacy Instead</span></div>
                    </div>
                )}
                   
                </>
            </Modal.Body>
          </Modal>
      
                                                      </>
    )
}

export default PharmacyInfo;
import React, { useState, useContext, useEffect, useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import { AccountContext } from "../Account/Account";
import { useNavigate } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import { convertDate } from "../../resources/hooks/hooks";

const useCustomTable = (columns, data) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow
    } = useTable({ columns, data }, useSortBy);
  
    return {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow
    };
  };


const Customers = () =>{
    const navigate = useNavigate();
    const { userAttributes, getSession } = useContext(AccountContext);
    const [token, setToken] = useState("");
    const [agentId, setAgentId] = useState("");
    const [orgId, setOrgId] = useState("");
    const [saleData, setSaleData] = useState([]);
    const [copySaleData, setCopySaleData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [salesPerPage,setSalesPerPage] = useState(10);
    const startIndex = (currentPage - 1) * salesPerPage;
    const endIndex = currentPage * salesPerPage;
    const [totalPages, setTotalPages] = useState(Math.ceil(saleData.length / salesPerPage));
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getSession().then((session) => {
            console.log(session)
          setToken(session.idToken.jwtToken);
          setAgentId(session["custom:agentId"]);
          setOrgId(session["custom:orgid"]);
        }).catch((err) =>{
          navigate('/auth');
        })
        ;
      }, []);

      useEffect(()=>{
        if(agentId && token && orgId){
            fetchData();
        }
      }, [agentId, token, orgId])

      useEffect(()=>{
        if(saleData.length > 0){
          setCopySaleData(saleData.slice(startIndex, endIndex));
          setTotalPages(Math.ceil(saleData.length / salesPerPage))
        }
      },[saleData]);
 

      useEffect(()=>{
        setCopySaleData(saleData.slice(startIndex, endIndex))
      },[currentPage])

      useEffect(()=>{
        setCurrentPage(1);
        setCopySaleData(saleData.slice(startIndex, endIndex))
        setTotalPages(Math.ceil(saleData.length / salesPerPage))
      },[salesPerPage])




  const MASalesColumns = useMemo(
    () => [
      { Header: "Customer Name",
      accessor: "contactname",
      Cell: ({ row }) => (
        <>
          {row.original.firstname} {row.original.lastname}
        </>
      )},
        { Header: "Submitted On",
        accessor: "time",
        Cell: ({ row }) => (
          <>
            {row.original.time ? (convertDate(row.original.time)):("-")}
          </>
        )},
        { Header: "Effective Date",
        accessor: "effectivedate",
        Cell: ({ row }) => (
          <>
            {row.original.effectivedate ? (convertDate(row.original.effectivedate)):("-")}
          </>
        )},
        { Header: "Plan Status",
        accessor: "status",
        Cell: ({ row }) => (
            <>
            {row.original.status ? (row.original.status):("-")}
            </>
        ) 
        },
        { Header: "Plan Category",
        accessor: "category",
        Cell: ({ row }) => (
          <>
            {row.original.category ? (row.original.category.toUpperCase()):("-")}
          </>
        )},
        { Header: "Confirmation ID",
        accessor: "confirmid",
        Cell: ({ row }) => (
            <>
            {row.original.id ? (row.original.id):("-")}
            </>
        ) 
        },
        { Header: "VBC Complete",
        accessor: "vbc",
        Cell: ({ row }) => (
            <>
            {row.original.vbc ? (row.original.vbc):("-")}
            </>
        ) 
        },
        { Header: "HRA Complete",
        accessor: "hra",
        Cell: ({ row }) => (
            <>
            {row.original.hra === true ? ("True"):("False")}
            </>
        ) 
        },
        {
          Header: "Actions",
          accessor: "actions",
          Cell: ({ row }) => (
            <>  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye me-2 hover-cursor" viewBox="0 0 16 16"  onClick={() => navigate(`/customers/view?id=${row.original.contactid}`)}>
            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-fire" viewBox="0 0 16 16" onClick={() => console.log("go to sunfire")}>
          <path d="M8 16c3.314 0 6-2 6-5.5 0-1.5-.5-4-2.5-6 .25 1.5-1.25 2-1.25 2C11 4 9 .5 6 0c.357 2 .5 4-2 6-1.25 1-2 2.729-2 4.5C2 14 4.686 16 8 16m0-1c-1.657 0-3-1-3-2.75 0-.75.25-2 1.25-3C6.125 10 7 10.5 7 10.5c-.375-1.25.5-3.25 2-3.5-.179 1-.25 2 1 3 .625.5 1 1.364 1 2.25C11 14 9.657 15 8 15"/>
        </svg>
           </>
          ),
        }
    ],
    []
  );


      const {
        getTableProps: getSaleTableProps,
        getTableBodyProps: getSaleTableBodyProps,
        headerGroups: saleHeaderGroups,
        rows: saleRows,
        prepareRow: prepareSaleRow,
      } = useCustomTable(MASalesColumns, copySaleData);

      const fetchData = async () => {
        try {
            const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/agentsaleshistory?agentid=${agentId}&orgid=${orgId}`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "auth-key": token
              },
            });
            const responseJson = await response.json();
            console.log(responseJson);
            if(responseJson.data.length > 0){
                setSaleData(responseJson.data);
                setIsLoading(false)
            }
          } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false)
          }
      };

      const selectPage = (p) => {
        console.log(p)
        setCurrentPage(p)
      }

      const filterSales = (e) => {
        if(e.target.value){
          const lowercaseInput = e.target.value.toLowerCase();
        let resultingData = saleData.filter(item => {
          for (let key in item) {
            const lowercaseValue = String(item[key]).toLowerCase();
            if (lowercaseValue.includes(lowercaseInput)) {
              return true;
            }
          }
          return false;
        });
          setCopySaleData(resultingData.slice(startIndex, endIndex));
          setTotalPages(Math.ceil(resultingData.length / salesPerPage))
        }else{
          setCopySaleData(saleData.slice(startIndex, endIndex))
          setTotalPages(Math.ceil(Math.ceil(saleData.length / salesPerPage)))
        }
      };
      

      return (
       <>
       <NavBar />
       <div className="row h-100">
        <Sidebar />
        <div className="col main-content">
        <div className="row p-2 mb-4">
                <div className="col">
                    <div className="row w-100 m-auto box">
                        <div className="col col-8 pb-1 border-bottom">
                            <div className="text-start">
                                <h5>Your Sales</h5>
                            </div>
                        </div>
                        <div className="col col-2 text-center border-bottom"><input type="search" className="w-100" placeholder="Search Sales..." onChange={(e)=>{filterSales(e)}}></input></div>
                        <div className="col col-2 text-center border-bottom hover-cursor" onClick={()=>{fetchData();setIsLoading(true);}}>Refresh <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
</svg></div>
                        <div className="col col-12 p-3">
                          {!isLoading ? (
                              <div className="table-responsive">
                              {copySaleData.length > 0 ? (
                                <table className="table" {...getSaleTableProps()}>
                                  <thead>
                                    {saleHeaderGroups.map((saleHeaderGroup) => (
                                      <tr {...saleHeaderGroup.getHeaderGroupProps()}>
                                        {saleHeaderGroup.headers.map((column) => (
                                          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render("Header")}
                                            <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                                          </th>
                                        ))}
                                      </tr>
                                    ))}
                                  </thead>
                                  <tbody {...getSaleTableBodyProps()}>
                                    {saleRows.map((saleRow) => {
                                      prepareSaleRow(saleRow);
                                      return (
                                        <tr {...saleRow.getRowProps()}>
                                          {saleRow.cells.map((saleCell, index) => (
                                           <td {...saleCell.getCellProps()}>
                                              {saleCell.render("Cell")}
                                         </td>
                                         
                                          ))}
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                                 ) : (
                                  <div className="w-100 m-auto text-center">
                                    <h6>No sales found</h6>
                                  </div>
                                   )}
                              </div>
                          ):(
                            <div className="float-all-middle">
                              <div className="text-center">
                                <img src="/img/spinner.gif" className="loadingIcon"></img>
                                <h5>Loading Sales Data...</h5>
                                </div>               
                            </div>
                          )}
                        
            <div className="w-100">
              <div className="d-inline-flex">
              <nav aria-label="Page navigation">
                      <ul className="pagination">
                        {Array.from({ length: totalPages }, (_, index) => index + 1).map(pageNumber => (
                          <li key={pageNumber} className="page-item">
                           <button className={`${currentPage === pageNumber ? 'active ' : ''}page-link`} onClick={() => {selectPage(pageNumber);}}>
                              {pageNumber}
                            </button>
                          </li>
                        ))}
                        </ul>
                          </nav>
              </div>
              <div className="d-inline-flex ms-4">
              <select className="form-select" value={salesPerPage} onChange={(e) => {setSalesPerPage(e.target.value)}}>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                        </select>
              </div>

            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
       </>
      );
      
}

export default Customers;
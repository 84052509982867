import React, { useState, createContext, useEffect, useRef} from "react";

const SunfireContext = createContext();


const SunfireMaster = (props) =>{
    const [sunfireUserCredentials, setSunfireUserCredentials] = useState({username: null,password:null})



    ////YOU NEED TO BUILD IN A CALL TO PULL CURRENT LOGIN CREDENTIALS FROM THE DB

    const integrateSunfire = async () => {
                    return {status:200, body:'Successfully Integrated'}
      }

      return (
        <SunfireContext.Provider value={{ sunfireUserCredentials, setSunfireUserCredentials, integrateSunfire }}>
             {props.children}
        </SunfireContext.Provider>  
    );
      
}

export {SunfireMaster, SunfireContext};
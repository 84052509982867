import React, {useContext} from "react";
import { ContactContext } from "./ContactParent";
import { Accordion } from "react-bootstrap";

const AgentNotes = () => {
    const { viewContactData, setViewContactData } = useContext(ContactContext);
    return (
        <>
       <Accordion >
       <Accordion.Item eventKey="0">
                        <Accordion.Header>Agent Notes&nbsp;<span>({viewContactData.filteredcallhistory.length || 0})</span></Accordion.Header>
                           <Accordion.Body>
                                <div><ul className="timeline">
                                    {
                                        viewContactData.filteredcallhistory.map((item, index) => (
                                            <li className="timeline-item mb-5">
                                            <p className="fw-bold">{new Date(item.time).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric'})} - {item.agentusername}</p>
                                            <p className="text-muted mb-2">Comments: {item.comments}</p>
                                            </li>
                                        ))
                                    }
                        </ul></div>
                            </Accordion.Body>
                        </Accordion.Item>
                        </Accordion>
        </>
    )
}
export default AgentNotes;
import React, {useContext, useEffect, useState} from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ContactContext } from "./ContactParent";
import { AccountContext } from "../Account/Account";
import SendEmail from "./SendEmail";
import SendSMS from "./SendSMS";
import { Modal, Card } from "react-bootstrap";
import { useDebounce, formatPhoneNumber } from "../../resources/hooks/hooks";

const Personal = () => {
    const { viewContactData, setViewContactData } = useContext(ContactContext);
    const { userAttributes, getToken } = useContext(AccountContext);
    const navigate = useNavigate();
    const [sendEmail, setSendEmail] = useState(false);
    const [sendSMS, setSendSMS] = useState(false);
    const [selectedANI, setSelectedANI] = useState(viewContactData?.anis[0])
    const [selectedEmail, setSelectedEmail] = useState(viewContactData?.emails[0]);
    const [searchParams, setSearchParams] = useSearchParams({id:""});
    const [customerMBID, setCustomerMBID] = useState(null);
    const debouncedMBID = useDebounce(customerMBID);
    const [loadedTimeout, setLoadedTimeOut] = useState(true);

    const [showAddAni, setShowAddAni] = useState(false);
    const handleAddAniClose = () => {setShowAddAni(false);setAddAniError("");setTempAni("")}
    const handleAddAniShow = () => setShowAddAni(true);
    const [tempAni, setTempAni] = useState(null);
    const [addAniError, setAddAniError] = useState("");

    const [showAddEmail, setShowAddEmail] = useState(false);
    const handleAddEmailClose = () => {setShowAddEmail(false);setAddEmailError("");setTempEmail("")}
    const handleAddEmailShow = () => setShowAddEmail(true);
    const [tempEmail, setTempEmail] = useState(null);
    const [addEmailError, setAddEmailError] = useState("");

    const [showDupeContacts, setShowDupeContacts] = useState(false);
    const [dupeContactList, setDupeContactList] = useState([]);
    const handleDupeContactClose = () => {setShowDupeContacts(false)}
    const handleDupeContactShow = () => setShowDupeContacts(true);



    const [isMergingContact, setIsMergingContact] = useState(false);
    const [mergeError, setMergeError] = useState(null);
    const [mergeErrorText, setMergeErrorText] = useState(null);
    const [newContact, setNewContact] = useState(null)

    const handleChange = (e) => {
      const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setViewContactData(prevState => ({
          ...prevState,
          [e.target.name] : value
      }))
  }

  /*

    const formatPhoneNumber = (phoneNumber) => {
      let formattedNumber = '';
      const numberString = String(phoneNumber)
    
      if (numberString.length === 10) {
        formattedNumber = `(${numberString.substring(0, 3)}) ${numberString.substring(3, 6)}-${numberString.substring(6)}`;
      }else{
        console.log(numberString.length)
      }
    
      return formattedNumber;
    }
  */
  

  useEffect(()=>{
    console.log(dupeContactList)
  },[dupeContactList])

  useEffect(()=>{
    if(newContact !== null){
      navigate(`/customers/view?id=${newContact}`);
      window.location.reload();
    }
  },[newContact])

  useEffect(() => {
    const shouldFetch = async () => {
        if(debouncedMBID && debouncedMBID.trim().length === 11){
          try {
            const data = {
              agentid:userAttributes["custom:agentId"], 
              orgid:userAttributes["custom:orgid"],
              contactid:searchParams.get("id"),
              mbid:debouncedMBID.trim()
            }
            const token = await fetchToken();
            const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/contact/dedupe/mbid`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "auth-key": token
              },
              body: JSON.stringify(data)
            });
            const responseJson = await response.json();
            if(responseJson.length > 0 && response.status){
              setShowDupeContacts(true);
              setDupeContactList(responseJson)
            }
          } catch (error) {
            console.log(error)
          }
        }
    }
    shouldFetch();
}, [debouncedMBID])

  const handleConsentChange = (e) => {
   
   if(e.target.value === "true"){
      let token;
      viewContactData.ani ? token = `${viewContactData.ani}-${Date.now()}` : viewContactData.email ? token = `${viewContactData.email}-${Date.now()}` :  token = `agent-${Date.now()}`;
      setViewContactData(prevState => ({
        ...prevState,
        [e.target.name] : e.target.value,
        consenttoken: token
    }))
    }else if(e.target.value === "false"){
      setViewContactData(prevState => ({
        ...prevState,
        [e.target.name] : e.target.value,
        consenttoken: null
    }))
    }
  }

  const handleInterestChange = (e) =>{

    if(e.target.checked){
      if(!viewContactData.interestedproducts.includes(e.target.name)){
        setViewContactData(prevState => ({
          ...prevState,
          interestedproducts: [...prevState.interestedproducts, e.target.name]
        }))
      }
    }else{
      if (viewContactData.interestedproducts.includes(e.target.name)) {
        setViewContactData(prevState => ({
          ...prevState,
          interestedproducts: prevState.interestedproducts.filter(item => item !== e.target.name)
        }));
      }
    }
  }

  const handleANIChange = (e) => {
    const selectedOptionValue = e.target.value;
    const selectedOptionName = e.target.name;
    if (selectedOptionValue !== "addAni" && !selectedOptionValue.includes("remove")) {
      setSelectedANI(selectedOptionValue);
    }else if(selectedOptionValue === "addAni"){
      setShowAddAni(true)
    }else if(selectedOptionValue.includes("remove")){
      const numberToRemove = selectedOptionValue.split("-")[1];
      const newContactAniList = viewContactData.anis.filter(item => item !== parseInt(numberToRemove))
      setViewContactData(prevState => ({
        ...prevState,
        anis: newContactAniList
      }));
      setSelectedANI(viewContactData?.anis[0])
    }
  }


  const handleEmailChange = (e) => {
    const selectedOptionValue = e.target.value;
    const selectedOptionName = e.target.name;
    if (selectedOptionValue !== "addEmail" && !selectedOptionValue.includes("remove")) {
      setSelectedEmail(selectedOptionValue);
    }else if(selectedOptionValue === "addEmail"){
      setShowAddEmail(true)
    }else if(selectedOptionValue.includes("remove")){
      const emailToRemove = selectedOptionValue.split("-")[1];
      const newContactEmailList = viewContactData.emails.filter(item => item !== emailToRemove)
      setViewContactData(prevState => ({
        ...prevState,
        emails: newContactEmailList
      }));
      setSelectedEmail(viewContactData?.emails[0])
    }
  }

  const handleMBIDChange = async(e) =>{
      setViewContactData(prevState => ({
        ...prevState,
        [e.target.name] : e.target.value.trim()
    }));
    setCustomerMBID(e.target.value.trim());
  }

  const mergeContact = async(id) =>{
    setIsMergingContact(true);
    try {
      const data = {
        agentid:userAttributes["custom:agentId"], 
        orgid:userAttributes["custom:orgid"],
        current: searchParams.get("id"),
        into: id,
        data: viewContactData
      }
      const token = await fetchToken();
      const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/contact/dedupe/merge`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-key": token
        },
        body: JSON.stringify(data)
      });
      if(response.status === 200){
        setMergeError(false)
          setTimeout(()=>{
            console.log(`should navigate to ${id}`);
            setNewContact(id);
          }, 3000)
      }else{
        console.log(response.status);
        setMergeError(true);
        setMergeErrorText(response.body);
        //error was present, show error and display appropriate options. If status = this then that, do not allow reattmepts for certain statuscodes.
      }
    } catch (error) {
      console.log(error)
    }
  }

  const isNumericInput = (event) => {
    const key = event.keyCode;
    return ((key >= 48 && key <= 57) || // Allow number line
        (key >= 96 && key <= 105) // Allow number pad
    );
    };
    
    const isModifierKey = (event) => {
    const key = event.keyCode;
    return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
        (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
        (key > 36 && key < 41) || // Allow left, up, right, down
        (
            // Allow Ctrl/Command + A,C,V,X,Z
            (event.ctrlKey === true || event.metaKey === true) &&
            (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
        )
    };
    
    const enforceFormat = (event) => {
    if(!isNumericInput(event) && !isModifierKey(event)){
        event.preventDefault();
    }
    };
    
    const formatToPhone = (event) => {
    if(isModifierKey(event)) {return;}
    const target = event.target;
    const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
    const zip = input.substring(0,3);
    const middle = input.substring(3,6);
    const last = input.substring(6,10);
    
    if(input.length > 6){target.value = `(${zip}) ${middle}-${last}`;}
    else if(input.length > 3){target.value = `(${zip}) ${middle}`;}
    else if(input.length > 0){target.value = `(${zip}`;}
    };

    const addNewAni = (e) =>{
      e.preventDefault();
      setAddAniError("")
      let strippedAni = parseInt(tempAni.replace("(","").replace(")","").replace(" ","").replace("-",""));
      const alreadyExists = viewContactData.anis.includes(strippedAni);
      if(!alreadyExists){
        setViewContactData(prevState => ({
          ...prevState,
          anis: [...prevState.anis, strippedAni]
        }));
        setSelectedANI(strippedAni);
        handleAddAniClose();
      }else{
        //ANI Already Exists for this contact
        setAddAniError("This phone number already exists for this contact");
        setSelectedANI(strippedAni);
      }
    }

    const addNewEmail = (e) =>{
      e.preventDefault();
      setAddEmailError("")
      const alreadyExists = viewContactData.emails.includes(tempEmail?.trim());
      if(!alreadyExists){
        setViewContactData(prevState => ({
          ...prevState,
          emails: [...prevState.emails, tempEmail?.trim()]
        }));
        setSelectedEmail(tempEmail?.trim());
        handleAddEmailClose();
      }else{
        //ANI Already Exists for this contact
        setAddEmailError("This email address already exists for this contact");
        setSelectedEmail(tempEmail?.trim());
      }
    }

    const fetchToken = async () => {
      try {
          const token = await getToken();
          return token;
      } catch (err) {
          console.log(err);
          return null;
      }
  }

    return (
        <>
         <div className="col mb-5">
                        <label className="form-label mb-1">First Name</label>
                        <input className="form-control mb-3" type="text" value={viewContactData.firstname} name="firstname" onChange={(e) => {handleChange(e)}} />
                        <label className="form-label mb-1">Email Address</label>
                        <div className="input-group">
                        <select className="form-select mb-3" value={selectedEmail} name="email" onChange={(e) => handleEmailChange(e)}>
                        {viewContactData.emails.length > 0 ? (
                          <>
                            {viewContactData.emails.map((email, index) => (   
                              <option key={index} name={email}>{email}</option>
                            ))}
                            {viewContactData.emails.map((email, index) => (   
                              <option key={index} value={`remove-${email}`}>- Remove {email}</option>
                            ))}
                            <option value="addEmail">+ Add New Email Address</option>
                          </>
                        ):(
                          <>
                          <option></option>
                          <option value="addEmail">+ Add New Email Address</option>
                          </>
                        )}
                      </select>
                        <span className="icon-inside" onClick={(e)=> {setSendEmail(true)}}><i className="fas fa-envelope"></i></span>
                         </div>                 
                          <div className="row m-04375">
                            <div className="col">
                            <p className="mb-1">Products Sold</p>
                        <div className="form-check d-inline-flex me-3 mb-3">
                          <input className="form-check-input" type="checkbox" id="formCheck-17" checked={viewContactData.enrollmenthistory?.some(item => item.category === "ma") ? true : false} name="ma_sold" readOnly/>
                          <label className="form-check-label" htmlFor="formCheck-17">&nbsp;MA</label>
                        </div>
                        <div className="form-check d-inline-flex me-3 mb-3">
                          <input className="form-check-input" type="checkbox" id="formCheck-18" checked={viewContactData.enrollmenthistory?.some(item => item.category === "mapd") ? true : false} name="mapd_sold" readOnly/>
                          <label className="form-check-label" htmlFor="formCheck-18">&nbsp;MAPD</label>
                        </div>
                        <div className="form-check d-inline-flex me-3 mb-3">
                          <input className="form-check-input" type="checkbox" id="formCheck-19" checked={viewContactData.enrollmenthistory?.some(item => item.category === "pdp") ? true : false} name="pdp_sold" readOnly/>
                          <label className="form-check-label" htmlFor="formCheck-19">&nbsp;PDP</label>
                        </div>
                        <div className="form-check d-inline-flex me-3 mb-3">
                          <input className="form-check-input" type="checkbox" id="formCheck-20" checked={viewContactData.enrollmenthistory?.some(item => item.category === "vbc") ? true : false} name="vbc_sold" readOnly/>
                          <label className="form-check-label" htmlFor="formCheck-20">&nbsp;VBC</label>
                        </div>
                            </div>
                            <div className="col">
                            <p className="mb-1">Interested Products</p>
                        <div className="form-check d-inline-flex me-3 mb-3">
                          <input className="form-check-input" type="checkbox" id="formCheck-21" checked={viewContactData.enrollmenthistory?.some(item => item.category === "ma") ? true : viewContactData.interestedproducts?.includes("ma") ? true : false} name="ma" onChange={(e) => {handleInterestChange(e)}}/>
                          <label className="form-check-label" htmlFor="formCheck-21">&nbsp;MA</label>
                        </div>
                        <div className="form-check d-inline-flex me-3 mb-3">
                          <input className="form-check-input" type="checkbox" id="formCheck-22" checked={viewContactData.enrollmenthistory?.some(item => item.category === "mapd") ? true : viewContactData.interestedproducts?.includes("mapd") ? true : false} name="mapd" onChange={(e) => {handleInterestChange(e)}}/>
                          <label className="form-check-label" htmlFor="formCheck-22">&nbsp;MAPD</label>
                        </div>
                        <div className="form-check d-inline-flex me-3">
                          <input className="form-check-input" type="checkbox" id="formCheck-23" checked={viewContactData.enrollmenthistory?.some(item => item.category === "pdp") ? true : viewContactData.interestedproducts?.includes("pdp") ? true : false} name="pdp" onChange={(e) => {handleInterestChange(e)}}/>
                          <label className="form-check-label" htmlFor="formCheck-23">&nbsp;PDP</label>
                        </div>
                        <div className="form-check d-inline-flex me-3 mb-3">
                          <input className="form-check-input" type="checkbox" id="formCheck-24" checked={viewContactData.enrollmenthistory?.some(item => item.category === "vbc") ? true : viewContactData.interestedproducts?.includes("vbc") ? true : false} name="vbc" onChange={(e) => {handleInterestChange(e)}}/>
                          <label className="form-check-label" htmlFor="formCheck-24">&nbsp;VBC</label>
                        </div>
                            </div>
                                  </div>
                   
                        <label className="form-label w-100 mb-1">Low Income Subsidy</label>
                        <select className="form-select mb-3" value={viewContactData.lis} name="lis" onChange={(e) => {handleChange(e)}} >
                          <option value="">Unknown</option>
                          <option value="0">No</option>
                          <option value="1">Yes - Level 1</option>
                          <option value="2">Yes - Level 2</option>
                          <option value="3">Yes - Level 3</option>
                        </select>
                        <label className="form-label w-100 mb-1">C-SNP Eligible</label>
                        <select className="form-select mb-3" value={viewContactData.csnp} name="csnp" onChange={(e) => {handleChange(e)}}>
                          <option value="">Unknown</option>
                          <option value="false">No</option>
                          <option value="true">Yes</option>
                        </select>
                        <label className="form-label w-100 mb-1">Medicare Number</label>
                        <input className="form-control mb-3" type="text" value={viewContactData.mbid}  maxLength="11" name="mbid" onChange={(e) => {handleMBIDChange(e)}}/>
                      </div>
                      <div className="col mb-5">
                        <label className="form-label mb-1">Last Name</label>
                        <input className="form-control mb-3" type="text" value={viewContactData.lastname} name="lastname" onChange={(e) => {handleChange(e)}} />
                        <label className="form-label mb-1">Phone Number(s)</label>
                        <div className="input-group">
                        <select className="form-select mb-3" value={selectedANI} name="ani" onChange={(e) => handleANIChange(e)}>
                        {viewContactData.anis.length > 0 ? (
                          <>
                            {viewContactData.anis.map((ani, index) => (   
                              <option key={index} name={ani}>{formatPhoneNumber(ani)}</option>
                            ))}
                            {viewContactData.anis.map((ani, index) => (   
                              <option key={index} value={`remove-${ani}`}>- Remove {formatPhoneNumber(ani)}</option>
                            ))}
                            <option value="addAni">+ Add New Phone Number</option>
                          </>
                        ):(
                          <>
                          <option></option>
                          <option value="addAni">+ Add New Phone Number</option>
                          </>
                        )}
                      </select>
                        <span className="icon-inside" onClick={(e)=> {setSendSMS(true)}}><i className="fas fa-comment"></i></span>
                        </div>
                        <label className="form-label w-100 mb-1">Medicaid Assistance</label>
                        <select className="form-select mb-3" value={viewContactData.medicaid} name="medicaid" onChange={(e) => {handleChange(e)}}>
                          <option value="">Unknown</option>
                          <option value="0">No</option>
                          <option value="FBDE">Yes - FBDE</option>
                          <option value="QMB Only">Yes - QMB Only</option>
                          <option value="QMB+">Yes - QMB+</option>
                          <option value="SLMB Only">Yes - SLMB</option>
                          <option value="SLMB">Yes - SLMB+</option>
                          <option value="QDWI">Yes - QDWI</option>
                          <option value="QI">Yes - QI</option>
                        </select>
                        <label className="form-label w-100 mb-1">D-SNP Eligible</label>
                        <select className="form-select mb-3" value={viewContactData.dsnp} name="dsnp" onChange={(e) => {handleChange(e)}}>
                          <option value="">Unknown</option>
                          <option value="false">No</option>
                          <option value="true">Yes</option>
                        </select>
                        <label className="form-label w-100 mb-1">Date Of Birth</label>
                        <input className="form-control mb-3" type="date" name="dob"  value={viewContactData.dob} onChange={(e) => {handleChange(e)}}/>
                        <label className="form-label w-100 mb-1">Permission To Contact</label>
                        <select className="form-select mb-3" name="consent" value={viewContactData.consent} onChange={(e) => {handleConsentChange(e)}}>
                          <option value="false">False</option>
                          <option value="true">True</option>
                        </select>
                      </div>
                      {sendEmail && <SendEmail showEmailModal={sendEmail} setEmailValue={setSendEmail}/>}
                      {sendSMS && <SendSMS showSMSModal={sendSMS} setSMSValue={setSendSMS}/>}


                      <Modal show={showAddAni} onHide={handleAddAniClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Add New Phone Number</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={addNewAni}>
                <input className="form-control mb-3" type="text" placeholder="Enter New Number" value={tempAni} required minLength="14" maxLength="14" onKeyDown={(e) => {enforceFormat(e)}} onKeyUp={(e) => {formatToPhone(e)}} onChange={(e) => {setTempAni(e.target.value)}}/>
                <div className="w-100 text-center"><button type="submit" className="btn main-button">Add Number</button></div>
                <div className="w-100 text-center">{addAniError}</div>
                </form>
              </Modal.Body>
            </Modal>

            <Modal show={showAddEmail} onHide={handleAddEmailClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Add New Email Address</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={addNewEmail}>
                <input className="form-control mb-3" type="text" placeholder="Enter New Number" value={tempEmail} required onChange={(e) => {setTempEmail(e.target.value)}}/>
                <div className="w-100 text-center"><button type="submit" className="btn main-button">Add Email</button></div>
                <div className="w-100 text-center">{addEmailError}</div>
                </form>
              </Modal.Body>
            </Modal>

            <Modal show={showAddAni} onHide={handleAddAniClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Add New Phone Number</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={addNewAni}>
                <input className="form-control mb-3" type="text" placeholder="Enter New Number" value={tempAni} required minLength="14" maxLength="14" onKeyDown={(e) => {enforceFormat(e)}} onKeyUp={(e) => {formatToPhone(e)}} onChange={(e) => {setTempAni(e.target.value)}}/>
                <div className="w-100 text-center"><button type="submit" className="btn main-button">Add Number</button></div>
                <div className="w-100 text-center">{addAniError}</div>
                </form>
              </Modal.Body>
            </Modal>

            <Modal show={showDupeContacts} onHide={handleDupeContactClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Potential Duplicate Contacts Found!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {!isMergingContact ? (
                <>
                <div className="d-flex flex-row flex-nowrap card-holder justify-content-center mb-5">
                {dupeContactList.map((contact, index) => (
                    <Card className={"duplicate-contact-card"}>
                      <Card.Body>
                      <div className="w-100 m-auto text-center">
                              <h4>{contact.firstname} {contact.lastname}</h4>
                          </div>
                          <hr className="hr-divider" />
                          <div className="pb-2">
                              <p className="text-start text-muted mb-0">Phone Number(s)</p>
                              { contact.anis.length > 0 ? (
                                 contact.anis?.map((ani) => (
                                  <p className="text-start mb-1">{formatPhoneNumber(ani)}</p>
                                ))
                              ):(
                                <p className="text-start">Unknown</p>
                              )
                              }
                          </div>
                          <div className="pb-2">
                              <p className="text-start text-muted mb-0">Email(s)</p>
                              { contact.emails.length > 0 ? (
                                 contact.emails?.map((email) => (
                                  <p className="text-start mb-1">{email}</p>
                                ))
                              ):(
                                <p className="text-start">Unknown</p>
                              )
                              }
                          </div>
                          <div className="pb-2">
                              <p className="text-start text-muted mb-0">City / State / Zip</p>
                              {
                                contact.city || contact.state || contact.zip ? (
                                  <p className="text-start">{contact.city ? `${contact.city},` : null} {contact.state ? `${contact.state},` : null} {contact.zip ? contact.zip : null}</p>
                                ):(
                                  <p className="text-start">Unknown</p>
                                  )
                              }
      
                          </div>
                          <div className="pb-2">
                              <p className="text-start text-muted mb-0">Medicare Number (Last 4)</p>
                              <p className="text-start">{contact.mbid ? contact.mbid : "Unknown"}</p>
                          </div>
                          <div className="text-center"><button className="btn main-button" type="button" onClick={()=>{mergeContact(contact.id)}}>Merge Contacts</button></div>
                      </Card.Body>
                    </Card>
                ))}
                </div>
                <div class="w-100 m-auto text-center pt-2"><button className="btn btn-light" type="button" onClick={handleDupeContactClose}>Continue Without Merging</button></div>
                </>
              ):(
                  mergeError === null ? (
                    <div className="w-100 p-3 text-center">
                        <div className="mb-3">
                            <img src="/img/spinner.gif" className="loadingIcon" />
                        </div>
                        <div className="w-100 p-0">
                            <h5>Merging Contacts...</h5>
                        </div>
                    </div>
                ) : (
                    mergeError === true ? (
                          <div className="w-100 p-3 text-center">
                          <div className="mb-3">
                              <img src="/img/error.png" className="resultIcon" />
                          </div>
                          <div className="w-100 p-0">
                              <h5>{mergeErrorText}</h5>
                          </div>
                      </div>
                    ) : (
                         <div className="w-100 p-3 text-center">
                                <div className="mb-3">
                                    <img src="/img/success.png" className="resultIcon" />
                                </div>
                                <div className="w-100 p-0">
                                    <h5>Successfully Merged Contacts!</h5>
                                </div>
                            </div>
                    )
                )
              )}                        
              </Modal.Body>
            </Modal>
        </>
    )
}

export default Personal;
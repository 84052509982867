import React from "react";
import "./App.css";
import "./resources/styles/styles.min.css";
import Login from "./components/Account/Login";
import { Account } from "./components/Account/Account";
import ManageAccount from "./components/Account/ManageAccount";
import { OrgDetails } from "./resources/helperdata/OrgDetails";
import {Five9Master} from "./components/Five9/Five9Master";
import {SunfireMaster} from "./components/Sunfire/SunfireMaster";
import Dashboard from "./components/Dashboard/Dashboard";
import Sales from "./components/RecordSale/Sales";
import MySales from "./components/MySales/MySales"
import Leaderboards from "./components/Leaderboards/Leaderboard";
import {Call} from "./components/Five9/Call"
import { ContactParentProvider } from "./resources/helperdata/FetchNDCData";
import Customers from "./components/Customers/Customers";
import {ContactParent} from "./components/Contact/ContactParent";
import CallHistory from "./components/CallHistory/CallHistory";
import ErrorPage from "./components/ErrorPage";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

const App = () => {
  return (
    <div className="App">
      <Account>
        <OrgDetails>
        <Five9Master>
          <SunfireMaster>
          <Router>
            <Routes>
              <Route path="/auth" element={<Login />} />
              <Route path="/account" element={<ManageAccount />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/customers/view" element={<ContactParentProvider><ContactParent /> </ContactParentProvider>} />
              <Route path="/recordsale" element={<Sales />} />
              <Route path="/sales" element={<MySales />} />
              <Route path="/leaderboard" element={<Leaderboards />} />
              <Route path="/call" element={<ContactParentProvider><Call /></ContactParentProvider>} />
              <Route path="/callhistory" element={<CallHistory />} />
              <Route path="/" element={<Navigate to="/auth" />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </Router>
          </SunfireMaster>
          </Five9Master>
          </OrgDetails>
      </Account>
    </div>
  );
};

export default App;

import React, { useState, useContext, useEffect } from "react";
import { SunfireContext } from "../Sunfire/SunfireMaster";
import { AccountContext } from "../Account/Account";

const SunfireIntegration = (props) =>{
  const { sunfireUserCredentials, setSunfireUserCredentials, integrateSunfire } = useContext(SunfireContext);
  const { userAttributes } = useContext(AccountContext);
  const [isEditing, setIsEditing] = useState(false);
  const [integrationAttempting, setIntegrationAttempting] = useState(false)
  const [integrationStatus, setIntegrationStatus] = useState("Not Integrated");

  const handleChange = (e) => {
    setSunfireUserCredentials(prevState => ({
      ...prevState,
      [e.target.name] : e.target.value
      })
    )
  }

  const attemptIntegration = async () => {
    if(sunfireUserCredentials.username && sunfireUserCredentials.password){
      setIntegrationAttempting(true);
      setIntegrationStatus(false);
      const integrationResult = await integrateSunfire();
      if(integrationResult?.status === 200){
        setIntegrationAttempting(false);
        setIsEditing(false);
        setIntegrationStatus(integrationResult.body);
        const updateObject = {
          agentid: userAttributes["custom:agentId"],
          orgid: userAttributes["custom:orgid"],
          newusername: sunfireUserCredentials.username,
          newpassword: sunfireUserCredentials.password
        }
        try {
          const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/account/updatesunfirecredentials`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "auth-key": props.access
            },
            body: JSON.stringify(updateObject)
          });
          const responseJson = await response.json();

        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }else{
        setIntegrationAttempting(false);
        setIntegrationStatus(integrationResult.body);
      }
    }
  }

    return (
      <div className="card">
                        <div className="card-body text-center">
                          <div className="editIntegration" onClick={()=>setIsEditing(true)}><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-people me-2">
                            <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"></path>
                        </svg></div>
                        <img src="/img/sunfire-integration-logo.png" className="w-25 mb-3"></img>
                            <h3 className="card-title">Sunfire</h3>
                            {isEditing && (
                              <>
                              <div className="text-start">
                              <label className="form-label mb-1">Your Username</label>
                              <input className="form-control mb-3" type="text" value={sunfireUserCredentials.username} name="username" readOnly={integrationAttempting} onChange={(e) => {handleChange(e)}} />
                              <label className="form-label mb-1">Your Password</label>
                              <input className="form-control mb-3" type="text" value={sunfireUserCredentials.password} name="password" readOnly={integrationAttempting} onChange={(e) => {handleChange(e)}} />
                              <div className="row mb-4">
                                <div className="col-6 text-center">
                                  <button className="btn btn-light" onClick={()=>setIsEditing(false)}>Cancel</button>
                                </div>
                                <div className="col-6 text-center">
                                  <button className="btn main-button" onClick={attemptIntegration}>Update Integration</button>
                                </div>
                              </div>
                              </div>
                              </>
                            )}
                            <h6 className="text-muted card-subtitle mb-2">{integrationStatus}</h6>
                        </div>
                    </div>
      );
}

export default SunfireIntegration;
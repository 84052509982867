import React, { useState, createContext, useEffect, useRef, useContext} from "react";
import { AccountContext } from "../Account/Account";
import { UserAgent, Registerer, RegistererOptions, UserAgentOptions, URI } from 'sip.js';
import { v4 as uuidv4 } from 'uuid';

const Five9Context = createContext();


const Five9Master = (props) =>{
    const { integrationCredentials, pullIntegrationCredentials, activeSession, userAttributes } = useContext(AccountContext);
    const [integrationStatus, setIntegrationStatus] = useState(false);
    const [browserPermissionGranted, setBrowserPermissionGranted] = useState(false);
    const [five9UserCredentials, setFive9UserCredentials] = useState({username: null,password:null,workstationId:null})
    const [domainNotReadyCodes, setDomainNotReadyCodes] = useState(null);
    const [domainCampaigns, setDomainCampaigns] = useState(null);
    const [domainDispositions, setDomainDispositions] = useState(null);
    const [userStatus, setUserStatus] = useState("Offline");
    const [five9WebSocketData, setFive9WebSocketData] = useState(null);
    const [five9SoftphoneConfigInfo, setFive9SoftphoneConfigInfo] = useState(null)
    const [socketStatus, setSocketStatus] = useState(false);
    const [currentCall, setCurrentCall] = useState(false) //default should be false
    const [currentCallDetails, setCurrentCallDetails] = useState(null);
    const [callContactManager, setCallContactManager] = useState({
      id:null,
      firstname: null,
      lastname: null,
      ani: null,
      anis:[],
      email: null,
      emails: [],
      emailhistory: [],
      medenrollments: [],
      providers: [],
      pharmacies: [],
      prescriptions: [],
      smshistory: [],
      vbcenrollments: [],
      rawcallhistory: [],
      filteredcallhistory: [],
      interestedproducts: [],        
      consent: false,
      consenttoken:null,
      lis: null,
      medicaid: null,
      csnp: null,
      dsnp: null,
      enrollmenthistory: [],
      mbid: null,
      dob: null,
      street: null,
      unit: null,
      city: null,
      state: null,
      zip: null,
      addresslat: null,
      addresslong: null
  }) 

  const [userAgent, setUserAgent] = useState(null);




    useEffect(()=>{
      const integrationHandler = async () => {
        if(activeSession && !socketStatus){
          setFive9WebSocketData(null);
            if(integrationCredentials){
                const integrate = await integrateFive9();
                if(integrate){
                  setTimeout(()=>{
                    connectServerWebsocket();
                  },2000)
                } 
            }else if(!socketStatus){
              setFive9WebSocketData(null);
              const fetchCreds = await pullIntegrationCredentials();
              if(fetchCreds[0].five9username && fetchCreds[0].five9password){
                const integrate = await integrateFive9();
                if(integrate){
                  setTimeout(()=>{
                    connectServerWebsocket();
                  },2000)
                } 
              }
            }
        }
      }
      integrationHandler();
    },[activeSession])



const connectServerWebsocket = () => {
  const randomSocketKey = (Math.random() * 1000 + 1).toString().split('.')[0];
  let heartBeat;
    const ws = new WebSocket(`wss://app-atl.five9.com:443/appsvcs/ws/${randomSocketKey}`);
    console.log("BONER connecting")

    // Event listener for WebSocket open
    ws.onopen = () => {
      setSocketStatus(true);
      console.log('BONER WebSocket connected');
       heartBeat = setInterval(()=>{ws.send('ping');},15000);

    };

    // Event listener for WebSocket messages
    ws.onmessage = (event) => {
      const messageData = JSON.parse(event.data);
      const messageId = messageData.context.eventId;

        switch (messageId){
          case "39":
            // New skill added, pull and update agent skills
            break;
          case "3":
              //  New outbound or inbound call that is not yet listed in the agent’s list of interactions
              console.log("BONER Inbound or outbound call detected, processing...");
              if(messageData.context.eventReason === "CREATED"){
                console.log("BONER New call event created");
                const callId = messageData.payLoad.id;
                const callDirection = messageData.payLoad.callType;
                const campId = messageData.payLoad.campaignId;
                const callerAni = messageData.payLoad.ani;
                console.log("BONER Call ID is: ", callId);
                console.log("BONER Call Direction is: ", callDirection);
                setCurrentCall(true);
                setCurrentCallDetails({call_id:callId, campaign_id:campId, caller_ani:callerAni});
                /// You are going to have to build in some sort of check to see if the agent is currently on a call or not to handle refreshes/script disappearances, as well as create context that both loadscript and load contact can use. Once both have returned true a useEffect can flip a value that displays the script and contact record.

                /*if(userStatus === "0"){
                  ///EVENTUALLY YOU ARE GOING TO HAVE TO CALL ANOTHER FUNCTION THAT PINGS THE DB FOR CONTACT INFO/CREATES CONTACT/SCRIPT SETUP ETC, THEN ANSWERS
                  //answerCall(callId);
                 
                }else{
                  console.log("BONER Agent Not Ready")
                  console.log(userStatus)
                }*/
              }
            break;
          case "30":
                //  Same shit as ID 3 just because
            break;
          case "5":
                //  Returned when a call is ended and a disposition is selected
            break;
          case "31":
              //  Same as 5
            break;
          case "52":
              //  Call quality is changed because of network issues
            break;
          case "4":
            // Call state was updated, or the call ended. During a call, this event may be returned for other reasons, such as RECORDING_ALLOWED or ON_HOLD, which may affect the details of the call information
              break;
          case "32":
                // New interaction that can be triggered by many actions, such as when the client sets call disposition (/agents/{agentId}/interactions/calls/{callId}/dispose), ends a conference,\ or transfers a voicemail message. The possible reason is CREATED. 
                console.log("BONER Disposition detected: ", messageData)
                if(messageData?.payLoad.dispositionId){
                  setCurrentCall(false);
                  setCurrentCallDetails(null);
                  setCurrentCallDetails({
                    id:null,
                    firstname: null,
                    lastname: null,
                    ani: null,
                    anis:[],
                    email: null,
                    emails: [],
                    emailhistory: [],
                    medenrollments: [],
                    providers: [],
                    pharmacies: [],
                    prescriptions: [],
                    smshistory: [],
                    vbcenrollments: [],
                    rawcallhistory: [],
                    filteredcallhistory: [],
                    interestedproducts: [],        
                    consent: false,
                    consenttoken:null,
                    lis: null,
                    medicaid: null,
                    csnp: null,
                    dsnp: null,
                    enrollmenthistory: [],
                    mbid: null,
                    dob: null,
                    street: null,
                    unit: null,
                    city: null,
                    state: null,
                    zip: null,
                    addresslat: null,
                    addresslong: null
                })
                }
                break;
          case "17":
            //User’s login state was updated. This event describes the progress of the user who is logging in again and for whom a new session is created
              break;
          case "65":
                //Call attempt failed.
              break;
          case "40":
                //The agent has not answered a call transferred by another agent.
              break;
          case "12":
                //Agent presence information was updated
                console.log("BONER: Presence State Updated", messageData)
                if(messageData?.payLoad?.currentState?.notReadyReasonCodeId && messageData?.payLoad?.pendingState === null){
                  const isSelectable = domainNotReadyCodes?.find(obj => obj.id === messageData.payLoad.currentState.notReadyReasonCodeId);
                  console.log("BONER IS SELECTABLE ", isSelectable)
                      if (isSelectable && isSelectable.selectable) {
                          setUserStatus(messageData.payLoad.currentState.notReadyReasonCodeId);
                      } else {
                          handleReadyStateChange({target:{value:"-1"}})
                      }
                }else{
                  console.log("BONER Something is missing: ", messageData?.payLoad)
                }
              break;
          case "42":
                //Station must be restarted
              break;
          case "18":
                //Station status was updated (like from connected to disconnected)
              break;
          case "58":
                //Call Transfer Failed
              break;
          case "1202":
                //Pong to the ping, some sort of interval here to see if the ping is ponging?
              break;
        }
    };

    // Event listener for WebSocket close
    ws.onclose = () => {
      console.log('BONER WebSocket disconnected');
      setSocketStatus(false);
      clearInterval(heartBeat);
    };
};

/*const ConnectSIPClient = () => {
  console.log("BONER ATTEMPING SIP")
  let webRTCHeartbeat;
    const configuration = {
      uri: 'sip:5374373@atlwebrtc001.five9.com', // Assuming 5374373 is your username
      displayName: 'Your Display Name',
      userAgentString: 'Five9 WebRTCv1 SIP.js 0.11.6',
      authorizationUser: '5374373',
      password: 'Pw7exK8ih%EXDvty0',
      supportedOptions: ['path', 'gruu', 'outbound'],
      transportOptions: {
        wsServers: ['wss://atlwebrtc001.five9.com:443'], // Websocket server
      },
      sessionDescriptionHandlerFactoryOptions: {
        constraints: {
          audio: true,
          video: false, // Assuming you're not using video
        },
        rtcOfferConstraints: {
          offerToReceiveAudio: true,
          offerToReceiveVideo: false, // Assuming you're not using video
        },
      },
    };

    const userAgent = new UserAgent(configuration);

    // Register event listener for registration state changes
    userAgent.stateChange.addListener((newState) => {
      switch (newState) {
        case 'Registered':
          console.log('Registered successfully');
          break;
        case 'Unregistered':
          console.log('Unregistered');
          break;
        case 'RegistrationFailed':
          console.error('Registration failed');
          break;
        default:
          break;
      }
    });

    // Register event listener for WebSocket connected event
    userAgent.transport.onConnect = () => {
      console.log('WebSocket connected. Sending heartbeat...');
      webRTCHeartbeat = setInterval(()=>{userAgent.transport.send('ping');},15000);
      // Here you can implement the logic to send a heartbeat message
      // For example, you can create a SIP message and send it using userAgent.message()
    };

    

    userAgent.transport.onDisconnect = () => {
      console.log('WebSocket disconnected. Clearing heartbeat and attempting to reconnect...');
      clearInterval(webRTCHeartbeat);
      // Here you can implement the logic to send a heartbeat message
      // For example, you can create a SIP message and send it using userAgent.message()
    };


    // Start the user agent
    userAgent.start();

};*/

function onInvite(invitation) {
  console.log("Invited", invitation);
}


const ConnectSIPClient = () => {
  const transportOptions = {
    server: 'wss://atlwebrtc001.five9.com:443',
  };

  const uri = UserAgent.makeURI("sip:5374373@atlwebrtc001.five9.com:443");

  const userAgentOptions = {
    authorizationPassword: 'JEVGo99m',
    authorizationUsername: '5374373',
    userAgentString: 'Five9 WebRTCv1 SIP.js 0.11.6',
    transportOptions,
    delegate: {
      onInvite
    },
    uri
  };
  const instanceId = uuidv4();

  const registererOptions = {
    extraContactHeaderParams: [
      'reg-id=1',
      `+sip.instance="<urn:uuid:${instanceId}>"`
    ],
  };
  
  const userAgent = new UserAgent(userAgentOptions);
  const registerer = new Registerer(userAgent, registererOptions);

  userAgent.start().then(() => {
    registerer.register();
  });

}


    ////YOU NEED TO BUILD IN A CALL TO PULL CURRENT LOGIN CREDENTIALS FROM THE DB

    const integrateFive9 = async () => {
      const data =  {"passwordCredentials":{"username":"Tap Policy Insurance Services","password":"Pw7exK8ih%EXDvty0"}, "policy":"ForceIn"}
      const URL = 'https://app.five9.com/appsvcs/rs/svc/auth/login';
      try {
        const response = await fetch(URL, {
          method: "POST",
          headers: {
              "Content-Type": "application/json"
          },
          credentials: 'include',
          body: JSON.stringify(data),
          });
    
          const responseData = await response.json();
          if (response.status === 200) {
            setFive9WebSocketData(responseData);
            requestAudioPermission();
                return true
          } else if(response.status === 435) {
                      switch(responseData?.five9ExceptionDetail?.errorCode){
                        case 8:
                          console.log("BONER ERROR CODE 8");
                          await pullExistingMetadata();
                          await fetchAdditonalDetails()
                        break;
                      }
                      console.log("BONER Else not found in JSON thing")
                    
          }
      } catch (error) {
            return {status:500, body: 'Server Error, Please Try Again'}
      }
    }


    const pullExistingMetadata = async () => {
      const URL = 'https://app.five9.com/appsvcs/rs/svc/auth/metadata';
      try {
        const response = await fetch(URL, {
          method: "GET",
          headers: {
              "Content-Type": "application/json"
          },
          credentials: 'include'
          });
    
          const responseData = await response.json();
          if (response.status === 200) {
            setFive9WebSocketData(responseData);
                return true
          }
      } catch (error) {
            return {status:500, body: 'Server Error, Please Try Again'}
      }
    }

   useEffect(()=>{
      if(five9WebSocketData){
        const updateAgentStatus = async () =>{
          return new Promise(async (resolve,reject)=>{
            const checkCurrentStatus = await checkAgentLoginStatus();
            console.log("BONER ", checkCurrentStatus)
            if(checkCurrentStatus === 'SELECT_STATION'){
              try {
                console.log(five9WebSocketData);
                const data =  {"stationId":"5374373","stationType":"GATEWAY"}
                const startSession = await fetch(`https://app-atl.five9.com/appsvcs/rs/svc/agents/${five9WebSocketData.userId}/session_start?force=true`, {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json"
                  },
                  body: JSON.stringify(data),
                  credentials: 'include'
                });                
                if(startSession.status === 204){
                  const isSelectingSkills = await checkAgentLoginStatus();
                  if(isSelectingSkills === "SELECT_SKILLS"){
                      const skillList = await fetchAgentSkills();
                      const skillIds = skillList.map(skill => skill.id);
                      await activateAgentSkills(skillIds);
                      if(activateAgentSkills){
                        const isWorking = await checkAgentLoginStatus();
                        if(isWorking === "WORKING"){
                          await fetchAdditonalDetails()
                          ConnectSIPClient();
                        }
                      }
                  }
                  resolve(true)
                }else if(startSession.status === 435){
                    const startSessionResult = await startSession.json();
                    if(startSessionResult?.five9ExceptionDetail?.context?.contextCode){
                        switch(startSessionResult?.five9ExceptionDetail?.context?.contextCode){
                          case 'STATION_IS_IN_USE':
                            await getCurrentCalls();
                            // should we attempt to log the current user out and then immediately call login again?
                          break;
                        }
                      }
                }else{
                  resolve(false);
                }
              } catch (error) {
                reject(error)
              }
            }else if(checkCurrentStatus === 'SELECT_SKILLS'){
                const skillList = await fetchAgentSkills();
                const skillIds = skillList.map(skill => skill.id);
                await activateAgentSkills(skillIds);
                if(activateAgentSkills){
                  const isWorking = await checkAgentLoginStatus();
                        if(isWorking === "WORKING"){
                          await fetchAdditonalDetails()
                          ConnectSIPClient();
                        }
                }
            }else if(checkCurrentStatus === 'WORKING'){
              console.log("BONER ",checkCurrentStatus)
              await fetchAdditonalDetails()
              ConnectSIPClient();
            }
          })
        }
        setTimeout(()=>{
          updateAgentStatus();
        },2000)
       
      } 
    },[five9WebSocketData])

    const fetchDomainNotReadyCodes = async () =>{
      return new Promise(async (resolve,reject)=>{
        try {
          console.log("BONER grab domain codes running")
          const orgCodes = await fetch(`https://app-atl.five9.com/appsvcs/rs/svc/orgs/${five9WebSocketData.orgId}/not_ready_reason_codes`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json"
            },
            credentials: 'include'
          }); 
          const result = await orgCodes.json();
          setDomainNotReadyCodes(result)
          resolve(result)               
        } catch (error) {
         reject(error)
        }
      })
    }
    
    const fetchDomainCampaignList = async () =>{
      return new Promise(async (resolve,reject)=>{
        try {
          console.log("BONER grab domain campaigns running")
          const domainCampaigns = await fetch(`https://app-atl.five9.com/appsvcs/rs/svc/orgs/${five9WebSocketData.orgId}/campaigns`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json"
            },
            credentials: 'include'
          }); 
          const result = await domainCampaigns.json()
          console.log("domain cmapaigns: ", result)
          setDomainCampaigns(result)
          resolve(result)               
        } catch (error) {
         reject(error)
        }
      })
    }

    const fetchDomainDispositionList = async () =>{
      return new Promise(async (resolve,reject)=>{
        try {
          console.log("BONER grab domain campaigns running")
          const domainDispositions = await fetch(`https://app-atl.five9.com/appsvcs/rs/svc/orgs/${five9WebSocketData.orgId}/dispositions`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json"
            },
            credentials: 'include'
          }); 
          const result = await domainDispositions.json()
          console.log("domain dispositions: ", result)
          setDomainDispositions(result)
          resolve(result)               
        } catch (error) {
         reject(error)
        }
      })
    }
    
    const fetchAgentSkills = async () =>{
      return new Promise(async (resolve,reject)=>{
        try {
          const agentSkills = await fetch(`https://app-atl.five9.com/appsvcs/rs/svc/agents/${five9WebSocketData.userId}/skills`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json"
            },
            credentials: 'include'
          }); 
          resolve(await agentSkills.json())               
        } catch (error) {
         reject(error)
        }
      })
    }

    const fetchSoftphoneConfig = async () =>{
      return new Promise(async (resolve,reject)=>{
        try {
          const softphoneConfig = await fetch(`https://app-atl.five9.com/appsvcs/rs/svc/agents/${five9WebSocketData.userId}/softphone_config`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json"
            },
            credentials: 'include'
          }); 
          const result = await softphoneConfig.json()
          setFive9SoftphoneConfigInfo({
            proxyServers : result.sipProxyServers,
            license: result.license,
            webRTCG : result.webRTCGWAddress
          })
          resolve(result)               
        } catch (error) {
         reject(error)
        }
      })
    }

    const checkAgentLoginStatus = async () =>{
      return new Promise(async (resolve,reject)=>{
        try {
          const updateStatus = await fetch(`https://app-atl.five9.com/appsvcs/rs/svc/agents/${five9WebSocketData.userId}/login_state`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json"
            },
            credentials: 'include'
          }); 
          resolve(await updateStatus.json())               
        } catch (error) {
         reject(error)
        }
      })
    };

    const checkAgentStationStatus = async () =>{
      return new Promise(async (resolve,reject)=>{
        try {
          const stationStatus = await fetch(`https://app-atl.five9.com/appsvcs/rs/svc/agents/${five9WebSocketData.userId}/station`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json"
            },
            credentials: 'include'
          }); 
          const result = await stationStatus.json();
          console.log("BONER STATION STATUS: ", result)
          resolve(result)               
        } catch (error) {
         reject(error)
        }
      })
    };

    const checkAgentState = async () =>{
      return new Promise(async (resolve,reject)=>{
        try {
          const currentState = await fetch(`https://app-atl.five9.com/appsvcs/rs/svc/agents/${five9WebSocketData.userId}/presence`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json"
            },
            credentials: 'include'
          }); 
          const result = await currentState.json()
          setUserStatus(result.currentState.notReadyReasonCodeId)
          resolve()               
        } catch (error) {
         reject(error)
        }
      })
    }


    const activateAgentSkills = async (s) =>{
      return new Promise(async (resolve,reject)=>{
        try {     
          const activateSkills = await fetch(`https://app-atl.five9.com/appsvcs/rs/svc/agents/${five9WebSocketData.userId}/active_skills`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(s),
            credentials: 'include'
          }); 
          if(activateSkills.status === 200){
            resolve(true)   
          }else{
            resolve(false)   
          }
        } catch (error) {
         reject(error);
        }
      })
    } 

    const handleReadyStateChange = async (e) =>{
      try {
        if(e.target.value !== "0"){
          const selectedObject = domainNotReadyCodes.find(item => item.id === e.target.value);
          const data = {"readyChannels": [],"notReadyReasonCodeId": selectedObject.id};
          const updateStatus = await fetch(`https://app-atl.five9.com/appsvcs/rs/svc/agents/${five9WebSocketData.userId}/presence`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
            credentials: 'include'
          });
         if(updateStatus.status === 200){
          checkAgentState();
         }
        }else{
          const data = {"readyChannels": ["CALL","VOICE_MAIL"],"notReadyReasonCodeId": 0};
          const updateStatus = await fetch(`https://app-atl.five9.com/appsvcs/rs/svc/agents/${five9WebSocketData.userId}/presence`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
            credentials: 'include'
          });
          if(updateStatus.status === 200){
            checkAgentState();
          }
        }               
      } catch (error) {
       
      }
    } 

    const forceDispoCurrentCall = () => {
      console.log("ASS");
      const data = { "dispositionId": "300000000000001" };
      return fetch(`https://app-atl.five9.com/appsvcs/rs/svc/agents/${five9WebSocketData.userId}/interactions/calls/${currentCallDetails.call_id}/dispose`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        credentials: 'include',
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        return true;
      })
      .catch(error => {
        console.error('Error:', error);
        return false;
      });
    }


    const answerCall = async (id) => {
      const answerCallRequest = await fetch(`https://app-atl.five9.com/appsvcs/rs/svc/agents/${five9WebSocketData.userId}/interactions/calls/${id}/answer`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json"
            },
            credentials: 'include'
          });

          console.log(await answerCallRequest.json()) 
    }

    const requestAudioPermission = () => {
      navigator.mediaDevices.getUserMedia({ audio: true, video: false })
        .then(function(stream) {
          // Access granted, you can use the stream now
          console.log('BONER Access to microphone granted');
          setBrowserPermissionGranted(true);
        })
        .catch(function(err) {
          // Handle errors or denied permission
          console.error('BONER Error accessing microphone:', err);
        });
    };


    const getCurrentCalls = async () =>{
      return new Promise(async (resolve,reject)=>{
          try {
            const grabCalls = await fetch(`https://app-atl.five9.com/appsvcs/rs/svc/agents/${five9WebSocketData.userId}/interactions/calls`, { method: "GET", headers: {"Content-Type": "application/json"}, credentials: 'include'})
            console.log("BONER Active Calls",grabCalls);
            if(grabCalls.status === 200){
              const result = await grabCalls.json();
              console.log("BONER grab calls: ",result)
              if(result[0] && result[0]?.state === "WRAP_UP" || result[0]?.state === "TALKING"){
                setCurrentCall(true)
                setCurrentCallDetails({call_id:result[0].id, campaign_id:result[0].campaignId, caller_ani:result[0].ani});
              }else if(result.length === 0){
                setCurrentCall(false)
              }
              resolve();
            }
          } catch (error) {
            reject(error)
          }
      })
    }

    const fetchAdditonalDetails = async () => {
      await fetchDomainNotReadyCodes();
      await checkAgentState();
      await checkAgentStationStatus();
      await fetchSoftphoneConfig();
      await fetchDomainCampaignList();
      await fetchDomainDispositionList();
      await getCurrentCalls();
    }

      return (
        <Five9Context.Provider value={{ five9UserCredentials, setFive9UserCredentials, five9WebSocketData, setFive9WebSocketData, integrateFive9, integrationStatus, currentCall, currentCallDetails, callContactManager, setCallContactManager, domainNotReadyCodes, userStatus, handleReadyStateChange, domainCampaigns, forceDispoCurrentCall, domainDispositions }}>
             {props.children}
        </Five9Context.Provider>  
    );
      
}

export {Five9Master, Five9Context};
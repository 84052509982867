import React, { useContext, useEffect, useState, useRef } from "react";
import { Five9Context } from "./Five9Master";
import { Modal } from "react-bootstrap";
import { AccountContext } from "../Account/Account";
import { OrgContext } from "../../resources/helperdata/OrgDetails"
import { useDebounce } from "../../resources/hooks/hooks";

const SendEmail = (props) => {
    const { callContactManager, setCallContactManager } = useContext(Five9Context);
    const { userAttributes, getToken } = useContext(AccountContext);
    const { orgDetails } = useContext(OrgContext);
    const [emailObject, setEmailObject] = useState({fromname:userAttributes.name, to: callContactManager.emails[0] ? callContactManager.emails[0] : null, from: userAttributes.email, subject: null, text:null, html:null, tocontactid:callContactManager.id, fromagentid:userAttributes["custom:agentId"], orgid:userAttributes["custom:orgid"], npn:userAttributes["custom:npn"], consenttoken:callContactManager.consenttoken, type:"EMAIL"});
    const debouncedEmailObjectText = useDebounce(emailObject.text)
    const [isEmailSending, setIsEmailSending] = useState(false);
    const [emailSentSuccess, setEmailSentSuccess] = useState(null);
    const [missingErrorText, setMissingErrorText] = useState("");
    const [sendStatusErrorText, setSendStatusErrorText] = useState(null);
    const emailBodyRef = useRef();
    const handleEmailClose = () => {
        setEmailObject({fromname:userAttributes.name, to: callContactManager.emails[0] ? callContactManager.emails[0] : null, from: userAttributes.email, subject: null, text:null, html:null, tocontactid:callContactManager.id, fromagentid:userAttributes["custom:agentId"], orgid:userAttributes["custom:orgid"], npn:userAttributes["custom:npn"], consenttoken:callContactManager.consenttoken, type:"EMAIL"})
        props.setEmailValue(false); // Update parent state to hide the modal
    };

    /*
        ----- TODO
        - Email form validation check
        - Undefined placeholder handling
        - Build a reuseable toast component to avoid having to use error text all the time
    */


    const handleChange = (e) => {
        setEmailObject(prevState => ({
            ...prevState,
            [e.target.name] : e.target.value
        }))
    }

    const recordManualConsent = () =>{
        let token;
        callContactManager.ani ? token = `${callContactManager.ani}-${Date.now()}` : callContactManager.email ? token = `${callContactManager.email}-${Date.now()}` :  token = `agent-${userAttributes["custom:agentId"]}-${Date.now()}`;
        setCallContactManager(prevState => ({
            ...prevState,
            consent: true,
            consenttoken: token
        }))

        setEmailObject(prevState => ({
            ...prevState,
            consenttoken: token
        }))
    }

    useEffect(()=>{
        if(emailSentSuccess === true){
            //set timeout to close popup
            setTimeout(()=>{
                handleEmailClose();
                setIsEmailSending(false);
                setEmailSentSuccess(null);
            },3000)
        }else if (emailSentSuccess === false){
            setTimeout(()=>{
                handleEmailClose();
                setIsEmailSending(false);
                setEmailSentSuccess(null);
                setSendStatusErrorText(null)
            },3000)
        }
    },[emailSentSuccess])

    useEffect(()=>{
        console.log(emailObject)
    },[emailObject])



    const addPlaceholder = (e) =>{
        const placeholderName = e.target.getAttribute('data-name');
        let additionalDataValue;
        console.log(placeholderName);
        switch (placeholderName){
            case 'Customer_FirstName':
                additionalDataValue = callContactManager.firstname || "**Unknown**";
            break;
            case 'Customer_LastName':
                additionalDataValue = callContactManager.lastname || "**Unknown**";
            break;
            case 'Customer_Phone':
                additionalDataValue = callContactManager.ani || "**Unknown**";
            break;
            case 'Agent_FirstName':
                additionalDataValue = userAttributes.name.split(" ")[0] || "**Unknown**";
            break;
            case 'Agent_LastName':
                additionalDataValue = userAttributes.name.split(" ")[1] || "**Unknown**";
            break;
            case 'Agent_DirectLine':
                additionalDataValue = userAttributes.phone_number || "**Unknown**";
            break;
            case 'Agent_NPN':
                additionalDataValue = userAttributes.npn || "**Unknown**";
            break;
        }

        setEmailObject(prevState => ({
            ...prevState,
            text: `${prevState.text || ''}${additionalDataValue}`
        }))
    }



    const fetchToken = async () => {
        try {
            const token = await getToken();
            return token;
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    const loadTemplate = (e) =>{
        console.log("should load template ", e.target.value)
        const selectedObject = orgDetails.orgemailtemplates.filter(item => item.id === e.target.value);

        if(selectedObject){ 
            const pattern = /\*\*(.*?)\*\*/g;
            const subjectMatches = selectedObject[0].subject.match(pattern);
            const bodyMatches = selectedObject[0].text.match(pattern);

            if(subjectMatches){
                for(let _match of subjectMatches){
                    console.log(_match)
                    let _stripVariable = _match.replace("**", "").replace("**","");
                    switch (_stripVariable){
                        case 'Customer_FirstName':
                            selectedObject[0].subject =  selectedObject[0].subject.replace(_match, callContactManager.firstname || "**Unknown**")
                        break;
                        case 'Customer_LastName':
                            selectedObject[0].subject =  selectedObject[0].subject.replace(_match, callContactManager.lastname || "**Unknown**")
                        break;
                        case 'Customer_Phone':
                            selectedObject[0].subject =  selectedObject[0].subject.replace(_match, callContactManager.ani || "**Unknown**")
                        break;
                        case 'Agent_FirstName':
                            selectedObject[0].subject =  selectedObject[0].subject.replace(_match, userAttributes.name.split(" ")[0] || "**Unknown**")
                        break;
                        case 'Agent_LastName':
                            selectedObject[0].subject =  selectedObject[0].subject.replace(_match, userAttributes.name.split(" ")[1] || "**Unknown**")
                        break;
                        case 'Agent_DirectLine':
                            selectedObject[0].subject =  selectedObject[0].subject.replace(_match, userAttributes.phone_number || "**Unknown**")
                        break;
                        case 'Agent_NPN':
                            selectedObject[0].subject =  selectedObject[0].subject.replace(_match, userAttributes.npn || "**Unknown**")
                        break;
                        }
                    }
            }

            if(bodyMatches){
                for(let _match of bodyMatches){
                    console.log(_match)
                    let _stripVariable = _match.replace("**", "").replace("**","");
                    switch (_stripVariable){
                        case 'Customer_FirstName':
                            selectedObject[0].text =  selectedObject[0].text.replace(_match, callContactManager.firstname || "**Unknown**")
                        break;
                        case 'Customer_LastName':
                            selectedObject[0].text =  selectedObject[0].text.replace(_match, callContactManager.lastname || "**Unknown**")
                        break;
                        case 'Customer_Phone':
                            selectedObject[0].text =  selectedObject[0].text.replace(_match, callContactManager.ani || "**Unknown**")
                        break;
                        case 'Agent_FirstName':
                            selectedObject[0].text =  selectedObject[0].text.replace(_match, userAttributes.name.split(" ")[0] || "**Unknown**")
                        break;
                        case 'Agent_LastName':
                            selectedObject[0].text =  selectedObject[0].text.replace(_match, userAttributes.name.split(" ")[1] || "**Unknown**")
                        break;
                        case 'Agent_DirectLine':
                            selectedObject[0].text =  selectedObject[0].text.replace(_match, userAttributes.phone_number || "**Unknown**")
                        break;
                        case 'Agent_NPN':
                            selectedObject[0].text =  selectedObject[0].text.replace(_match, userAttributes.npn || "**Unknown**")
                        break;
                        }
                    }
            }
                
            setEmailObject((prevState)=> ({
                ...prevState,
                subject: selectedObject[0].subject,
                text: selectedObject[0].text
            }))
        }
    }

    useEffect(() => {
        if(debouncedEmailObjectText){
            let htmlContent = `<div>${emailObject.text?.replace(/\n/g, '<br>')}</div>`;
             setEmailObject(prevState => ({
                ...prevState,
                html: htmlContent
                }));
        }
      }, [debouncedEmailObjectText]);

    const validateAndSendEmail = async () => {
        const isMissing = Object.values(emailObject).some(value => value === null || value === "");
        if(!isMissing){
            setMissingErrorText("");
            setIsEmailSending(true);
            const emailToken = await fetchToken();
            console.log(emailObject)
            try {
                const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/sendemail`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "auth-key": emailToken
                    },
                    body: JSON.stringify(emailObject),
                    });
                    const responseData = await response.json();
                    if (responseData.statusCode === 200) {
                        setEmailSentSuccess(true);
                        setSendStatusErrorText(null)
                    } else {
                        setEmailSentSuccess(false);
                        console.log(response);
                        setSendStatusErrorText(responseData.data)
                    }

            } catch (error) {
                console.log(error)
                setEmailSentSuccess(false);
            }

        }else{
            //toast or error that there is something missing
            setMissingErrorText("Please complete all required fields before sending an email.")
            console.log(emailObject)
        }
    } 

    return (
      <Modal show={props.showEmailModal} onHide={handleEmailClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Send An Email</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            {callContactManager.consent ? (
                <>
                {!isEmailSending ? (
                    <form>
                    <div className="row mb-3">
                        <div className="col col-12">
                            <select className="form-select" name="to" onChange={(e)=>{handleChange(e)}}>
                                {callContactManager.emails.map((email, index) => (   
                                    <option key={index} value={email}>To: {email}</option>
                                    ))}
                               
                            </select></div>
                    </div>
                    <div className="row mb-3">
                        <div className="col col-12"><select className="form-select" onChange={(e)=>loadTemplate(e)}>
                                <option value="loadtemplate" selected>Load Template</option>
                                {orgDetails.orgemailtemplates?.length > 0 && (
                                    orgDetails.orgemailtemplates.map((template) =>{
                                        return <option value={template.id}>{template.name}</option>
                                    })
                                )}
                            </select></div>
                    </div>
                    <div className="row mb-3">
                        <div className="col col-12"><input className="form-control" type="text" placeholder="Subject" name="subject" value={emailObject.subject} onChange={(e)=>handleChange(e)}/></div>
                    </div>
                    <div className="row mb-1">
                        <div className="col col-12"><textarea className="form-control mb-2" placeholder="Email body" rows="10" name="text" ref={emailBodyRef} value={emailObject.text} onChange={(e)=>handleChange(e)}></textarea>
                        <div><button class="placeholder-button" data-name="Customer_FirstName"  type="button" onClick={(e)=>addPlaceholder(e)}>Customer First Name</button><button class="placeholder-button" data-name="Customer_LastName" type="button" onClick={(e)=>addPlaceholder(e)}>Customer Last Name</button><button class="placeholder-button" data-name="Customer_Phone" type="button" onClick={(e)=>addPlaceholder(e)}>Customer Phone</button><button class="placeholder-button" data-name="Agent_FirstName" type="button" onClick={(e)=>addPlaceholder(e)}>Agent First Name</button><button class="placeholder-button" data-name="Agent_LastName" type="button" onClick={(e)=>addPlaceholder(e)}>Agent Last Name</button><button class="placeholder-button" data-name="Agent_DirectLine" type="button" onClick={(e)=>addPlaceholder(e)}>Agent Direct Line</button><button class="placeholder-button" data-name="Agent_NPN" type="button" onClick={(e)=>addPlaceholder(e)}>Agent NPN</button></div>
                        </div>
                    </div>
                    {missingErrorText != "" && (
                    <div className="errorBlock">
                        <span>{missingErrorText}</span>
                    </div>
                    )}
                </form>
                 ):(
                    <div className="p-4 text-center">
                        { emailSentSuccess === null && (
                            <>
                                <div className="w-100 p-0 mb-3">
                                    <img src="/img/spinner.gif" className="loadingIcon"></img>
                                </div>
                                <div className="w-100 p-0">
                                    <h5>Sending Email...</h5>
                                </div>
                            </>
                        )}
                          { emailSentSuccess && (
                            <>
                                <div className="w-100 p-0 mb-3">
                                    <img src="/img/success.png" className="resultIcon"  playsInline loop={false}></img>
                                </div>
                                <div className="w-100 p-0">
                                    <h5>Email Sent Successfully!</h5>
                                </div>
                            </>
                        )}
                        {emailSentSuccess === false &&(
                            <>
                                <div className="w-100 p-0 mb-3">
                                    <img src="/img/error.png" className="resultIcon"></img>
                                </div>
                                <div className="w-100 p-0">
                                    <h5>Failed To Send Email</h5>
                                </div>
                                <div className="w-100 pt-3 pb-0">
                                    <h6>{sendStatusErrorText}</h6>
                                </div>
                            </>
                    )}
                   </div>
                 )}
                 </>
            ):(
                <>
                <div className="row w-100 m-auto p-3 text-center">
                    <div className="col-12">
                    <img src="/img/error.png" className="resultIcon mb-5"></img>
                    </div>
                    <div className="col-12 mb-4">
                        <h5>Warning: We have no record of consent for this contact. Would you like to record consent now?</h5>
                    </div> 
                    <div className="col-6">
                            <button className="btn main-button ms-4" type="submit" onClick={handleEmailClose}>Cancel Email</button>
                    </div>
                    <div className="col-6">
                            <button className="btn main-button ms-4" type="submit" onClick={recordManualConsent}>Yes, I Have Consent</button>
                    </div>
                </div>
                </>
            )}


            </Modal.Body>
            {callContactManager.consent && (
                <>
            { !isEmailSending && (
                            <Modal.Footer>
                            <div className="w-100 text-end">
                            <button className="btn btn-light" type="button" onClick={handleEmailClose}>Cancel</button>
                            <button className="btn main-button ms-4" type="submit" onClick={validateAndSendEmail}>Send Email</button>
                            </div>
                        </Modal.Footer>
                )}
            </>
            )}
          </Modal>
    );
}

export default SendEmail;

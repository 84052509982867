import React from 'react';
import { useNavigate } from 'react-router-dom';

const ErrorPage = () =>{
    const navigate = useNavigate();
    return(
        <div className="container p-5 text-center">
                <div className="row">
                    <div className="col"><img className="mb-3" src="/img/tappolicyheadergraphic-cropped.png"></img>
                        <h1 className="mb-3"><strong>404 - Page Not Found!</strong></h1><button className="btn btn-primary" type="button" onClick={(e)=>{navigate("/auth");}}>Back To Safety</button>
                    </div>
                </div>
            </div>
    )
};

export default ErrorPage
import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "../Account/Account";
import { Five9Context } from "./Five9Master";
import { OrgContext } from "../../resources/helperdata/OrgDetails";
import { useSearchParams } from 'react-router-dom';
import { Dropdown, DropdownButton, Accordion, AccordionBody, AccordionHeader, Modal } from "react-bootstrap";
import { useDebounce } from "../../resources/hooks/hooks";

const LoadScript = () => {
    const { userAttributes } = useContext(AccountContext);
    const { currentCallDetails, callContactManager, domainCampaigns } = useContext(Five9Context);
    const { orgDetails } = useContext(OrgContext);
    const [scriptContent, setScriptContent] = useState(null);
    const [currSlideContent, setCurrSlideContent] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams({ c: "" });
    const [slideNumber, setSlideNumber] = useState(0);
    const noScriptMatched = 
      {
        "name": "No Script Found",
        "slides": [
          {
            "title": "Introduction",
            "content": "<div id='slide-1' class='script-slide'><h3 className='w-100 m-auto text-centrer'>No matching script found.</h3></div>"
          }
        ]
      }
    
    const [showObjectionModal, setShowObjectionModal] = useState(false);
    const handleObjectionModalClose = () => {setShowObjectionModal(false)}
    const handleObjectionModalShow = () => setShowObjectionModal(true);

    useEffect(() => {
      if(currentCallDetails && currentCallDetails.call_id && currentCallDetails.campaign_id && currentCallDetails.caller_ani && orgDetails){
        processScript(currentCallDetails.campaign_id);
      }
    }, [currentCallDetails]);

    useEffect(() => {
      if (scriptContent) {
        handlePlaceholders(scriptContent.slides[slideNumber].content)
          .then(result => {
            setCurrSlideContent(result);
          })
          .catch(error => {
            console.error("Error handling placeholders:", error);
          });
      }
    }, [slideNumber, callContactManager]);

    const processScript = async (n) => {
        const matchedScript = orgDetails.scriptdetails.find(obj => obj.id === n);
        if (matchedScript) {
            const updatedContent = await handlePlaceholders(matchedScript.slides[slideNumber].content);
            setScriptContent(matchedScript);
            setCurrSlideContent(updatedContent);
        }else{
          console.log("BONER No script found for this campaign, loading default.");
          setScriptContent(noScriptMatched);
          setCurrSlideContent(noScriptMatched.slides[slideNumber].content);
        }
    }
    
    const handlePrevSlide = () => {
      let prevSlide = slideNumber-1;
      if(scriptContent.slides[prevSlide]?.content){
        setSlideNumber(prevSlideNumber => prevSlideNumber - 1);
      }
    }

    const handleNextSlide = () => {
      let nextSlide = slideNumber+1
      if(scriptContent.slides[nextSlide]?.content){
        setSlideNumber(prevSlideNumber => prevSlideNumber + 1);
      }
    }

    const scriptJump = (n) => {
      setSlideNumber(n)
      console.log(n);
    }

    const handlePlaceholders = async (content) => {
      return new Promise((resolve, reject) => {
        const pattern = /\*\*(.*?)\*\*/g;
        const slideMatches = content.match(pattern);
        if (slideMatches) {
          for (let _match of slideMatches) {
            const route = _match.replaceAll("**", "").split(".")[0];
            const value = _match.replaceAll("**", "").split(".")[1];
            if (route && route === 'contact') {
              content = content.replace(_match, callContactManager[value] || "")
            } else if (route && route === 'agent') {
              //content =  content.replace(_match, userAttributes[value] || "")
            } else {
              // Do nothing
            }
          }
        }
        // Resolve the promise with the updated content
        resolve(content);
      });
    }
    

    return (
        <>
         {scriptContent && (
          <>
            <div className="scriptHolder">
              <div className="row w-100">
                          <div className="col-3 text-center">
                          <DropdownButton id="dropdown-basic-button" title="Script Jump">
                          {scriptContent && scriptContent.slides && (
                            Object.entries(scriptContent.slides).map(([index, slide]) => (
                              <Dropdown.Item key={index} onClick={() => scriptJump(index)}>
                                {slide.title}
                              </Dropdown.Item>
                            ))
                          )}
                          </DropdownButton>        
                          </div>
                          <div className="col-6 text-center">
                            <h4>{scriptContent.name}</h4>
                          </div>
                          <div className="col-3 text-center">
                            <button className="btn btn-primary" onClick={handleObjectionModalShow}>Objection</button>
                          </div>
                      </div>
                      <div className="row">
                          <div className="col-12 slide-content">
                            { 
                              scriptContent && (
                                <div dangerouslySetInnerHTML={{ __html:  currSlideContent}} />
                              )
                            }
                        </div>
                        </div>
                        <div className="row">
                              <div className="col-6 text-center">
                        <button className="btn btn-primary" onClick={handlePrevSlide}>Back</button>
                      </div>
                      <div className="col-6 text-center">
                        <button className="btn btn-primary" onClick={handleNextSlide}>Next</button>
                      </div>
                          </div>
                </div>
               </>
            )}
            <Modal show={showObjectionModal} onHide={handleObjectionModalClose} size="md">
  <Modal.Body>
    {scriptContent && scriptContent.objections ? (
      <Accordion>
        {Object.entries(scriptContent.objections).map(([objectionKey, objectionValue]) => (
          <Accordion.Item key={objectionKey} eventKey={objectionKey}>
            <AccordionHeader>{objectionKey}</AccordionHeader>
            <AccordionBody>{objectionValue}</AccordionBody>
          </Accordion.Item>
        ))}
      </Accordion>
    ) : (
      <div className="w-100 m-auto text-center p-5">
        <h6>No objections have been set.</h6>
      </div>
    )}
  </Modal.Body>
</Modal>

        </>
    );
}

export default LoadScript;

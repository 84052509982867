import React, { useContext } from "react";
import { Five9Context } from "./Five9Master";
import { Accordion } from "react-bootstrap";

const EnrollmentHistory = () => {
    const { callContactManager, setCallContactManager } = useContext(Five9Context);
    return (
        <Accordion>
            <Accordion.Item eventKey="0">
            <Accordion.Header>Enrollment History <span>({callContactManager.medenrollments.length})</span></Accordion.Header>
            <Accordion.Body>
            <div className="accordion-body">
                                                <div><ul className="timeline">
                                                    {
                                                        callContactManager.enrollmenthistory.map((item, index) => (
                                                            <li className="timeline-item mb-5">
                                                            <h6>{item.category.toUpperCase()} Enrollment</h6>
                                                            <p className="text-muted mb-2 fw-bold">{new Date(item.time).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric'})} - {item.agentid}</p>
                                                            </li>
                                                        ))
                                                    }
                                                </ul></div>
                                            </div>
            </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

export default EnrollmentHistory;
import React, {useContext, useEffect, useState, useRef} from "react";
import { Five9Context } from "./Five9Master";
import { useDebounce, findLatLongByFirstThree } from "../../resources/hooks/hooks";
import { AccountContext } from "../Account/Account";
import { Modal } from "react-bootstrap";


const AddressInfo = () => {
const { callContactManager, setCallContactManager } = useContext(Five9Context);
const { agentData } = useContext(AccountContext);
const [placeDetails, setPlaceDetails] = useState([]);
const [selected, setSelected] = useState(false);
const [addressSearch, setAddressSearch] = useState("");
const debouncedAddressSearch = useDebounce(addressSearch);
const [agentLicenseWarning, setAgentLicenseWarning] = useState(false);
const handleClose = () => setAgentLicenseWarning(false);
const searchResultDropdown = useRef();


    useEffect(() => {
          const shouldFetch = async () => {
              if(debouncedAddressSearch !== "" && debouncedAddressSearch.length >= 5){
                  fetchAddresses()
              }else if(debouncedAddressSearch.length < 5){
                 setPlaceDetails([]);
              }
          }
          shouldFetch();
      }, [debouncedAddressSearch])

      
      useEffect(() => {
        const clickOutsideAddressDropdown = (e) => {
          if (!searchResultDropdown.current.contains(e.target)) {
            if (placeDetails.length > 0) {
              setPlaceDetails([]);
            }
          }
        };
        document.addEventListener("mousedown", clickOutsideAddressDropdown);
        return () => {
          document.removeEventListener("mousedown", clickOutsideAddressDropdown);
        };
      }, [placeDetails, searchResultDropdown]);
      



const handleChange = (e) => {
  setCallContactManager(prevState => ({
        ...prevState,
        [e.target.name] : e.target.value
    }));
      if(e.target.name === "street"){
        setAddressSearch(e.target.value)
      }
      if(e.target.name === "state" && agentData){
        if(!agentData.agent_data.data[0]["licensedstates"][e.target.value]){
          setAgentLicenseWarning(true)
        }
    }
}

const handleSelection = async (place) => {
  console.log(place);

  const apiKey = 'AIzaSyDLahf2zhKgErUyr_j4w1M0h_3OHd3u454';
  const apiUrl = `https://places.googleapis.com/v1/places/${place.placePrediction.placeId}?fields=addressComponents,location`;



  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Goog-Api-Key': apiKey
    }
  };


  try {
    const response = await fetch(apiUrl, requestOptions);
    const data = await response.json();
    console.log(data)
    let streetAddress, city, state, zip, unit, lat, long;
    data.addressComponents.forEach((component) => {
      if (component.types.includes('street_number')) {
        streetAddress = component.longText;
      } else if (component.types.includes('route')) {
        streetAddress = streetAddress ? `${streetAddress} ${component.longText}` : component.longText;
      } else if (component.types.includes('locality')) {
        city = component.longText;
      } else if (component.types.includes('administrative_area_level_1')) {
        state = component.shortText;
      } else if (component.types.includes('postal_code')) {
        zip = component.longText;
      }
    });

    lat = data.location?.latitude;
    long = data.location?.longitude;

    setCallContactManager((prevState) => ({
      ...prevState,
      street: streetAddress || '',
      unit: unit || '',
      zip: zip || '',
      city: city || '',
      state: state || '',
      addresslat: lat,
      addresslong: long
    }));

    if(!agentData.agent_data.data[0]["licensedstates"][state]){
      setAgentLicenseWarning(true)
    }

  } catch (error) {
    console.error('Error fetching data:', error);
  }


};

const handleResultsHide = (e) => {
  if(e.target.value.length === 0){
    setPlaceDetails([])
  }
};


const fetchAddresses = async (token) => {
  const apiKey = 'AIzaSyDLahf2zhKgErUyr_j4w1M0h_3OHd3u454';
  const apiUrl = `https://places.googleapis.com/v1/places:autocomplete?fields=*`;

  if(callContactManager.addresslat && callContactManager.addresslong){

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Goog-Api-Key': apiKey
      },
      body: JSON.stringify({
        input: debouncedAddressSearch,
        regionCode: "US",
        locationBias: {
          circle: {
            center: {
              latitude: callContactManager.addresslat,
              longitude: callContactManager.addresslong,
            },
            radius: 50000.0
          },
        }
      })
    };


    try {
      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();
      if(data.suggestions?.length > 0){
        setPlaceDetails(data.suggestions);
      }else{
        setPlaceDetails([]);
      }
      console.log(data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  }else{
    const firstThree = parseInt(callContactManager.ani?.toString().substring(0,3));
    const areaCodeMatch = await findLatLongByFirstThree(firstThree);
        const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-Goog-Api-Key': apiKey
            },
            body: JSON.stringify({
              input: debouncedAddressSearch,
              regionCode: "US",
                  locationBias: {
                      circle: {
                        center: {
                          latitude: areaCodeMatch.latitude,
                          longitude: areaCodeMatch.longitude,
                        },
                        radius: 50000.0
                      },
                    }
            }),
          };

          try {
            const response = await fetch(apiUrl, requestOptions);
            const data = await response.json();
            if(data.suggestions?.length > 0){
              setPlaceDetails(data.suggestions);
            }else{
              setPlaceDetails([]);
            }
            console.log(data)
          } catch (error) {
            console.error('Error fetching data:', error);
          }
      }

      }




    return (
        <>
                    <div className="col mb-5"><label className="form-label mb-1">Street Address</label>
        
                    <input className="form-control mb-3" type="text" name="street" value={callContactManager.street} onChange={(e) => {setSelected(false); handleChange(e); handleResultsHide(e);}} />
                    <div className="addressSelectOverlay" ref={searchResultDropdown} style={{ display: placeDetails.length > 0 && !selected ? 'block' : 'none' }}>
                    <ul className="addressList">
                                    {placeDetails.map((place, index) => (   
                                        <li key={place.placePrediction.placeId} onClick={(e)=>{setSelected(true); handleSelection(place)}}>{place.placePrediction.text.text}</li>
                                    ))}
                                  </ul>
                    </div>
                    
                    <label className="form-label mb-1">City</label><input className="form-control mb-3" type="text" name="city" value={callContactManager.city} onChange={(e) => handleChange(e)}/></div>
                    <div className="col mb-5">
                    <div>
                        <div className="row">
                            <div className="col"><label className="form-label mb-1">Apt/Unit Number</label><input className="form-control mb-3" type="text" name="unit" value={callContactManager.unit} onChange={(e) => handleChange(e)}/></div>
                            <div className="col"><label className="form-label mb-1">Zip Code</label><input className="form-control mb-3" type="text" name="zip" value={callContactManager.zip} onChange={(e) => handleChange(e)}/></div>
                        </div>
                    </div><label className="form-label mb-1">State</label>
                    <select className="form-select mb-3" value={callContactManager.state} name="state" onChange={(e) => {handleChange(e)}} >
                              <option value="0"></option>
                              <option value="AL">Alabama</option>
                              <option value="AK">Alaska</option>
                              <option value="AZ">Arizona</option>
                              <option value="AR">Arkansas</option>
                              <option value="CA">California</option>
                              <option value="CO">Colorado</option>
                              <option value="CT">Connecticut</option>
                              <option value="DE">Delaware</option>
                              <option value="FL">Florida</option>
                              <option value="GA">Georgia</option>
                              <option value="HI">Hawaii</option>
                              <option value="ID">Idaho</option>
                              <option value="IL">Illinois</option>
                              <option value="IN">Indiana</option>
                              <option value="IA">Iowa</option>
                              <option value="KS">Kansas</option>
                              <option value="KY">Kentucky</option>
                              <option value="LA">Louisiana</option>
                              <option value="ME">Maine</option>
                              <option value="MD">Maryland</option>
                              <option value="MA">Massachusetts</option>
                              <option value="MI">Michigan</option>
                              <option value="MN">Minnesota</option>
                              <option value="MS">Mississippi</option>
                              <option value="MO">Missouri</option>
                              <option value="MT">Montana</option>
                              <option value="NE">Nebraska</option>
                              <option value="NV">Nevada</option>
                              <option value="NH">New Hampshire</option>
                              <option value="NJ">New Jersey</option>
                              <option value="NM">New Mexico</option>
                              <option value="NY">New York</option>
                              <option value="NC">North Carolina</option>
                              <option value="ND">North Dakota</option>
                              <option value="OH">Ohio</option>
                              <option value="OK">Oklahoma</option>
                              <option value="OR">Oregon</option>
                              <option value="PA">Pennsylvania</option>
                              <option value="RI">Rhode Island</option>
                              <option value="SC">South Carolina</option>
                              <option value="SD">South Dakota</option>
                              <option value="TN">Tennessee</option>
                              <option value="TX">Texas</option>
                              <option value="UT">Utah</option>
                              <option value="VT">Vermont</option>
                              <option value="VA">Virginia</option>
                              <option value="WA">Washington</option>
                              <option value="WV">West Virginia</option>
                              <option value="WI">Wisconsin</option>
                              <option value="WY">Wyoming</option>
                        </select>
                </div>
                <Modal show={agentLicenseWarning} onHide={handleClose} size="lg">
            <Modal.Body>
                <div className="row w-100 m-auto p-3 text-center">
                    <div className="col-12">
                    <img src="/img/error.png" className="resultIcon mb-5"></img>
                    </div>
                    <div className="col-12 mb-4">
                        <h5>Warning: Our records do not indicate you are licensed in {callContactManager.state}. Please double check before you proceed.</h5>
                    </div> 
                    <div className="col-12">
                            <button className="btn main-button ms-4" type="submit" onClick={handleClose}>I understand</button>
                    </div>
                </div>
            </Modal.Body>
          </Modal>
        </>
    )
}
export default AddressInfo;
import React, { useState, useMemo, useEffect } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from "react-chartjs-2";


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

const PerformanceChart = (props) => {
    const [labels, setLabels] = useState([]);
    const [data, setData] = useState([]);

    

    useEffect(()=>{
        for (const entry of props.data) {
            setLabels((prevLabels) => [...prevLabels, entry.month_name]);
            setData((prevData) => [...prevData, entry.submission_count]);
        }
    }, [props.data])


    const chartData = {
        labels: labels,
        datasets: [
            {
                label: "Sales",
                backgroundColor: "#0069b4b3",
                borderColor: "#0069b4",
                borderWidth: 1,
                hoverBackgroundColor: "#0069b447",
                hoverBorderColor: "#0069b4",
                data: data
            }
        ]
    };


  return (
    <>

            {data.length > 0 && labels.length > 0 ? (<div className="col col-12 p-3">
            <Bar
                data={chartData}
                style={{ width: "100%", maxHeight: "150px" }}
                options={{
                responsive: true,
                  maintainAspectRatio: true
                }}
              />
                           </div>):(<h3>Generating...</h3>)}
     </>
  );
};

export default PerformanceChart;

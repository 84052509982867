import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "./Account";
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [code, setCode] = useState("");
  const [loginerror, setLoginError] = useState("");
  const [formType, setFormType] = useState("login");
  const navigate = useNavigate();
  const { authenticate, getSession, initiateResetPassword, confirmResetPassword } = useContext(AccountContext);


  useEffect(() => {
    getSession().then((session) => {
      navigate('/dashboard');
    }).catch((err) =>{
      navigate('/auth');
    })
    ;
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    setLoginError("");
    authenticate(email, password)
      .then((data) => {
        if(data.idToken.jwtToken){
          navigate('/dashboard');
        }
      })
      .catch((err) => {
        setLoginError(err.message);
      });
  };

  const initiateReset = (event) => {
    event.preventDefault();
    initiateResetPassword(email)
      .then((data) => {
        console.log(data);
        setLoginError("");
        setFormType("updatePassword");
      })
      .catch((err) => {
        setLoginError(err.message);
      });
  }

  const updatePassword = (event) => {
    event.preventDefault();
    confirmResetPassword(email, code, newPassword)
      .then((data) => {
        setLoginError("");
        setFormType("login");
      })
      .catch((err) => {
        setLoginError(err.message);
      });
  }

  const renderForm = () => {
    switch (formType) {
      case "login":
        return (
          <form className="text-center" onSubmit={onSubmit}>
            {<><div className="mb-3"><input className="form-control login-input" type="email" name="email" placeholder="Email"  value={email} onChange={(event) => setEmail(event.target.value)} required></input></div>
                                <div className="mb-3"><input className="form-control" type="password" name="password" placeholder="Password"  value={password} onChange={(event) => setPassword(event.target.value)} required minLength={8}></input></div>
                                <div className="mb-3"><button className="btn btn-primary d-block w-100" type="submit">Login</button></div>
                                <span className="mb-3" onClick={()=> setFormType("initiateReset")}>Forgot Password</span>
                                </>}
          </form>
        );
      case "initiateReset":
        return (
          <form className="text-center" onSubmit={initiateReset}>
            {<>  <div className="mb-3"><input className="form-control login-input" type="email" name="email" placeholder="Email"  value={email} onChange={(event) => setEmail(event.target.value)} required></input></div>
                                <div className="mb-3"><button className="btn btn-primary d-block w-100" type="submit">Send Reset Code</button></div>
                              </>}
          </form>
        );
      case "updatePassword":
        return (
          <form className="text-center" onSubmit={updatePassword}>
            {<> <div className="mb-3"><input className="form-control login-input" type="text" name="code" placeholder="Enter Verification Code"  value={code} onChange={(event) => setCode(event.target.value)} required></input></div>
                            <div className="mb-3"><input className="form-control" type="password" name="password" placeholder="New Password"  value={newPassword} onChange={(event) => setNewPassword(event.target.value)} required minLength={8}></input></div>
                            <div className="mb-3"><button className="btn btn-primary d-block w-100" type="submit">Reset Password</button></div></>}
          </form>
        );
      default:
        return null;
    }
  };

  return (
    <section className="position-relative py-4 py-xl-5">
        <div className="container">
            <div className="row mb-5">
                <div className="col-md-8 col-xl-6 text-center mx-auto">
                    <h2>Welcome Back! Please Log In.</h2>
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col col-12 col-md-6">
                    <div className="card mb-5">
                        <div className="card-body d-flex flex-column align-items-center">
                            <div className="p-3"><img src="/img/tappolicyheadergraphic-cropped.png"></img></div>
                            {renderForm()}
                            <span className="mt-2">{loginerror}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default Login;

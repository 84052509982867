import React, { createContext, useState, useEffect } from "react";

const ContactParentContext = createContext();

const ContactParentProvider = ({ children }) => {
  const [ndcData, setNdcData] = useState([]);

  const fetchNDCData = async () => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    try {
      const dataLink = await fetch('https://ndcdata.s3.amazonaws.com/output.json', requestOptions);
      const result = await dataLink.json();
      setNdcData(result);

      // Save data to sessionStorage
      sessionStorage.setItem("ndcData", JSON.stringify(result));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Check if data is already in sessionStorage
    const storedData = sessionStorage.getItem("ndcData");
    if (storedData) {
      // If data is available, use it
      setNdcData(JSON.parse(storedData));
    } else {
      // If not, fetch the data
      fetchNDCData();
    }
  }, []);

  return (
    <ContactParentContext.Provider value={{ ndcData, fetchNDCData }}>
      {children}
    </ContactParentContext.Provider>
  );
}

export { ContactParentProvider, ContactParentContext };

import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "../Account/Account";
import { useNavigate } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import ProcessReminders from "./ProcessReminders";
import PerformanceChart from "./PerformanceChart";


const Dashboard = () =>{
    const navigate = useNavigate();
    const { getSession } = useContext(AccountContext);
    const [token, setToken] = useState("");
    const [agentId, setAgentId] = useState("");
    const [orgId, setOrgId] = useState("")
    const [contactCount, setContactCount] = useState("0");
    const [appsSubmitted, setAppsSubmitted] = useState("0");
    const [performanceData, setPerformanceData] = useState([])
    const [ranking, setRanking] = useState("");
    const [announcement, setAnnouncement] = useState(null);
    const [agentFirstName, setAgentFirstName] = useState("");
    useEffect(() => {
        getSession().then((session) => {
          setToken(session.idToken.jwtToken);
          setAgentId(session["custom:agentId"]);
          setOrgId(session["custom:orgid"])
          console.log(session)
          setAgentFirstName(session["name"].split(" ")[0]);
        }).catch((err) =>{
          navigate('/auth');
        })
        ;
      }, []);

      useEffect(()=>{
        fetchData()
      }, [agentId])


      const fetchData = async () => {
        setPerformanceData([])
        try {
            const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/pullinitialdashboarddata?agentid=${agentId}`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "auth-key": token
              },
            });
            const responseJson = await response.json();
            console.log(responseJson);
            setContactCount(responseJson.body.agent_contact_count ?? 0);
            setAppsSubmitted(responseJson.body.app_submission_count ?? 0);
            setRanking(responseJson.body.agent_rank ?? "-");
            setAnnouncement(responseJson.body.announcement ?? null)
            setPerformanceData(responseJson.body.performance_chart ?? [])
          } catch (error) {
            console.error("Error fetching data:", error);
          }
      };


      return (
       <>
       <NavBar />
       <div className="row h-100">
        <Sidebar />
        <div className="col main-content">
            {announcement ? (
                  <div className="row p-2 mb-2">
                  <div className="col">
                      <div className="card">
                          <div className="card-body announcement-border text-center">
                              <p className="card-text"><span><strong>Announcement:</strong></span>&nbsp; <span dangerouslySetInnerHTML={{ __html: announcement }} /></p>
                          </div>
                      </div>
                  </div>
              </div>
            ):(
                <></>
            )}
            <div className="row p-2 text-start">
                <div className="col-10">
                    {agentFirstName ? (<h4>Welcome Back, {agentFirstName}!</h4>):(<h4>Welcome Back!</h4>)}
                </div>
                <div className="col col-2 text-center hover-cursor" onClick={()=>{fetchData();}}>Refresh <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
</svg></div>
            </div>
            <div className="row p-2 mb-4">
                <div className="col">
                    <div className="card">
                        <div className="card-body text-center">
                            <h1 className="card-title">{contactCount}</h1>
                            <h6 className="text-muted card-subtitle mb-2">Contact Count</h6>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card">
                        <div className="card-body text-center">
                            <h1 className="card-title">{appsSubmitted}</h1>
                            <h6 className="text-muted card-subtitle mb-2">YTD Applications Submitted</h6>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card">
                        <div className="card-body text-center">
                            <h1 className="card-title">#{ranking}</h1>
                            <h6 className="text-muted card-subtitle mb-2">Your Ranking</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row p-2 mb-4">
                <div className="col">
                    <div className="row w-100 m-auto box">
                        <div className="col col-12 border-bottom text-start">
                            <div>
                                <h5>Your Performance</h5>
                            </div>
                        </div>
                        {performanceData.length ? (<PerformanceChart data={performanceData}/>):(<div className="w-100 m-auto text-center"><h6>Loading performance chart.</h6></div>)}
                    </div>
                </div>
            </div>
            <div className="row p-2 mb-4">
                <div className="col">
                <div className="row w-100 m-auto box">
                <ProcessReminders/>
                </div>
                </div>
            </div>
        </div>
    </div>
       </>
      );
      
}

export default Dashboard;
import React, { useContext } from "react";
import { ContactContext } from "./ContactParent";
import { Accordion } from "react-bootstrap";

const SMSHistory = () => {
    const { viewContactData, setViewContactData } = useContext(ContactContext);
    return (
        <Accordion>
            <Accordion.Item eventKey="0">
            <Accordion.Header>Text History <span>({viewContactData.smshistory.length})</span></Accordion.Header>
            <Accordion.Body>
                {
                    viewContactData.smshistory.length > 0 ? (
                                                <div><ul className="timeline">
                                                    {
                                                        viewContactData.smshistory.map((item, index) => (
                                                            <li className="timeline-item mb-5">
                                                            <h6>Date Sent: {new Date(item.time).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric'})}</h6>
                                                            <p>From: {item.fromagentid}</p>
                                                            <p>To: {item.tonumber}</p>
                                                            <p>Message: {item.text}</p>
                                                            <p>Files: {item.files?.length >0 ? item.files : "None"}</p>
                                                            </li>
                                                        ))
                                                    }
                                                </ul></div>
                                       
                   ):(
                    <div className="w-100 text-center">
                                                        <h6>No text history</h6>
                                                        </div>
                   )
                }
            </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

export default SMSHistory;
import React, { useContext, createContext, useState, useEffect } from "react";
import { AccountContext, getToken } from "../../components/Account/Account";
const OrgContext = createContext();

const OrgDetails = (props) => {
    const { userAttributes, getToken, getSession } = useContext(AccountContext);
    const [orgDetails, setOrgDetails] = useState(null);
    const [fetching, setFetching] = useState(false);
    const [token, setToken] = useState(null);


      useEffect(() => {
        getSession().then((session) => {
             setToken(session.idToken.jwtToken);
        }).catch((err) =>{
          
        })
        ;
      }, []);

    useEffect(() => {
        const fetchData = async () => {
            const storedData = sessionStorage.getItem("orgDetails");
            if (storedData) {
                setOrgDetails(JSON.parse(storedData));
              } else {
                if (userAttributes["custom:agentId"] && userAttributes["custom:orgid"] && token && orgDetails === null && !fetching) {
                    console.log("fetching")
                    try {
                        setFetching(true);
                        const sessionToken = await getToken();
                        const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/pullorgdetails?agentid=${userAttributes["custom:agentId"]}&orgid=${userAttributes["custom:orgid"]}`, {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                "auth-key": sessionToken
                            },
                        });
                        const responseJson = await response.json();
                        setOrgDetails(responseJson.data[0].pull_org_details[0]);
                        sessionStorage.setItem("orgDetails", JSON.stringify(responseJson.data[0].pull_org_details[0]));
                    } catch (error) {
                        console.error("Error fetching data:", error);
                    } finally {
                        setFetching(false);
                    }
                }
            };
        }
        fetchData();
    }, [token, userAttributes]);

    return (
        <OrgContext.Provider value={{ orgDetails }}>
            {props.children}
        </OrgContext.Provider>
    );

}

export { OrgDetails, OrgContext }

import React, { createContext, useEffect, useState } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "../../UserPool";
import { deepEqual } from "../../resources/hooks/hooks";

const AccountContext = createContext();

const Account = (props) => {

  const [userAttributes, setUserAttributes] = useState({});
  const [activeSession, setActiveSession] = useState(null);
  const [integrationCredentials, setIntegrationCredentials] = useState(null);
  const [agentData, setAgentData] = useState(null);
  const [sessionInformation, setSessionInformation] = useState(null);



  useEffect(()=>{
    if(activeSession) pullAgentData();
  },[activeSession])

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject();
          } else {
            try {
              setSessionInformation(session);
              const attributes = await getUserAttributes(user);
              setUserAttributes(attributes);
              if(activeSession !== true){
                setActiveSession(true);
              }
              resolve({ user, ...session, ...attributes });
            } catch (error) {
              reject(error);
            }
          }
        });
      } else {
        reject();
      }
    });
  };
  
  const getUserAttributes = (user) => {
    return new Promise((resolve, reject) => {
      user.getUserAttributes((err, attributes) => {
        if (err) {
          reject(err);
        } else {
          const results = {};
          for (let attribute of attributes) {
            const { Name, Value } = attribute;
            results[Name] = Value;
          }
          resolve(results);
        }
      });
    });
  };

  

  const getToken = async () =>{
      return await new Promise((resolve, reject) => {
        const user = Pool.getCurrentUser();
        if (user) {
          user.getSession(async (err, session) => {
            if (err) {
              reject();
            } else {
              resolve(session.idToken.jwtToken);
            }
          });
        } else {
          reject();
        }
      });
    };

  const authenticate = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });

      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: function(result) {
          resolve(result);
        },
      
        onFailure: function(err) {
          alert(err.message || JSON.stringify(err));
          reject(err);
        },
      
      
        mfaRequired: function(codeDeliveryDetails) {
          var verificationCode = prompt('Please input verification code', '');
          user.sendMFACode(verificationCode, this);
        },
      });
    });
  };

  const initiateResetPassword = async (Username) => {
    const user = new CognitoUser({ Username, Pool });
    return await new Promise((resolve, reject) =>{
      user.forgotPassword({
        onSuccess: function(data) {
          resolve(data);
        },
        onFailure: function(err) {
          alert(err.message || JSON.stringify(err));
          reject(err);
        }
      });
    })
  }

  const confirmResetPassword = async (Username, Code, Password) => {
    const user = new CognitoUser({ Username, Pool });
    return await new Promise((resolve, reject) =>{
    user.confirmPassword(Code, Password, {
			onSuccess() {
				console.log('Password confirmed!');
        resolve()
			},
			onFailure(err) {
				console.log('Password not confirmed!');
        console.log(err);
        reject(err)
			},
		  });
    })
  }

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
    }
  };

  const pullIntegrationCredentials = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const session = await getSession();
        const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/account/pullcredentials?agentid=${session.idToken.payload["custom:agentId"]}&orgid=${session.idToken.payload["custom:orgid"]}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "auth-key": session.idToken.jwtToken
          }
        });
        const responseJson = await response.json();
        if (response.status === 200) {
          setIntegrationCredentials(JSON.parse(responseJson.body));
          resolve(JSON.parse(responseJson.body));
        } else {
          reject();
        }
      } catch (error) {
        reject(error);
      }
    });
  }




  const pullAgentData = async () => {
    const session = await getSession();
    if(session){
      const storedData = sessionStorage.getItem("agentData");
          if (!storedData) {
              try {
                const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/pullinitialdashboarddata?agentid=${session.idToken.payload["custom:agentId"]}`, {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    "auth-key": session.idToken.jwtToken
                  },
                });
                const responseJson = await response.json();
                sessionStorage.setItem("agentData", JSON.stringify(responseJson.body));
                setAgentData(responseJson.body);
              } catch (error) {
                console.error("Error fetching data:", error);
              } 
          } else {
              try {
                const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/pullinitialdashboarddata?agentid=${session.idToken.payload["custom:agentId"]}`, {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    "auth-key": session.idToken.jwtToken
                  },
                });
                const responseJson = await response.json();
                if(!deepEqual(storedData, responseJson.body)){
                  sessionStorage.setItem("agentData", JSON.stringify(responseJson.body));
                  setAgentData(responseJson.body);
                }
              } catch (error) {
                console.error("Error fetching data:", error);
              } 
          }
    }
  }
  

  return (
    <AccountContext.Provider value={{ authenticate, userAttributes, getSession,getToken, initiateResetPassword, confirmResetPassword, logout, integrationCredentials, pullIntegrationCredentials, activeSession, agentData, pullAgentData, sessionInformation }}>
      {props.children}
    </AccountContext.Provider>
  );
};
export { Account, AccountContext };

import React, { useState, useContext, useEffect, useMemo, useRef, useCallback } from "react";
import { AccountContext } from "../Account/Account";
import { Five9Context } from "../Five9/Five9Master";
import { useNavigate } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import { deepEqual } from "../../resources/hooks/hooks";
import { useTable, useSortBy } from "react-table";
import { formatPhoneNumber } from "../../resources/hooks/hooks";
import { Modal } from "react-bootstrap";



const useCustomTable = (columns, data) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data }, useSortBy);

  return {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  };
};


const CallHistory = () =>{
    const navigate = useNavigate();
    const { userAttributes, getSession, agentData } = useContext(AccountContext);
    const { domainDispositions } = useContext(Five9Context);
    const [token, setToken] = useState("");
    const [agentId, setAgentId] = useState("");
    const [orgId, setOrgId] = useState("");
    const [callHistoryDetails, setCallHistoryDetails] = useState([]);
    const [copyCallHistoryDetails, setCopyCallHistoryDetails] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [callsPerPage,setCallsPerPage] = useState(10);
    const startIndex = (currentPage - 1) * callsPerPage;
    const endIndex = currentPage * callsPerPage;
    const [totalPages, setTotalPages] = useState(Math.ceil(callHistoryDetails.length / callsPerPage));
    const filterRef = useRef();
    const [apiFailure, setApiFailure] = useState(false);
    const [apiFailureText, setApiFailureText] = useState(null);
    const [showEditCall, setShowEditCall] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [editCallTempInfo, setEditCallTempInfo] = useState({
      "id": null,
      "time": null,
      "direction": null,
      "numbercalled": null,
      "calledby": null,
      "duration": null,
      "disposition": null,
      "contactid": null,
      "comments":null,
      "firstname": null,
      "lastname": null
  });
  const handleEditCallClose = () => {
    setEditCallTempInfo({
      "id": null,
      "time": null,
      "direction": null,
      "numbercalled": null,
      "calledby": null,
      "duration": null,
      "disposition": null,
      "contactid": null,
      "comments":null,
      "firstname": null,
      "lastname": null
  })
  setShowEditCall(false);
};

    useEffect(() => {
        getSession().then((session) => {
          setToken(session.idToken.jwtToken);
          setAgentId(session["custom:agentId"]);
          setOrgId(session["custom:orgid"]);
        }).catch((err) =>{
          navigate('/auth');
        })
        ;
      }, []);

      useEffect(()=>{
        if(token && agentId && orgId)fetchCallHistory();
      },[token,agentId,orgId]);


      useEffect(()=>{
        if(callHistoryDetails.length > 0){
          setCopyCallHistoryDetails(callHistoryDetails.slice(startIndex, endIndex));
          setTotalPages(Math.ceil(callHistoryDetails.length / callsPerPage))
        }
      },[callHistoryDetails]);

      useEffect(()=>{
        setCopyCallHistoryDetails(callHistoryDetails.slice(startIndex, endIndex))
      },[currentPage])

      useEffect(()=>{
        setCurrentPage(1);
       if(filterRef.current.value){
          let obj = {target: {
            value: filterRef.current.value
          }}
        filterCalls(obj)
       }else{
        setCopyCallHistoryDetails(callHistoryDetails.slice(startIndex, endIndex))
        setTotalPages(Math.ceil(callHistoryDetails.length / callsPerPage))
       }
      },[callsPerPage])

       const editCall = useCallback(
        (cid) => {
          const callToUpdate = callHistoryDetails.find((call) => call.id === cid);
          if (callToUpdate) {
            setEditCallTempInfo(callToUpdate);
            setShowEditCall(true);
          }
        },
        [callHistoryDetails],
      );



      const callColumns = useMemo(
        () => [
          { 
            Header: "Date", 
            accessor: "time", 
            Cell: ({ row }) => (
              <>
                {formatDate(row.original.time)}
              </>
            ),
          },
          { 
            Header: "Contact Name", 
            accessor: "contactname", 
            Cell: ({ row }) => (
              <>
              {row.original.firstname} {row.original.lastname}
            </>
            ),
          },
          { 
            Header: "Direction", 
            accessor: "direction", 
            Cell: ({ row }) => (
              <>
              {row.original.direction.charAt(0).toUpperCase() + row.original.direction.slice(1)}
            </>
            ),
          },
          { 
            Header: "Phone Number", 
            accessor: "numbercalled", 
            Cell: ({ row }) => (
              <>
              {
                row.original.direction === "inbound" ? formatPhoneNumber(row.original.calledby) : formatPhoneNumber(row.original.numbercalled)
              }
            </>
            ),
          },
          { 
            Header: "Call Duration", 
            accessor: "duration", 
            Cell: ({ row }) => (
              <>
              {formatMilliseconds(row.original.duration)}
            </>
            ),
          },
          { 
            Header: "Disposition", 
            accessor: "disposition", 
            Cell: ({ row }) => (
              <>
              {row.original.disposition}
            </>
            ),
          },
          {
            Header: "Actions",
            accessor: "actions",
            Cell: ({ row }) => (
              <>  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye me-2 hover-cursor" viewBox="0 0 16 16"  onClick={() => navigate(`/customers/view?id=${row.original.contactid}`)}>
              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
            </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone me-2 hover-cursor" viewBox="0 0 16 16" onClick={() => console.log("inititate call function")}>
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil hover-cursor" viewBox="0 0 16 16" onClick={() => {editCall(row.original.id)}}>
  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
</svg>
             </>
            ),
          },
        ],
        [editCall]
      )


      const {
        getTableProps: getCallTableProps,
        getTableBodyProps: getCallTableBodyProps,
        headerGroups: callHeaderGroups,
        rows: callRows,
        prepareRow: prepareCallRow,
      } = useCustomTable(callColumns, copyCallHistoryDetails);





      const fetchCallHistory = async () => {
            setApiFailure(false);
            setApiFailureText(null)
        try {
          const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/pullcallhistory?agentid=${agentId}&orgid=${orgId}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "auth-key": token
            },
          });
          if(response.status === 200){
            const responseJson = await response.json();
            if(typeof responseJson.body === "object" && responseJson.body.length > 0){
              const storedData = JSON.parse(sessionStorage.getItem("callHistory"));
              if(!storedData){
                sessionStorage.setItem("callHistory", JSON.stringify(responseJson.body));
                setCallHistoryDetails(responseJson.body)
                setIsLoading(false);
              }else{
                if(!deepEqual(storedData, responseJson.body)){
                  sessionStorage.setItem("callHistory", JSON.stringify(responseJson.body));
                  setCallHistoryDetails(responseJson.body)
                  setIsLoading(false);
                }else{
                  if(callHistoryDetails?.length > 0){
                    setCallHistoryDetails(storedData);
                    setIsLoading(false);
                  }
                }
              }
             }
          }else{
            setApiFailure(true);
            setApiFailureText("Error fetching call history, please try again.")
            console.error("BONER Error fetching call history, must retry")
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setApiFailure(true);
          setApiFailureText("Error fetching call history, please try again.")
        }
      }

      const selectPage = (p) => {
        console.log(p)
        setCurrentPage(p)
      }

      const filterCalls = (e) => {
        if(e.target.value){
          const lowercaseInput = e.target.value.toLowerCase();
        let resultingData = callHistoryDetails.filter(item => {
          for (let key in item) {
            const lowercaseValue = String(item[key]).toLowerCase();
            if (lowercaseValue.includes(lowercaseInput)) {
              return true;
            }
          }
          return false;
        });
          setCopyCallHistoryDetails(resultingData.slice(startIndex, endIndex));
          setTotalPages(Math.ceil(resultingData.length / callsPerPage))
        }else{
          setCopyCallHistoryDetails(callHistoryDetails.slice(startIndex, endIndex))
          setTotalPages(Math.ceil(Math.ceil(callHistoryDetails.length / callsPerPage)))
        }
      };

      const formatMilliseconds = (ms) => {
        const minutes = Math.floor(ms / 60000);
        const seconds = ((ms % 60000) / 1000).toFixed(0);
      
        if (minutes > 0) {
          return `${minutes}m ${seconds}s`;
        } else {
          return `${seconds}s`;
        }
      };

      const formatDate = (isoString) => {
        const date = new Date(isoString);
        const options = {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true,
          timeZone: 'America/New_York' // Adjusted to Eastern Standard Time (EST)
        };
      
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
        
        return `${formattedDate} EST`;
      };


      const handleCallDetailChange = (e) => {
        setEditCallTempInfo(prev => ({
          ...prev,
          [e.target.name] : e.target.value
        }))
      }

      const updateCallDetails = async () => {
        console.log(editCallTempInfo)

        try {
          const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/updatecalldetails?agentid=${agentId}&orgid=${orgId}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "auth-key": token
            },
            body: JSON.stringify(editCallTempInfo)
          });
          if(response.status === 200){
            handleEditCallClose();
            fetchCallHistory();
          }
          const responseJson = await response.json();
          console.log(responseJson)
        } catch (error) {
          console.log(error)
        }
      }



    return (
      <>
      <div><NavBar />
       <div className="row h-100">
        <Sidebar />
        <div className="col main-content">
        <div className="row p-2 mb-4">
                <div className="col">
                    <div className="card">
                        <div className="card-body text-center">
                        <h1 className="card-title">{callHistoryDetails ? (callHistoryDetails.filter(item => item.duration > 90000).length) : '0'}</h1>
                            <h6 className="text-muted card-subtitle mb-2">Total Calls ({">"} 90 Seconds)</h6>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card">
                        <div className="card-body text-center">
                            <h1 className="card-title">{agentData?.agent_data?.data[0]?.appslifetime ? (agentData?.agent_data?.data[0]?.appslifetime) : ('0')}</h1>
                            <h6 className="text-muted card-subtitle mb-2">Total Sales</h6>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card">
                        <div className="card-body text-center">
                        <h1 className="card-title">{`${(callHistoryDetails?.length > 0 && agentData?.agent_data?.data[0]?.appslifetime) ? ((agentData.agent_data.data[0].appslifetime / callHistoryDetails.filter(item => item.duration > 90000).length)).toFixed(2) * 100 + '%' : '- %'}`}</h1>
                            <h6 className="text-muted card-subtitle mb-2">Conversion Rate</h6>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card">
                    <div className="card-body text-center">
  <h1 className="card-title">{(callHistoryDetails && callHistoryDetails.filter(item => item.duration > 9e4).length > 0) ? formatMilliseconds(callHistoryDetails.filter(item => item.duration > 9e4).reduce((t, i) => t + i.duration, 0) / callHistoryDetails.filter(item => item.duration > 9e4).length) : '-'}</h1>
  <h6 className="text-muted card-subtitle mb-2">Average Talk Time</h6>
</div>
                    </div>
                </div>
            </div>
            <div className="row p-2 mb-4">
                <div className="col">
                    <div className="row w-100 m-auto box">
                        <div className="col col-8 border-bottom text-start">
                            <div>
                                <h5>Your Call History</h5>
                            </div>
                        </div>
                        <div className="col col-2 text-center border-bottom"><input type="search" className="w-100" placeholder="Search Calls..." ref={filterRef} onChange={(e)=>{filterCalls(e)}}></input></div>
                        <div className="col col-2 text-center border-bottom hover-cursor" onClick={()=>{fetchCallHistory();setIsLoading(true);}}>Refresh <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
</svg></div>
                        <div className="col col-12 p-3">
                        {!isLoading ? (
                        <div className="table-responsive">
            {copyCallHistoryDetails.length > 0 ? (
              <table className="table" {...getCallTableProps()}>
                <thead>
                  {callHeaderGroups.map((callHeaderGroup) => (
                    <tr {...callHeaderGroup.getHeaderGroupProps()}>
                      {callHeaderGroup.headers.map((column) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                          {column.render("Header")}
                          <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getCallTableBodyProps()}>
                  {callRows.map((callRow) => {
                    prepareCallRow(callRow);
                    return (
                      <tr {...callRow.getRowProps()}>
                        {callRow.cells.map((callCell, index) => (
                         <td {...callCell.getCellProps()}>
                            {callCell.render("Cell")}
                       </td>
                       
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
               ) : (
                <div className="w-100 m-auto text-center">
                  <h6>No calls found</h6>
                </div>
                 )}
            </div>):(
              <div className="float-all-middle">
                <div className="text-center">
                  <img src="/img/spinner.gif" className="loadingIcon"></img>
                  <h5>Loading Call History...</h5>
                  </div>               
              </div>
            )}
            </div>
            <div className="w-100">
              <div className="d-inline-flex">
              <nav aria-label="Page navigation">
                      <ul className="pagination">
                        {Array.from({ length: totalPages }, (_, index) => index + 1).map(pageNumber => (
                          <li key={pageNumber} className="page-item">
                           <button className={`${currentPage === pageNumber ? 'active ' : ''}page-link`} onClick={() => {selectPage(pageNumber);}}>
                              {pageNumber}
                            </button>
                          </li>
                        ))}
                        </ul>
                          </nav>
              </div>
              <div className="d-inline-flex ms-4">
              <select className="form-select" value={callsPerPage} onChange={(e) => {setCallsPerPage(e.target.value)}}>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                        </select>
              </div>
                    </div>
            </div>
                </div>
            </div>
        </div>
        </div>
        </div>
        <Modal show={showEditCall} onHide={handleEditCallClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Edit A Call</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                    <div className="row mb-3">
                      <div className="col-6"><input className="form-control" type="text" placeholder="Caller First Name" name="firstname" value={`${editCallTempInfo.firstname} ${editCallTempInfo.lastname}`} readOnly disabled/></div>
                      <div className="col-6"><input className="form-control" type="text" placeholder="Call Type" name="direction" value={editCallTempInfo.direction?.charAt(0).toUpperCase() + editCallTempInfo.direction?.slice(1)} readOnly disabled/></div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-6"><input className="form-control" type="text" placeholder="Caller Number" name="callernumber" value={editCallTempInfo.direction === "inbound" ? formatPhoneNumber(editCallTempInfo.calledby) : formatPhoneNumber(editCallTempInfo.numbercalled)} readOnly disabled/></div>
                      <div className="col-6">
                        <select className="form-select" value={editCallTempInfo.disposition} name="disposition" onChange={(e) => {handleCallDetailChange(e)}}>
                        {domainDispositions && (
                           domainDispositions.filter(item => parseInt(item.id) > 0)?.map(item => (
                            <option key={item.id} value={item.name}>{item.name}</option> // Replace `someProperty` with the property you want to display
                          ))
                        )}
                        </select></div>
                    </div>
                    <div className="row mb-3">
                      <div className="col col-12"><textarea className="form-control mb-2" placeholder="No call notes documented..." rows="10" name="comments" value={editCallTempInfo.comments} onChange={(e)=>{handleCallDetailChange(e)}}></textarea></div>
                      </div>
                </form>

            </Modal.Body>
          
                            <Modal.Footer>
                            <div className="w-100 text-end">
                            <button className="btn btn-light" type="button" onClick={handleEditCallClose}>Cancel</button>
                            <button className="btn main-button ms-4" type="submit" onClick={updateCallDetails}>Save Changes</button>
                            </div>
                        </Modal.Footer>
          </Modal>
      </>
      );
}

export default CallHistory;
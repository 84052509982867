import { useEffect, useState, useRef } from "react";
import areacodekey from "../helperdata/areacodekey.json";


// Deep compare two JSON objects
export const deepEqual = (obj1, obj2) => {
    if (obj1 === obj2) return true;

    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) return false;

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
        if (!obj2.hasOwnProperty(key) || obj1[key] !== obj2[key]) {
            return false;
        }
    }

    return true;
}

// Custom hook for debouncing view contact data
export const useViewContactDebounce = (value, delay = 1000) => {
    const [debouncedContactValue, setDebouncedContactValue] = useState(value);
    const timeoutRef = useRef(null);

    useEffect(() => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            setDebouncedContactValue(value);
        }, delay);
        return () => clearTimeout(timeoutRef.current);
    }, [value, delay]);
  
    return debouncedContactValue;
};

// Regular debounce hook
export const useDebounce = (value, delay = 300) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => clearTimeout(timeout);
    }, [value, delay]);

    return debouncedValue;
};

export const findLatLongByFirstThree = async (firstThree) => {
    return new Promise((resolve, reject) => {
        const matchingEntry = areacodekey.find(entry => entry.areacode === firstThree);
        if (matchingEntry.latitude && matchingEntry.longitude) {
            resolve({ latitude: matchingEntry.latitude, longitude: matchingEntry.longitude });
        } else {
            reject(new Error('Matching entry not found for the first three digits'));
        }
    });
};

export const formatPhoneNumber = (phoneNumber) => {
        let formattedNumber = '';
        const numberString = String(phoneNumber)
      
        if (numberString.length === 10) {
          formattedNumber = `(${numberString.substring(0, 3)}) ${numberString.substring(3, 6)}-${numberString.substring(6)}`;
        }else{
          console.log(numberString.length)
        }
      
        return formattedNumber;
  }


  export const convertDate = (datestring) => {
            const date = new Date(datestring);
            const options = { 
                month: 'short', 
                day: 'numeric', 
                year: 'numeric'
            };
    
            return date.toLocaleDateString('en-US', options);
    }
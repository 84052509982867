import React, { useState, useContext, useEffect, useMemo } from "react";
import { useTable, useSortBy } from "react-table";

// Custom hook for creating table
const useCustomTable = (columns, data) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data }, useSortBy);

  return {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  };
};

const LoadLeaderboards = (props) => {
  const [agentData, setAgentData] = useState([]);
  const [podData, setPodData] = useState([]);
  const [errorResult, setErrorResult] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  

  useEffect(() => {
    
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
  
      const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/leaderboards?agentid=${props.agentId}&orgid=${props.orgId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-key": props.access,
        },
      });
      const responseJson = await response.json();
      if (response.ok) {
        setAgentData(responseJson.body.agents);
        setPodData(responseJson.body.pods);
        setIsLoading(false);
      } else {
        console.error("Failed to load leaderboard");
        setErrorResult(responseJson.body);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error loading leaderboard:", error);
      setErrorResult("Error loading leaderboard");
      setIsLoading(false);
    }
  };

  const podColumns = useMemo(
    () => [
      { Header: "Pod Name", accessor: "podname" },
      { Header: "Day", accessor: "appstoday" },
      { Header: "Week", accessor: "appsweek" },
      { Header: "Month", accessor: "appsmonth" },
      { Header: "YTD", accessor: "appsyear" }
    ],
    []
  );

  const agentColumns = useMemo(
    () => [
      { Header: "Agent Name", accessor: "name" },
      { Header: "Day", accessor: "appstoday" },
      { Header: "Week", accessor: "appsweek" },
      { Header: "Month", accessor: "appsmonth" },
      { Header: "YTD", accessor: "appsyear" }
    ],
    []
  );

  const {
    getTableProps: getPodTableProps,
    getTableBodyProps: getPodTableBodyProps,
    headerGroups: podHeaderGroups,
    rows: podRows,
    prepareRow: preparePodRow
  } = useCustomTable(podColumns, podData);

  const {
    getTableProps: getAgentTableProps,
    getTableBodyProps: getAgentTableBodyProps,
    headerGroups: agentHeaderGroups,
    rows: agentRows,
    prepareRow: prepareAgentRow
  } = useCustomTable(agentColumns, agentData);

  return(
 
    <div className="container-fluid mt-4">
    <div className="row w-100 m-auto">
    {!isLoading ? (
      <>
        <div className="col-12 col-md-6 p-3 mb-5">
            <h2 className="text-center">Pod Leaderboard</h2>
            <div className="table-responsive  scroll-stop">
                <table className="table table-bordered" {...getPodTableProps()}>
                    <thead>
                    {podHeaderGroups.map((podHeaderGroup) => (
                        <tr {...podHeaderGroup.getHeaderGroupProps()}>
                        {podHeaderGroup.headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            <span>
                                {column.isSorted
                                ? column.isSortedDesc
                                    ? " 🔽"
                                    : " 🔼"
                                : ""}
                            </span>
                            </th>
                        ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getPodTableBodyProps()}>
                    {podRows.map((podRow) => {
                            preparePodRow(podRow);
                            return (
                            <tr {...podRow.getRowProps()}>
                                {podRow.cells.map((podCell) => (
                                <td {...podCell.getCellProps()}>{podCell.render("Cell")}</td>
                                ))}
                            </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
        <div className="col-12 col-md-6 p-3 mb-5">
            <h2 className="text-center">Agent Leaderboard</h2>
            <div className="table-responsive  scroll-stop">
                <table className="table table-bordered" {...getAgentTableProps()}>
                    <thead>
                    {agentHeaderGroups.map((agentHeaderGroup) => (
                        <tr {...agentHeaderGroup.getHeaderGroupProps()}>
                        {agentHeaderGroup.headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            <span>
                                {column.isSorted
                                ? column.isSortedDesc
                                    ? " 🔽"
                                    : " 🔼"
                                : ""}
                            </span>
                            </th>
                        ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getAgentTableBodyProps()}>
                    {agentRows.map((agentRow) => {
                            prepareAgentRow(agentRow);
                            return (
                            <tr {...agentRow.getRowProps()}>
                                {agentRow.cells.map((agentCell) => (
                                <td {...agentCell.getCellProps()}>{agentCell.render("Cell")}</td>
                                ))}
                            </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
        </>
        ):(
          <div className="float-all-middle">
            <div className="text-center">
              <img src="/img/spinner.gif" className="loadingIcon"></img>
              <h5>Loading Leaderboard Data...</h5>
              </div>               
          </div>
        )}
        <div className="col col-12 text-center hover-cursor" onClick={()=>{fetchData();setIsLoading(true);}}>Refresh <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
</svg></div>
    </div>
</div>

  )
};

export default LoadLeaderboards;

import React, { useContext } from "react";
import { Five9Context } from "./Five9Master";
import { Accordion } from "react-bootstrap";

const EmailHistory = () => {
    const { callContactManager, setCallContactManager } = useContext(Five9Context);
    return (
        <Accordion>
            <Accordion.Item eventKey="0">
            <Accordion.Header>Email History <span>({callContactManager.emailhistory.length})</span></Accordion.Header>
            <Accordion.Body>
                {
                    callContactManager.emailhistory.length > 0 ? (
                                                <div><ul className="timeline">
                                                    {
                                                        callContactManager.emailhistory.map((item, index) => (
                                                            <li className="timeline-item mb-5">
                                                            <h6>Subject: {item.subject} - {new Date(item.time).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric'})}</h6>
                                                            <p>From: {item.fromemail}</p>
                                                            <p>To: {item.toemail}</p>
                                                            <p>Body: {item.body}</p>
                                                            </li>
                                                        ))
                                                    }
                                                </ul></div>
                                       
                   ):(
                    <div className="w-100 text-center">
                                                        <h6>No email history</h6>
                                                        </div>
                   )
                }
            </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

export default EmailHistory;
import React, { useState, createContext, useContext, useEffect } from "react";
import { AccountContext } from "../Account/Account";
import { Five9Context } from "./Five9Master";
import { useNavigate } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import ContactPortable from "./ContactPortable"
import LoadScript from "./LoadScript";
import CallController from "./CallController";

const CallContext = createContext();

const Call = () =>{
    const navigate = useNavigate();
    const { getSession } = useContext(AccountContext);
    const { currentCall } = useContext(Five9Context);
    const [token, setToken] = useState(null);
    const [agentId, setAgentId] = useState(null);
    const [orgId, setOrgId] = useState(null)

    useEffect(() => {
        getSession().then((session) => {
          setToken(session.idToken.jwtToken);
          setAgentId(session["custom:agentId"]);
          setOrgId(session["custom:orgid"]);
        }).catch((err) =>{
          navigate('/auth');
        })
        ;
      }, []);


    return (
      <>
      <div><NavBar />
       <div className="row h-100">
        <Sidebar />
        <div className="col main-content float-middle">
          {currentCall ? (<CallContext.Provider value={{ token, agentId, orgId }}>
          <div className="row w-100 m-auto">
            <div className="col-6">
              <LoadScript />
            </div>
            <div className="col-6">
              <ContactPortable />
            </div>
          </div>
          </CallContext.Provider>) : (
            <div className="float-all-middle">
              <div className="text-center">
                <img src="/img/spinner.gif" className="loadingIcon"></img>
                <h5>Waiting For Calls...</h5>
                </div>               
            </div>
          )}
        </div>
        <CallController />
        </div>
        </div>
      </>
      );
}

export {Call, CallContext};
import React, { useContext, useEffect, useState, useRef } from "react";
import { Five9Context } from "./Five9Master";
import { Modal } from "react-bootstrap";
import { AccountContext } from "../Account/Account";
import { OrgContext } from "../../resources/helperdata/OrgDetails"


const SendSMS = (props) => {
    const { userAttributes, getToken } = useContext(AccountContext);
    const { callContactManager, setCallContactManager } = useContext(Five9Context);
    const { orgDetails } = useContext(OrgContext);
    const [smsObject, setSMSObject] = useState({to: callContactManager.anis[0] ? callContactManager.anis[0] : null, text:null, sendvcc:false, tocontactid:callContactManager.id, fromagentid:userAttributes["custom:agentId"], orgid:userAttributes["custom:orgid"], npn:userAttributes["custom:npn"], consenttoken:callContactManager.consenttoken, type:"SMS"});
    const [isSMSSending, setIsSMSSending] = useState(false);
    const [smsSentSuccess, setSMSSentSuccess] = useState(null);
    const [missingErrorText, setMissingErrorText] = useState("");
    const [sendStatusErrorText, setSendStatusErrorText] = useState(null);
    const [smsBodyLength, setSMSBodyLength] = useState(0);

    const smsBodyRef = useRef();
    const handleSMSClose = () => {
        setSMSObject({to: callContactManager.anis[0] ? callContactManager.anis[0] : null, text:null, sendvcc:false, tocontactid:callContactManager.id, fromagentid:userAttributes["custom:agentId"], orgid:userAttributes["custom:orgid"], npn:userAttributes["custom:npn"], consenttoken:callContactManager.consenttoken, type:"SMS"})
        props.setSMSValue(false);
    };


    const handleChange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        if(e.target?.name !== 'text'){
            if(e.target?.name === 'to'){
                setSMSObject(prevState => ({...prevState, [e.target.name]: parseInt(value)}))
            }else{
                setSMSObject(prevState => ({...prevState, [e.target.name]: value}))
            }
        } else if(smsBodyLength < 110){
            setSMSObject(prevState => ({...prevState, [e.target.name]: value}))
            setSMSBodyLength(e.target.value.length)
        }else{
            e.preventDefault()
        }

    }

    const recordManualConsent = () =>{
        let token;
        callContactManager.ani ? token = `${callContactManager.ani}-${Date.now()}` : callContactManager.email ? token = `${callContactManager.email}-${Date.now()}` :  token = `agent-${userAttributes["custom:agentId"]}-${Date.now()}`;
        setCallContactManager(prevState => ({
            ...prevState,
            consent: true,
            consenttoken: token
        }))

        setSMSObject(prevState => ({
            ...prevState,
            consenttoken: token
        }))
    }

    useEffect(()=>{
        if(smsSentSuccess === true){
            //set timeout to close popup
            setTimeout(()=>{
                handleSMSClose();
                setIsSMSSending(false);
                setSMSSentSuccess(null);
                setSMSBodyLength(0);
            },3000)
        }else if (smsSentSuccess === false){
            setTimeout(()=>{
                handleSMSClose();
                setIsSMSSending(false);
                setSMSSentSuccess(null);
                setSendStatusErrorText(null);
                setSMSBodyLength(0);
            },3000)
        }
    },[smsSentSuccess])

    useEffect(() =>{
        console.log(smsObject)
    }, [smsObject])


    const addPlaceholder = (e) =>{
        const placeholderName = e.target.getAttribute('data-name');
        let additionalDataValue;
        console.log(placeholderName);
        switch (placeholderName){
            case 'Customer_FirstName':
                additionalDataValue = callContactManager.firstname || "**Unknown**";
            break;
            case 'Customer_LastName':
                additionalDataValue = callContactManager.lastname || "**Unknown**";
            break;
            case 'Customer_Phone':
                additionalDataValue = callContactManager.ani || "**Unknown**";
            break;
            case 'Agent_FirstName':
                additionalDataValue = userAttributes.name.split(" ")[0] || "**Unknown**";
            break;
            case 'Agent_LastName':
                additionalDataValue = userAttributes.name.split(" ")[1] || "**Unknown**";
            break;
            case 'Agent_DirectLine':
                additionalDataValue = userAttributes.phone_number || "**Unknown**";
            break;
            case 'Agent_NPN':
                additionalDataValue = userAttributes.npn || "**Unknown**";
            break;
        }

        setSMSObject(prevState => ({
            ...prevState,
            text: `${prevState.text || ''}${additionalDataValue}`
        }))
    }



    const fetchToken = async () => {
        try {
            const token = await getToken();
            return token;
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    const loadTemplate = (e) =>{
        const selectedObject = orgDetails.orgsmstemplates.filter(item => item.id === e.target.value);

        if(selectedObject){ 
            const pattern = /\*\*(.*?)\*\*/g;
            const bodyMatches = selectedObject[0].text.match(pattern);

            if(bodyMatches){
                for(let _match of bodyMatches){
                    console.log(_match)
                    let _stripVariable = _match.replace("**", "").replace("**","");
                    switch (_stripVariable){
                        case 'Customer_FirstName':
                            selectedObject[0].text =  selectedObject[0].text.replace(_match, callContactManager.firstname || "**Unknown**")
                        break;
                        case 'Customer_LastName':
                            selectedObject[0].text =  selectedObject[0].text.replace(_match, callContactManager.lastname || "**Unknown**")
                        break;
                        case 'Customer_Phone':
                            selectedObject[0].text =  selectedObject[0].text.replace(_match, callContactManager.ani || "**Unknown**")
                        break;
                        case 'Agent_FirstName':
                            selectedObject[0].text =  selectedObject[0].text.replace(_match, userAttributes.name.split(" ")[0] || "**Unknown**")
                        break;
                        case 'Agent_LastName':
                            selectedObject[0].text =  selectedObject[0].text.replace(_match, userAttributes.name.split(" ")[1] || "**Unknown**")
                        break;
                        case 'Agent_DirectLine':
                            selectedObject[0].text =  selectedObject[0].text.replace(_match, userAttributes.phone_number || "**Unknown**")
                        break;
                        case 'Agent_NPN':
                            selectedObject[0].text =  selectedObject[0].text.replace(_match, userAttributes.npn || "**Unknown**")
                        break;
                        }
                    }
            }

                
            setSMSObject((prevState)=> ({
                ...prevState,
                text: selectedObject[0].text,
                sendvcc: selectedObject[0].vcc !== undefined && selectedObject[0].vcc === true ? true : false
            }))

            setSMSBodyLength(selectedObject[0].text.length)
        }
    }

    const validateAndSendSMS = async () => {
        const isMissing = Object.values(smsObject).some(value => value === null || value === "");
        if(!isMissing){
            setMissingErrorText("");
            setIsSMSSending(true);
            const smsToken = await fetchToken();
            console.log(smsObject)
            try {
                const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/sendsms`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "auth-key": smsToken
                    },
                    body: JSON.stringify(smsObject),
                    });
                    const responseData = await response.json();
                    if (responseData.statusCode === 200) {
                        setSMSSentSuccess(true);
                        setSendStatusErrorText(null)
                    } else {
                        setSMSSentSuccess(false);
                        console.log(response);
                        setSendStatusErrorText(responseData.data)
                    }

            } catch (error) {
                console.log(error)
                setSMSSentSuccess(false);
            }

        }else{
            //toast or error that there is something missing
            setMissingErrorText("Please complete all required fields before sending an text message.")
            console.log(smsObject)
        }
    } 

    return (
      <Modal show={props.showSMSModal} onHide={handleSMSClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Send A Text</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            {callContactManager.consent ? (
                <>
                {!isSMSSending ? (
                    <form>
                    <div className="row mb-3">
                        <div className="col col-12"><select className="form-select" name="to" onChange={(e)=>{handleChange(e)}}>
                                {callContactManager.anis.map((ani, index) => (   
                                    <option key={index} value={ani}>To: {ani}</option>
                                    ))}
                            
                            </select></div>
                    </div>
                    <div className="row mb-3">
                        <div className="col col-12"><select className="form-select" onChange={(e)=>loadTemplate(e)}>
                                <option value="loadtemplate" selected>Load Template</option>
                                {orgDetails.orgsmstemplates?.length > 0 && (
                                    orgDetails.orgsmstemplates.map((template) =>{
                                        return <option value={template.id}>{template.name}</option>
                                    })
                                )}
                            </select></div>
                    </div>
                    <div className="row mb-3">
                        <div className="col col-12"><div className="text-wrapper"><textarea className="form-control mb-2" placeholder="Message" rows="10" name="text" ref={smsBodyRef} value={smsObject.text} onChange={(e)=>handleChange(e)}></textarea><span className="inner-textArea">{smsBodyLength}/110</span></div>
                        <div><button class="placeholder-button" data-name="Customer_FirstName"  type="button" onClick={(e)=>addPlaceholder(e)}>Customer First Name</button><button class="placeholder-button" data-name="Customer_LastName" type="button" onClick={(e)=>addPlaceholder(e)}>Customer Last Name</button><button class="placeholder-button" data-name="Customer_Phone" type="button" onClick={(e)=>addPlaceholder(e)}>Customer Phone</button><button class="placeholder-button" data-name="Agent_FirstName" type="button" onClick={(e)=>addPlaceholder(e)}>Agent First Name</button><button class="placeholder-button" data-name="Agent_LastName" type="button" onClick={(e)=>addPlaceholder(e)}>Agent Last Name</button><button class="placeholder-button" data-name="Agent_DirectLine" type="button" onClick={(e)=>addPlaceholder(e)}>Agent Direct Line</button><button class="placeholder-button" data-name="Agent_NPN" type="button" onClick={(e)=>addPlaceholder(e)}>Agent NPN</button></div>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="ms-1">
                            <input className="form-check-input" type="checkbox" id="vcc-check" checked={smsObject.sendvcc} name="sendvcc" onChange={(e) => {handleChange(e)}}/>
                            <label className="form-check-label" htmlFor="vcc-check">&nbsp;Include My Virtual Contact Card</label>
                            </div>
                    </div>
                    {missingErrorText != "" && (
                    <div className="errorBlock">
                        <span>{missingErrorText}</span>
                    </div>
                    )}
                </form>
                 ):(
                    <div className="p-4 text-center">
                        { smsSentSuccess === null && (
                            <>
                                <div className="w-100 p-0 mb-3">
                                    <img src="/img/spinner.gif" className="loadingIcon"></img>
                                </div>
                                <div className="w-100 p-0">
                                    <h5>Sending Text...</h5>
                                </div>
                            </>
                        )}
                          { smsSentSuccess && (
                            <>
                                <div className="w-100 p-0 mb-3">
                                    <img src="/img/success.png" className="resultIcon"  playsInline loop={false}></img>
                                </div>
                                <div className="w-100 p-0">
                                    <h5>Text Sent Successfully!</h5>
                                </div>
                            </>
                        )}
                        {smsSentSuccess === false &&(
                            <>
                                <div className="w-100 p-0 mb-3">
                                    <img src="/img/error.png" className="resultIcon"></img>
                                </div>
                                <div className="w-100 p-0">
                                    <h5>Failed To Send Text</h5>
                                </div>
                                <div className="w-100 pt-3 pb-0">
                                    <h6>{sendStatusErrorText}</h6>
                                </div>
                            </>
                    )}
                   </div>
                 )}
                 </>
            ):(
                <>
                <div className="row w-100 m-auto p-3 text-center">
                    <div className="col-12">
                    <img src="/img/error.png" className="resultIcon mb-5"></img>
                    </div>
                    <div className="col-12 mb-4">
                        <h5>Warning: We have no record of consent for this contact. Would you like to record consent now?</h5>
                    </div> 
                    <div className="col-6">
                            <button className="btn main-button ms-4" type="submit" onClick={handleSMSClose}>Cancel Text</button>
                    </div>
                    <div className="col-6">
                            <button className="btn main-button ms-4" type="submit" onClick={recordManualConsent}>Yes, I Have Consent</button>
                    </div>
                </div>
                </>
            )}


            </Modal.Body>
            {callContactManager.consent && (
                <>
            { !isSMSSending && (
                            <Modal.Footer>
                            <div className="w-100 text-end">
                            <button className="btn btn-light" type="button" onClick={handleSMSClose}>Cancel</button>
                            <button className="btn main-button ms-4" type="submit" onClick={validateAndSendSMS}>Send Text</button>
                            </div>
                        </Modal.Footer>
                )}
            </>
            )}
          </Modal>
    );
}

export default SendSMS;

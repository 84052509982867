import React, { useState, useContext } from "react";
import { AccountContext } from "./Account";

export default () => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("")

  const { getSession } = useContext(AccountContext);

  const onSubmit = (event) => {
    event.preventDefault();

    if(newPassword === confirmNewPassword){
      getSession().then(({ user }) => {
        user.changePassword(password, newPassword, (err, result) => {
          if (err) {
            console.error(err);
          } else {
            console.log(result);
          }
        });
      });
    }else{
      alert("Your new passwords do not match!")
    }
  };

  return (
    <div className="p-2 mt-3">
      <form onSubmit={onSubmit}>
        <label className="me-3">Current password</label>
        <input
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          className="me-4"
        />

        <label className="me-3">New password</label>
        <input
          value={newPassword}
          onChange={(event) => setNewPassword(event.target.value)}
          className="me-4"
        />

        <label className="me-3">Confirm New Password</label>
                <input
                  value={confirmNewPassword}
                  onChange={(event) => setConfirmNewPassword(event.target.value)}
                  className="me-4"
                />

        <button className="btn btn-primary" type="submit">Change password</button>
      </form>
    </div>
  );
};

import React, { useState, useContext, useEffect } from "react";
import { Five9Context } from "../Five9/Five9Master";
import { AccountContext } from "../Account/Account";

const CallController = () =>{
  const { currentCall, currentCallDetails } = useContext(Five9Context);

    return (
        currentCall && (
            <div className="callController p-2">
            <div className="row">
                    <div className="col p-2 text-center">
                        <div>
                            <p className="callControllerSectionTitle mb-1"><strong>Campaign:</strong> -Campaign Name-</p>
                            <h3 className="fw-light">(888) 888-8888</h3>
                        </div>
                    </div>
                    <div className="col p-2 text-center">
                        <div>
                            <p className="callControllerSectionTitle mb-1"><strong>Call Controls</strong></p>
                            <div className="row w-100 m-auto">
                                <div className="col"><button className="btn btn-primary w-100" type="button">Hold Call</button></div>
                                <div className="col"><button className="btn btn-primary w-100" type="button">Transfer</button></div>
                                <div className="col"><button className="btn btn-primary w-100" type="button">Mute</button></div>
                            </div>
                        </div>
                    </div>
                    <div className="col p-2 text-center">
                        <div className="mt-4">
                            <div className="row w-100 m-auto">
                                <div className="col col-4 align-items-center">
                                    <h5 className="mt-2 p-0 mb-0">(00:00:00)</h5>
                                </div>
                                <div className="col col-8"><button className="btn btn-primary w-100" type="button">End Call</button></div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        )
      );
}

export default CallController;
import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "../Account/Account";
import { useNavigate } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import SubmitApplication from "./SubmitApplication";
import Sidebar from "../Sidebar/Sidebar";


const Sales = () =>{
    const navigate = useNavigate();
    const { userAttributes, getSession } = useContext(AccountContext);

    const [token, setToken] = useState("")
    const [agentid, setAgentId] = useState("");
    const [podid, setPodId] = useState("");
    const [orgId, setOrgId] = useState("");

    useEffect(() => {
        getSession().then((session) => {
          setToken(session.idToken.jwtToken);
          setAgentId(session["custom:agentId"]);
          setPodId(session["custom:podId"]);
          setOrgId(userAttributes["custom:orgid"]);
          console.log(session);
        }).catch((err) =>{
          navigate('/auth');
        })
        ;
      }, []);


    return (
      <>
      <div><NavBar />
       <div className="row h-100">
        <Sidebar />
        <div className="col main-content">
      <SubmitApplication access={token} agentId={agentid} podId={podid} organizationId={orgId}/></div>
      </div>
      </div>
      </>
      );
}

export default Sales;
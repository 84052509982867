import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import { useTable, useSortBy } from "react-table";
import { AccountContext } from "../Account/Account";
import { useNavigate } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import { Modal } from "react-bootstrap";
import { formatPhoneNumber } from "../../resources/hooks/hooks";

const useCustomTable = (columns, data) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow
    } = useTable({ columns, data }, useSortBy);
  
    return {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow
    };
  };


const Customers = () =>{
    const navigate = useNavigate();
    const { userAttributes, getSession } = useContext(AccountContext);
    const [token, setToken] = useState("");
    const [agentId, setAgentId] = useState("");
    const [orgId, setOrgId] = useState("");
    const [contactData, setContactData] = useState([]);
    const [copyContactData, setCopyContactData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [contactsPerPage,setContactsPerPage] = useState(10);
    const startIndex = (currentPage - 1) * contactsPerPage;
    const endIndex = currentPage * contactsPerPage;
    const [totalPages, setTotalPages] = useState(Math.ceil(contactData.length / contactsPerPage));
    const filterRef = useRef();
    const [createdContactId, setCreatedContactId] = useState(null);
    const [creatingContact, setCreatingContact] = useState(false);
    const handleCreateContactClose = () => {setCreatingContact(false)}
    const handleCreateContactShow = () => setCreatingContact(true);
    const [createContactStatus, setCreateContactStatus] = useState(null);
    const [createContactErrorText, setCreateContactErrorText] = useState("");
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        getSession().then((session) => {
          setToken(session.idToken.jwtToken);
          setAgentId(session["custom:agentId"]);
          setOrgId(userAttributes["custom:orgid"])
        }).catch((err) =>{
          navigate('/auth');
        })
        ;
      }, []);

      useEffect(()=>{
        if(agentId && token){
            fetchData();
        }
      }, [agentId])

      useEffect(()=>{
        if(contactData.length > 0){
          setCopyContactData(contactData.slice(startIndex, endIndex));
          setTotalPages(Math.ceil(contactData.length / contactsPerPage))
        }
      },[contactData]);
 

      useEffect(()=>{
        setCopyContactData(contactData.slice(startIndex, endIndex))
      },[currentPage])

      useEffect(()=>{
        setCurrentPage(1);
       if(filterRef.current.value){
          let obj = {target: {
            value: filterRef.current.value
          }}
        filterContacts(obj)
       }else{
        setCopyContactData(contactData.slice(startIndex, endIndex))
        setTotalPages(Math.ceil(contactData.length / contactsPerPage))
       }
      },[contactsPerPage])

      useEffect(()=>{
        if(createdContactId !== null){
          navigate(`/customers/view?id=${createdContactId}`);
          window.location.reload();
        }
      },[createdContactId])




  const contactColumns = useMemo(
    () => [
      { Header: "First Name",
      accessor: "firstname",
      Cell: ({ row }) => (
        <>
          {row.original.firstname ? (row.original.firstname):("-")}
        </>
      )},
      { Header: "Last Name",
      accessor: "lastname",
      Cell: ({ row }) => (
        <>
          {row.original.lastname ? (row.original.lastname):("-")}
        </>
      )
    },
      { Header: "Plan Status",
      accessor: "planstatus",
      Cell: ({ row }) => (
        <>
          {row.original.planstatus ? (row.original.planstatus):("-")}
        </>
      ) 
    },
      { Header: "Last Disposition",
      accessor: "lastdisposition",
       Cell: ({ row }) => (
        <>
          {row.original.lastdisposition ? (row.original.lastdisposition):("-")}
        </>
      ) 
    },
      { Header: "Plan ID",
       accessor: "planid",
       Cell: ({ row }) => (
        <>
          {row.original.planid ? (row.original.planid):("-")}
        </>
      ) 
      },
      { Header: "Email",
       accessor: "email", 
       Cell: ({ row }) => (
         <>
           {row.original.email ? (row.original.email):("-")}
         </>
       ) 
      },
      { 
        Header: "Phone Number", 
        accessor: "ani", 
        Cell: ({ row }) => (
          <>
            {formatPhoneNumber(row.original.ani)}
          </>
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <button
            className="btn m-0 p-1"
            type="button"
            onClick={() => navigate(`/customers/view?id=${row.original.id}`)}
          >
            View
          </button>
        ),
      },
    ],
    []
  );


      const {
        getTableProps: getContactTableProps,
        getTableBodyProps: getContactTableBodyProps,
        headerGroups: contactHeaderGroups,
        rows: contactRows,
        prepareRow: prepareContactRow,
      } = useCustomTable(contactColumns, copyContactData);

      const fetchData = async () => {
        try {
            const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/pullagentcontactrecords?agentid=${agentId}`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "auth-key": token
              },
            });
            const responseJson = await response.json();
            console.log(responseJson);
            if(responseJson.data.length > 0){
                setContactData(responseJson.data);
                setIsLoading(false);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false);
          }
      };

      const createNewContact = async() => {
        if(agentId && orgId){
          try {
            setCreatingContact(true);
            const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/dashboard/contact/create?agentid=${agentId}&orgid=${orgId}`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "auth-key": token
              },
            });
            const responseJson = await response.json();
            if(responseJson.body && responseJson.statusCode == 200 && responseJson.body !== "Unauthorized"){
              setCreateContactStatus(true);
                setTimeout(()=>{
                  setCreatedContactId(responseJson.body);
                  setCreatingContact(true);
                }, 3000)
                
  
            }else if(responseJson.body === "Unauthorized"){
              setCreateContactErrorText("You are not authorized to view this contact");
              setCreateContactStatus(false)
            }else{
              console.log(responseJson.body.statusCode)
            }
  
          } catch (error) {
            console.error("Error fetching data:", error);
            setCreateContactStatus(false);
          }
        }else{
          setOrgId(userAttributes["custom:orgid"])
        }
      }

      const selectPage = (p) => {
        console.log(p)
        setCurrentPage(p)
      }

      const filterContacts = (e) => {
        if(e.target.value){
          const lowercaseInput = e.target.value.toLowerCase();
        let resultingData = contactData.filter(item => {
          for (let key in item) {
            const lowercaseValue = String(item[key]).toLowerCase();
            if (lowercaseValue.includes(lowercaseInput)) {
              return true;
            }
          }
          return false;
        });
          setCopyContactData(resultingData.slice(startIndex, endIndex));
          setTotalPages(Math.ceil(resultingData.length / contactsPerPage))
        }else{
          setCopyContactData(contactData.slice(startIndex, endIndex))
          setTotalPages(Math.ceil(Math.ceil(contactData.length / contactsPerPage)))
        }
      };
      

      return (
       <>
       <NavBar />
       <div className="row h-100">
        <Sidebar />
        <div className="col main-content">
        <div className="row p-2 mb-4">
                <div className="col">
                    <div className="row w-100 m-auto box">
                        <div className="col col-6 pb-1 border-bottom">
                            <div className="text-start">
                                <h5>Your Contacts</h5>
                            </div>
                        </div>
                        <div className="col col-2 text-center border-bottom"><input type="search" className="w-100" placeholder="Search Contacts..." ref={filterRef} onChange={(e)=>{filterContacts(e)}}></input></div>
                        <div className="col col-2 text-center border-bottom"><button className="btn m-0 p-0" type="button" onClick={() => {createNewContact()}}>Create Contact</button></div>
                        <div className="col col-2 text-center border-bottom hover-cursor" onClick={()=>{fetchData();setIsLoading(true);}}>Refresh <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
</svg></div>
                        <div className="col col-12 p-3">
                        {!isLoading ? (
                        <div className="table-responsive">
            {copyContactData.length > 0 ? (
              <table className="table" {...getContactTableProps()}>
                <thead>
                  {contactHeaderGroups.map((reminderHeaderGroup) => (
                    <tr {...reminderHeaderGroup.getHeaderGroupProps()}>
                      {reminderHeaderGroup.headers.map((column) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                          {column.render("Header")}
                          <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getContactTableBodyProps()}>
                  {contactRows.map((reminderRow) => {
                    prepareContactRow(reminderRow);
                    return (
                      <tr {...reminderRow.getRowProps()}>
                        {reminderRow.cells.map((reminderCell, index) => (
                         <td {...reminderCell.getCellProps()}>
                            {reminderCell.render("Cell")}
                       </td>
                       
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
               ) : (
                <div className="w-100 m-auto text-center">
                  <h6>No contacts found</h6>
                </div>
                 )}
            </div>):(
                <div className="float-all-middle">
                <div className="text-center">
                  <img src="/img/spinner.gif" className="loadingIcon"></img>
                  <h5>Loading Your Customers...</h5>
                  </div>               
                </div>
            )}
            <div className="w-100">
              <div className="d-inline-flex">
              <nav aria-label="Page navigation">
                      <ul className="pagination">
                        {Array.from({ length: totalPages }, (_, index) => index + 1).map(pageNumber => (
                          <li key={pageNumber} className="page-item">
                           <button className={`${currentPage === pageNumber ? 'active ' : ''}page-link`} onClick={() => {selectPage(pageNumber);}}>
                              {pageNumber}
                            </button>
                          </li>
                        ))}
                        </ul>
                          </nav>
              </div>
              <div className="d-inline-flex ms-4">
              <select className="form-select" value={contactsPerPage} onChange={(e) => {setContactsPerPage(e.target.value)}}>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                        </select>
              </div>

            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Modal show={creatingContact} onHide={handleCreateContactClose} size="lg">
            <Modal.Body>
                        { createContactStatus === null && (
                            <>
                                <div className="w-100 p-3 m-auto text-center">
                                    <img src="/img/spinner.gif" className="loadingIcon"></img>
                                </div>
                                <div className="w-100 p-3 m-auto text-center">
                                    <h5>Creating Contact...</h5>
                                </div>
                            </>
                        )}
                          { createContactStatus && (
                            <>
                                <div className="w-100 p-3 m-auto text-center">
                                    <img src="/img/success.png" className="resultIcon"  playsInline loop={false}></img>
                                </div>
                                <div className="w-100  p-3 m-auto text-center">
                                    <h5>Contact Created! You will be redirected shortly.</h5>
                                </div>
                            </>
                        )}
                        {createContactStatus === false &&(
                            <>
                                <div className="w-100 p-3 m-auto text-center">
                                    <img src="/img/error.png" className="resultIcon"></img>
                                </div>
                                <div className="w-100  p-3 m-auto text-center">
                                    <h5>Failed To Create Contact</h5>
                                </div>
                                <div className="w-100  p-3 m-auto text-center">
                                    <h6>{createContactErrorText}</h6>
                                </div>
                            </>
                    )}

            </Modal.Body>
          </Modal>
       </>
      );
      
}

export default Customers;
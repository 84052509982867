import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "../Account/Account";
import { useNavigate } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import ChangePassword from "./ChangePassword"
import Five9Integration from "./Five9Integration";
import SunfireIntegration from "./SunfireIntegration";


const ManageAccount = () =>{
    const navigate = useNavigate();
    const { userAttributes, getSession } = useContext(AccountContext);
    const [token, setToken] = useState(null); 
    

    useEffect(() => {
        getSession().then((session) => {
          setToken(session.idToken.jwtToken);
        }).catch((err) =>{
          navigate('/auth');
        })
        ;
      }, []);


    return (
      <>
      <div><NavBar />
       <div className="row h-100">
        <Sidebar />
        <div className="col main-content">
          <div className="row p-2 text-start">
                <div className="col-12">
                   <h4 className="border-bottom">Personal Information</h4>
                </div>
            </div>
            <div className="row p-2 mb-5">
              <div className="col-6">
                <h6 className="pb-4">Name: {userAttributes.name}</h6>
                <h6 className="pb-4">Email: {userAttributes.email}</h6>
                <h6 className="pb-4">Agent ID: {userAttributes["custom:agentId"]}</h6>
              </div>
              <div className="col-6">
                <h6 className="pb-4">NPN: {userAttributes["custom:npn"]}</h6>
                <h6 className="pb-4">Phone: {userAttributes.phone_number}</h6>
                <h6 className="pb-4">Pod ID: {userAttributes["custom:podId"]}</h6>
              </div>
            </div>
            <div className="row p-2 text-start mb-5">
                <div className="col-12">
                <h4 className="border-bottom">Modify Your Password</h4>
                <ChangePassword />
                </div>
            </div>
            <div className="row p-2 text-start mb-5">
                <div className="col-12">
                <h4 className="border-bottom">Integrations</h4>
                {token && (
                   <div className="row pt-2">
                   <div className="col-3">
                   <Five9Integration access={token}/>
                   </div>
                   <div className="col-3">
                   <SunfireIntegration access={token}/>
                   </div>
                 </div>
                )}
                </div>
            </div>
        </div>
        </div>
        </div>
      </>
      );
}

export default ManageAccount;